import React, { useEffect, useState } from "react";
import config from "../../config";
import hexToRGB from "../../helpers/helpers";
import { useDownloadApp } from "../../helpers/useDownloadApp";

const AppAds = () => {
  const downloadApp = useDownloadApp();

  return (
    <div
      style={{
        background: hexToRGB(config.primaryColor, 0.1),
      }}
    >
      <div
        className="container d-md-flex d-block justify-content-between"
        style={{
          alignItems: "center",
        }}
      >
        <div className="d-flex flex-column align-items-center mb-md-5 mb-0  mx-3 pb-4 pt-5 phonePromotionArea">
          <h1 className="fw-bold text-center">Real-Estate in your pocket</h1>
          <p className="text-center">
            With our web app, spend less time searching and more time at your
            dream home.
          </p>
          <button
            id="setup_button"
            aria-label="Install app"
            title="Install app"
            className="btn text-white px-4 mt-4"
            style={{
              fontSize: 20,
              background: "#18136D",
              borderRadius: 100,
            }}
            onClick={downloadApp}
          >
            Download Now
          </button>
        </div>
        <div className="d-md-block d-none">
          <img
            src={require("../../assets/images/appstore.png")}
            alt="App Preview"
            height={400}
          />
        </div>
      </div>
    </div>
  );
};

export default AppAds;
