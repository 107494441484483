import React from "react";

function Logo() {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 477.9 204.8"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            className="cls-1"
            d="M1.26,161.4H444.28v9.27c-1.85,.56-439.39,.76-442.91,.26-.52-1.69-.6-6.93-.11-9.53Z"
          />
          <path
            className="cls-1"
            d="M119.07,157.25V92.99c.61-.08,1.21-.23,1.81-.23,8.55-.01,17.1-.09,25.64,.01,9.56,.12,17.25,6.21,19.36,15.23,1.37,5.87,.08,11.09-4.26,15.59,.45,.38,.82,.77,1.26,1.05,4.05,2.69,6.07,6.57,6.13,11.32,.04,2.6-.24,5.31-.96,7.8-2.24,7.67-7.5,12.04-15.41,13.15-1.65,.23-3.34,.32-5.01,.32-8.55,.03-17.1,.01-25.64,.01-.87,0-1.74,0-2.92,0Zm15.81-14.62c4.77,0,9.23,.06,13.68-.03,2.06-.04,3.34-1.34,4.04-3.22,1.49-4.04-.96-7.74-5.25-7.83-3.67-.07-7.33-.02-11,0-.45,0-.9,.14-1.46,.23v10.85Zm0-24.62c3.71,0,7.14,.11,10.55-.03,2.93-.12,4.71-2.25,4.7-5.27,0-3.13-1.87-5.19-4.94-5.28-2.87-.09-5.74-.03-8.61-.02-.53,0-1.06,.13-1.7,.21v10.39Z"
          />
          <path
            className="cls-1"
            d="M236.38,157.09c-.5,.05-1.05,.15-1.59,.15-4.87,.01-9.75-.05-14.62,.04-1.43,.03-2.15-.56-2.81-1.71-2.94-5.13-6-10.2-8.87-15.36-.86-1.54-1.84-2.17-3.58-2.04-1.97,.15-3.95,.03-6.28,.03v18.9h-16.22V92.92c.81-.06,1.5-.16,2.2-.16,8.55,0,17.1-.08,25.65,.04,2.45,.03,4.97,.34,7.34,.96,8.77,2.3,15.04,9.96,15.8,19.04,.76,8.95-1.66,14.74-9.22,21.75,.38,.67,.79,1.42,1.23,2.14,3.44,5.58,6.88,11.16,10.33,16.73,.69,1.12,1.28,2.21,.64,3.68Zm-37.59-33.39c4.36,0,8.49,.12,12.6-.05,2.1-.09,3.69-1.4,4.73-3.25,1.44-2.58,1.65-5.31,.78-8.09-.98-3.14-3.27-4.84-6.58-4.89-3.34-.06-6.69-.02-10.03,0-.46,0-.91,.11-1.5,.19v16.11Z"
          />
          <path
            className="cls-1"
            d="M119.91,19.79c.95,0,1.74,0,2.52,0,8.31,0,16.62,0,24.92,0,3.13,0,6.22,.41,9.18,1.45,8.43,2.96,14.15,10.9,14.41,20.19,.23,7.81-2.37,14.42-8.61,19.37-.25,.2-.55,.34-.86,.53,.48,.86,.79,1.49,1.15,2.09,3.47,5.65,6.94,11.3,10.45,16.93,.71,1.13,1.21,2.24,.66,3.76-.54,.05-1.15,.17-1.76,.17-4.79,.01-9.59-.04-14.38,.04-1.4,.02-2.15-.46-2.82-1.66-2.95-5.21-6.05-10.35-8.99-15.57-.76-1.35-1.58-2-3.19-1.88-2.05,.15-4.13,.04-6.26,.04-.63,2.21-.25,4.28-.31,6.32-.07,2.08-.02,4.16-.02,6.24v6.33h-16.11V19.79Zm16.11,14.71c.07,5.61-.16,10.9,.15,16.31,4.21,0,8.11,.07,12.01-.03,2.41-.06,4.26-1.3,5.4-3.43,1.61-3.01,1.71-6.11,.23-9.2-1-2.09-2.7-3.51-5.01-3.6-4.13-.16-8.27-.05-12.79-.05Z"
          />
          <path
            className="cls-1"
            d="M342.55,157.13h-17.98c-4.62-8.12-9.31-16.37-14.1-24.79-2.11,.1-4.23,.2-6.52,.31v24.14c-1.88,.58-13.84,.66-16.44,.14V92.91h16.34v23.84c6.42,1.73,13.41-1.65,15.46-7.55,.85-2.45,1.18-5.12,1.44-7.73,.27-2.76,.18-5.56,.25-8.59,5.55-.26,10.94-.07,16.37-.12,.16,.39,.4,.68,.38,.95-.3,5.26-.3,10.56-1.05,15.76-.96,6.6-4.45,11.9-9.94,15.8-.44,.32-.84,.7-1.35,1.13,.49,.82,.94,1.57,1.39,2.31,5.03,8.17,10.04,16.34,15.1,24.49,.77,1.23,1.11,2.42,.64,3.93Z"
          />
          <path
            className="cls-1"
            d="M393.35,142.6v14.23c-1.76,.5-37.29,.66-40.45,.2-.48-1.65-.65-60.63-.18-63.89,1.72-.5,37.05-.6,39.74-.09v14.31h-23.58v9.35h21.64v14.55h-21.7c-.2,3.84-.09,7.45-.07,11.33h24.6Z"
          />
          <path
            className="cls-1"
            d="M64.75,19.9h39.35c.64,1.88,.72,11.94,.08,14.52h-23.23v9.3c3.56,.15,7.12,.05,10.68,.07,3.57,.02,7.14,0,10.84,0v14.56h-21.54v11.29c8.01,.15,16.03-.01,23.99,.09,.54,1.83,.69,10.38,.29,14.14-1.63,.51-36.93,.67-40.47,.16V19.9Z"
          />
          <path
            className="cls-1"
            d="M53.35,69.56v14.58H2.51c-.7-1.51-.11-2.75,.61-4.04,7.94-14.24,15.85-28.48,23.77-42.73,.45-.82,.88-1.65,1.49-2.8H.97c0-4.96-.08-9.7,.08-14.61H52.86c.67,1.58,.03,2.83-.68,4.11-7.96,14.22-15.89,28.46-23.83,42.69-.45,.81-.85,1.64-1.45,2.8h26.45Z"
          />
          <path
            className="cls-1"
            d="M279.59,91.02c0,5.43,.05,10.87-.03,16.3-.03,2.23-.18,4.48-.57,6.67-1.32,7.31-5.91,11.69-12.88,13.61-5.61,1.54-11.07,.93-16.2-1.97-5.77-3.25-8.18-8.49-8.72-14.82-.09-1.03-.08-2.08-.08-3.11,0-11.19-.07-22.38,.04-33.56,.03-2.85,.32-5.77,1-8.53,1.89-7.65,7.62-12.47,15.48-13.18,2.21-.2,4.5-.2,6.68,.15,7.76,1.25,12.97,6.44,14.55,14.53,.5,2.57,.65,5.23,.7,7.85,.1,5.35,.03,10.71,.03,16.06Zm-10.02-.29s-.05,0-.07,0c0-5.12,.09-10.24-.05-15.36-.05-1.97-.51-3.96-1.01-5.88-.66-2.56-2.47-4.18-5.05-4.53-2.03-.28-4.15-.27-6.18,0-2.7,.37-4.52,2.11-5.09,4.77-.52,2.41-.95,4.88-.98,7.33-.11,9.6-.06,19.2-.03,28.8,0,1.35,.16,2.72,.46,4.03,.93,4.1,3.5,6.4,7.37,6.75,4.72,.43,8.07-1.34,9.59-5.45,.68-1.83,.95-3.88,1-5.85,.14-4.88,.05-9.76,.05-14.64Z"
          />
          <path
            className="cls-1"
            d="M219.15,38.57c0,5.44,.06,10.88-.03,16.32-.04,2.31-.2,4.64-.59,6.91-1.1,6.3-4.89,10.49-10.72,12.8-5.19,2.05-10.49,2.02-15.68-.02-7.25-2.85-10.75-8.45-11.36-16.05-.08-.96-.08-1.92-.08-2.88,0-11.36-.07-22.72,.04-34.08,.03-2.77,.34-5.61,1-8.3,1.9-7.65,7.65-12.48,15.52-13.17,2.29-.2,4.65-.14,6.92,.21,6.7,1.04,11.11,5.04,13.34,11.33,.81,2.3,1.39,4.81,1.47,7.24,.2,6.55,.07,13.11,.07,19.67,.04,0,.08,0,.12,0Zm-10-.2s-.05,0-.08,0c0-5.11,.09-10.23-.05-15.33-.05-1.97-.51-3.95-1.01-5.87-.69-2.66-2.62-4.23-5.27-4.59-1.87-.26-3.84-.26-5.71,0-2.76,.38-4.75,2.03-5.34,4.81-.53,2.48-.97,5.03-1,7.56-.12,9.5-.07,19.01-.03,28.51,0,1.42,.16,2.88,.48,4.26,.92,3.96,3.47,6.24,7.21,6.62,4.61,.47,7.85-1.23,9.64-5.04,.94-2.01,1.14-4.14,1.14-6.31,0-4.87,0-9.74,0-14.62Z"
          />
          <path
            className="cls-1"
            d="M400.31,122.41c2.45,0,4.61,.18,6.73-.05,2.44-.26,4.88-.71,7.24-1.39,2.05-.59,3.59-2.05,4.54-4.02,.16-.33,.18-.73,.34-1.4h-18.67v-6.64h18.3c-.49-1.75-1.52-2.61-2.63-3.36-1.9-1.28-4.06-1.87-6.32-1.98-2.31-.11-4.63-.07-6.95-.12-.78-.01-1.56-.07-2.45-.11v-9.61h43.29v6.34h-14.03c1.32,3.02,2.51,5.75,3.86,8.83h10.22v6.59h-10.69c-2.04,9.83-8.7,14.17-18.29,15.46,7.57,8.02,14.34,16.07,21.11,24.53-.81,.13-1.32,.28-1.83,.28-3.83,.02-7.67-.03-11.5,.04-1.24,.02-2.06-.39-2.86-1.33-6.14-7.16-12.34-14.26-18.49-21.41-.43-.5-.84-1.22-.86-1.85-.09-2.79-.04-5.59-.04-8.78Z"
          />
          <path
            className="cls-1"
            d="M369.15,177.65h6.88c1.77,5.88,3.52,11.72,5.28,17.57,.18,.01,.36,.02,.55,.03,1.96-5.74,3.41-11.65,5.33-17.55h6.89c1.68,8.65,3.36,17.31,5.07,26.13h-6.26c-1.01-5.8-2.01-11.5-3.01-17.2h-.5c-1.64,5.73-3.28,11.46-4.94,17.24h-5.66c-1.62-5.66-3.27-11.38-4.89-17.04-.18-.05-.25-.1-.3-.08-.07,.02-.16,.08-.19,.14-.12,.29-.27,.59-.33,.9-.9,5.27-1.79,10.54-2.71,15.99h-6.23c1.52-8.7,3.31-17.33,5.01-26.13Z"
          />
          <path
            className="cls-1"
            d="M224.27,88.19c-.95,0-1.67,.05-2.38-.01-1.26-.11-2.52-.29-4.16-.49,7.93-21.59,15.89-42.95,23.85-64.43,2.23,.21,4.27,.41,6.68,.63-8.05,21.57-15.99,42.85-23.99,64.3Z"
          />
          <path
            className="cls-1"
            d="M362.42,190.89c-.05,7.39-6.26,13.56-13.62,13.55-7.44,0-13.69-6.34-13.64-13.81,.06-7.45,6.42-13.65,13.9-13.55,7.34,.1,13.42,6.38,13.36,13.81Zm-5.71-.07c.06-4.24-3.67-8.05-7.88-8.06-4.09-.02-7.83,3.63-7.97,7.77-.14,4.14,3.64,8.13,7.79,8.21,4.16,.08,8-3.69,8.05-7.91Z"
          />
          <path
            className="cls-1"
            d="M160.99,203.96c-7.36-.02-13.51-6.21-13.54-13.63-.03-7.51,6.19-13.76,13.68-13.73,7.31,.03,13.58,6.3,13.62,13.63,.04,7.4-6.33,13.75-13.75,13.74Zm-7.8-13.74c0,4.27,3.72,8.05,7.9,8.03,4.17-.02,7.93-3.89,7.87-8.1-.06-4.15-3.72-7.84-7.82-7.88-4.23-.04-7.95,3.68-7.95,7.95Z"
          />
          <path
            className="cls-1"
            d="M78.06,203.96c-7.37,0-13.53-6.16-13.59-13.58-.06-7.51,6.15-13.79,13.63-13.78,7.32,.01,13.58,6.24,13.66,13.58,.08,7.38-6.29,13.78-13.7,13.78Zm-7.85-13.64c.04,4.26,3.81,8.01,8,7.93,4.07-.07,7.74-3.82,7.78-7.95,.04-4.22-3.72-8.03-7.92-8.03-4.23,0-7.9,3.76-7.86,8.05Z"
          />
          <path
            className="cls-1"
            d="M263.89,190.07h12.45c.97,6.49-4.11,13.04-10.86,14.23-7.22,1.26-14.38-3.66-15.7-10.8-1.39-7.53,3.1-14.47,10.46-16.14,6.55-1.49,13.91,2.63,15.39,8.72h-5.88c-1.21-1.88-2.95-3.3-5.41-3.65-1.64-.23-3.18,.05-4.64,.82-3.24,1.7-4.99,5.58-4.22,9.39,.73,3.63,3.79,6.44,7.24,6.63,3.75,.21,6.23-1.36,7.5-4.96-1.08-.03-2.07-.07-3.06-.08-1.03-.01-2.05,0-3.26,0v-4.14Z"
          />
          <path
            className="cls-1"
            d="M331.14,177.69v26.12h-5.91v-10.31h-8.85v10.26h-5.38c-.48-1.55-.64-22.44-.15-26.05h5.38v10.19h8.76c.45-3.3-.05-6.72,.3-10.21h5.86Z"
          />
          <path
            className="cls-1"
            d="M119.02,189.31c.38,.21,.72,.38,1.05,.56,3.03,1.71,4.39,4.58,3.69,7.83-.73,3.42-3.1,5.56-6.74,5.71-3.75,.15-7.5,.03-11.23,.03-.54-1.69-.73-22.09-.22-26.37,.87,0,1.8,0,2.73,0,2.24,0,4.48-.07,6.71,.04,2.85,.13,5.15,1.35,6.18,4.13,1.03,2.76,.64,5.35-1.72,7.41-.17,.15-.26,.38-.45,.66Zm-7.87,9.83c1.15,0,2.01,0,2.88,0,.4,0,.8,0,1.19-.09,1.63-.34,2.7-1.6,2.73-3.17,.03-1.58-.97-2.98-2.58-3.28-1.38-.25-2.81-.38-4.21,.19v6.35Zm.01-11.53c1.9,.32,3.52,.35,4.61-1.33,.72-1.1,.64-2.53-.22-3.54-1.17-1.38-2.67-1.43-4.39-1.1v5.97Z"
          />
          <path
            className="cls-1"
            d="M420.95,195.54h5.51c.09,.19,.3,.45,.35,.74,.58,3.18,4.72,4.75,8.25,3.26,1.19-.5,2.04-1.34,2.03-2.7,0-1.28-.87-2.06-1.97-2.47-1.49-.55-3.04-.95-4.56-1.44-1.52-.49-3.08-.88-4.53-1.52-2.52-1.11-4.07-2.98-4.19-5.89-.13-3.11,1.08-5.51,3.8-6.94,4.19-2.21,8.48-2.09,12.64,.09,2.17,1.14,3.39,3.1,3.71,5.57,.03,.23-.02,.47-.05,.94h-5.08c-1.6-3.1-3.18-3.96-6.28-3.31-.68,.14-1.39,.45-1.93,.89-1.35,1.08-1.31,2.68,.18,3.58,1,.61,2.19,.93,3.33,1.28,1.91,.58,3.89,.94,5.75,1.63,3.1,1.14,5.07,3.24,5.09,6.79,.01,3.57-1.56,6.16-4.87,7.53-4.03,1.66-8.12,1.7-12.11-.11-3.28-1.49-4.91-4.13-5.07-7.9Z"
          />
          <path
            className="cls-1"
            d="M49.71,194.29v9.01h-5.38c-.46-1.62-.6-22.79-.13-26.05,3.78,0,7.6-.27,11.37,.08,3.09,.29,5.51,3.24,6.06,6.36,.66,3.79-.67,7.24-3.52,9.05-.6,.38-1.25,.68-2.17,1.17,2.24,3.15,4.7,6.03,7.09,9.32-2.34,.45-4.45,.27-6.54,.15-.47-.03-.97-.74-1.31-1.24-1.36-1.98-2.64-4-3.98-5.99-.33-.49-.74-.93-1.49-1.86Zm.07-4.87c2.46,.2,4.67,.54,6.06-1.76,.89-1.48,.75-3.43-.34-4.74-1.58-1.9-3.62-1.49-5.72-1.32v7.82Z"
          />
          <path
            className="cls-1"
            d="M127.05,203.3v-26.11c3.84,0,7.58-.24,11.28,.07,3.16,.27,5.72,3.23,6.28,6.44,.65,3.8-.68,7.24-3.54,9.05-.6,.38-1.25,.68-2.2,1.18,2.37,3.08,4.68,6.07,7.25,9.41-2.4,.26-4.43,.18-6.44,.07-.49-.03-1.06-.62-1.4-1.1-1.28-1.83-2.48-3.72-3.72-5.59-.44-.66-.91-1.3-1.72-2.47-.45,3.29-.01,6.11-.34,9.04h-5.45Zm5.71-13.94c3.7,.37,5.13-.06,6.06-1.72,.85-1.5,.73-3.44-.35-4.74-1.59-1.89-3.62-1.5-5.71-1.3v7.76Z"
          />
          <path
            className="cls-1"
            d="M190.13,190.27c3.49,4.41,6.79,8.58,10.44,13.19-2.69,0-4.89,.09-7.06-.06-.55-.04-1.15-.74-1.55-1.28-1.89-2.58-3.7-5.21-5.55-7.81-.46-.65-.94-1.27-1.6-2.15-.71,1.98-.31,3.77-.38,5.51-.07,1.82-.02,3.65-.02,5.65h-5.51v-26.09h5.42v11.1c1.68-.94,2.21-2.38,3.08-3.5,.98-1.26,1.86-2.6,2.78-3.9,.88-1.24,1.76-2.47,2.65-3.72h7.3c-3.45,4.5-6.68,8.72-10.01,13.07Z"
          />
          <path
            className="cls-1"
            d="M218.72,185.17c-2.4,4.89-2.03,4.97-8.33,7.4,3.05,3.6,6.06,7.14,9.06,10.69-.07,.17-.14,.34-.2,.52-2.03,0-4.06,.05-6.09-.04-.46-.02-.98-.48-1.33-.87-2.64-2.99-5.23-6.03-7.86-9.03-1.43-1.63-.59-3.45-.68-5.25,2.95-.77,6.51,.45,8.59-3.29-2.92-.54-5.69,0-8.53-.38v-3.06h7.83c-1.93-3.13-5-2.16-7.82-2.54v-4.6c6.61-.09,13.26-.03,20.08-.04v3.23h-6.43c.63,1.51,1.11,2.67,1.64,3.95h4.74v3.07c-1.59,.08-3.15,.16-4.67,.24Z"
          />
          <path
            className="cls-1"
            d="M241,198.69c-3.05,0-6.01,.01-8.96,0-.85,0-1.31,.36-1.59,1.15-.47,1.35-1,2.67-1.51,4.02h-6.07c3.58-8.85,7.08-17.49,10.61-26.19h4.99c3.5,8.64,7,17.27,10.6,26.15h-6.06c-.64-1.63-1.31-3.32-2.01-5.11Zm-4.73-13.54c-.19-.04-.39-.08-.58-.11-1.01,2.88-2.01,5.76-3.09,8.84h6.73c-1.07-3.06-2.07-5.89-3.06-8.73Z"
          />
          <path
            className="cls-1"
            d="M295.62,203.87c-.86,.04-1.56,.1-2.27,.1-4.15,0-8.29,0-12.62,0v-26.29h14.93v5h-9.15v5.34h8.75v5.17h-8.76v5.56h9.11v5.11Z"
          />
          <path
            className="cls-1"
            d="M402.17,177.67h14.84v5.06h-9.18v5.43h8.85v5.01h-8.59c-.7,1.95-.51,3.64-.21,5.56h9.06v4.88c-1.57,.47-10.81,.62-14.63,.22-.5-1.65-.64-22.89-.13-26.15Z"
          />
          <path
            className="cls-1"
            d="M24.08,177.2h14.84v5.04h-9.25v5.27c1.46,.04,2.94,.1,4.42,.12,1.42,.02,2.84,0,4.41,0v5.13h-8.8v5.35c2.95,.48,5.98-.03,9.17,.32v4.68c-1.62,.48-10.73,.63-14.66,.23-.47-1.71-.59-23.03-.12-26.15Z"
          />
          <path
            className="cls-1"
            d="M.88,177.17H20c-.53,1.93-1.61,3.49-2.44,5.14-.89,1.79-1.84,3.54-2.77,5.31-.89,1.7-1.76,3.4-2.65,5.1-.88,1.69-1.75,3.38-2.82,5.42,3.53,.35,6.76,.01,10.05,.23,.66,1.64,.42,3.26,.18,5H0c3.64-7.01,7.19-13.84,10.94-21.05H.88v-5.15Z"
          />
          <path
            className="cls-1"
            d="M458.96,79.24h5.84c1.1,4.04,2.21,8.13,3.33,12.22,.19,0,.38,0,.57,.01,1.08-4.02,2.17-8.04,3.28-12.18h5.47c.5,1.65,.64,15.84,.17,19.16-.48,.07-1.01,.19-1.53,.22-.47,.02-.95-.05-1.76-.11-.18-4.59-.03-9.17-.63-13.83-1.15,4.59-2.3,9.18-3.46,13.84h-3.64c-1.15-4.61-2.3-9.2-3.45-13.79-.17,.02-.34,.04-.51,.06v13.66h-3.54c-.27-6.31-.06-12.64-.13-19.26Z"
          />
          <path
            className="cls-1"
            d="M446.99,82.37h-5.71v-3.08h15.01v3.01h-5.49c-.24,5.52,0,10.82-.15,16.24h-3.67v-16.17Z"
          />
        </g>
      </g>
    </svg>
  );
  // return (
  //   <img className='img-fluid' src={src} style={{
  //       maxWidth:width,
  //       width:"100%",
  //       transition:"0.1s linear all"
  //   }}/>
  // )
}

export default Logo;
