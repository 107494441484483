import { useState, useEffect } from "react";
import { List, PersonCircle } from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation } from "react-router-dom";
import config from "../../config";
import Logo from "./Logo";
import "./useful.css";
import Modal from "react-bootstrap/Modal";
import { CloseButton } from "react-bootstrap";

function CustomNavbar({ isTransparent = false }) {
  const [bgColor, setBgColor] = useState(isTransparent);
  const location = useLocation();
  const [showWorkWithUs, setShowWorkWithUs] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSidebar, setShowSidebar] = useState(null);
  const [isRegister, setRegister] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 20) {
      setBgColor(true);
    } else {
      setBgColor(false);
    }
  };
  window.addEventListener(
    "scroll",
    !isTransparent ? changeNavbarColor : () => {}
  );

  function LoginRegister() {
    return !isRegister ? (
      <Modal
        show={showLogin}
        onHide={() => {
          setShowLogin(false);
          setShowSidebar(true);
        }}
        centered
        fullscreen={window.screen.width >= 600 ? false : true}
        backdropClassName="backdropModel"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="">
            <form action="" className="mobile-register-form">
              <div className="d-flex justify-content-between login-flex">
                <h3 className="mb-4 fw-bold">Login</h3>
                <CloseButton
                  onClick={() => {
                    setShowLogin(false);
                    setShowSidebar(true);
                  }}
                  color="#000"
                />
              </div>

              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                />
                <label htmlFor="email">Email address</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                />
                <label htmlFor="password">Password</label>
              </div>

              <button
                className="btn text-white "
                style={{ background: config.primaryColor }}
              >
                Login
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    ) : (
      <Modal
        show={showLogin}
        onHide={() => {
          setShowLogin(false);
          setShowSidebar(true);
        }}
        centered
        backdropClassName="backdropModel"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body className="mobile-register-form">
          <div className="">
            <form action="">
              <div className="d-flex justify-content-between">
                <h3 className="mb-4 fw-bold">Login</h3>
                <CloseButton
                  onClick={() => {
                    setShowLogin(false);
                    setShowSidebar(true);
                  }}
                  color="#000"
                />
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="John"
                />
                <label htmlFor="name">Name</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                />
                <label htmlFor="email">Email address</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Phone"
                />
                <label htmlFor="phone">Phone</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                />
                <label htmlFor="password">Password</label>
              </div>

              <p>
                Already Have an Account ?{" "}
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setRegister(false)}
                >
                  Login
                </button>
              </p>

              <button
                className="btn text-white "
                style={{ background: config.primaryColor }}
              >
                Register
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function WorkWithUsModel() {
    return (
      <Modal
        show={showWorkWithUs}
        onHide={() => setShowWorkWithUs(false)}
        centered
        backdropClassName="backdropModel"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="">
            <form action="">
              <div className="d-flex justify-content-between">
                <h3 className="mb-4 fw-bold">Work With Us</h3>
                <CloseButton
                  onClick={() => setShowWorkWithUs(false)}
                  color="#000"
                />
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                />
                <label htmlFor="name">Name</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Mobile Number"
                />
                <label htmlFor="phone">Mobile Number</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                />
                <label htmlFor="email">Email address</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="location"
                  placeholder="Location"
                />
                <label htmlFor="location">Location</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="rera"
                  placeholder="RERA Number"
                />
                <label htmlFor="rera">RERA Number</label>
                <span>
                  (Note: RERA No. is Compulsory to Register as Partner)
                </span>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Broker
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Developer
                </label>
              </div>

              <button
                className="btn text-white "
                style={{ background: config.primaryColor }}
              >
                Submit
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, [location]);

  return (
    <>
      <WorkWithUsModel />
      <LoginRegister />
      <Navbar
        bg={bgColor ? "light" : !isTransparent ? "transparent" : "light"}
        fixed="top"
        expand={false}
        className={bgColor ? "shadow px-md-3 p-0" : "px-md-3 p-0"}
        style={{ transition: "0.1s linear all" }}
      >
        <div
          className="container-fluid"
          style={{
            height: 70,
            flexWrap: "unset",
          }}
        >
          <Navbar.Brand
            className={bgColor ? "fw-bold text-dark" : "fw-bold text-white"}
          >
            <Link to={"/"}>
              {config.blueLogo != null ? (
                <Logo
                  src={bgColor ? config.blueLogo : config.whiteLogo}
                  width={bgColor ? "150px" : "150px"}
                />
              ) : (
                config.sitename
              )}
            </Link>{" "}
          </Navbar.Brand>
          <div className="d-flex">
            <ul className="navbar nav d-md-flex d-none">
              <li className="nav-item">
                <Link
                  className={
                    bgColor
                      ? location.pathname === "/"
                        ? "cool-link nav-link text-dark active"
                        : "cool-link nav-link text-dark"
                      : location.pathname === "/"
                      ? "cool-link-light nav-link text-white active-light"
                      : "cool-link-light nav-link text-white"
                  }
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    bgColor
                      ? location.pathname === "/residential-projects"
                        ? "cool-link nav-link text-dark active"
                        : "cool-link nav-link text-dark"
                      : location.pathname === "/residential-projects"
                      ? "cool-link-light nav-link text-white active-light"
                      : "cool-link-light nav-link text-white"
                  }
                  aria-current="page"
                  to="/residential-projects"
                >
                  Residential Projects
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    bgColor
                      ? location.pathname === "/commercial-projects"
                        ? "cool-link nav-link text-dark active"
                        : "cool-link nav-link text-dark"
                      : location.pathname === "/commercial-projects"
                      ? "cool-link-light nav-link text-white active-light"
                      : "cool-link-light nav-link text-white"
                  }
                  aria-current="page"
                  to="/commercial-projects"
                >
                  Commercial Projects
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    bgColor
                      ? location.pathname === "/independent-bungalows-villas"
                        ? "cool-link nav-link text-dark active"
                        : "cool-link nav-link text-dark"
                      : location.pathname === "/independent-bungalows-villas"
                      ? "cool-link-light nav-link text-white active-light"
                      : "cool-link-light nav-link text-white"
                  }
                  aria-current="page"
                  to="/independent-bungalows-villas"
                >
                  Independent Bunglows/Villas
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className={
                    bgColor
                      ? location.pathname === "/home-loan"
                        ? "cool-link nav-link text-dark active"
                        : "cool-link nav-link text-dark"
                      : location.pathname === "/home-loan"
                      ? "cool-link-light nav-link text-white active-light"
                      : "cool-link-light nav-link text-white"
                  }
                  aria-current="page"
                  href="/home-loan"
                >
                  Home Loan
                </a>
              </li>
              {/* <li className="nav-item ">
                <a className={bgColor ? location.pathname === "/work-with-us" ? "cool-link nav-link text-dark active" : "cool-link nav-link text-dark" : location.pathname === "/work-with-us" ? "cool-link-light nav-link text-white active-light" : "cool-link-light nav-link text-white"} aria-current="page" onClick={() => setShowWorkWithUs(true)} style={{ cursor: "pointer" }}>Work With Us</a>
              </li> */}
            </ul>
            <Navbar.Toggle
              className="ms-2"
              style={{
                border: "none",
                color: "transparent",
              }}
              aria-controls={`offcanvasNavbar-expand-${false}`}
            >
              <List
                color={bgColor ? "#000" : "#fff"}
                size={30}
                style={{
                  outline: "none",
                }}
              />
            </Navbar.Toggle>
          </div>
          {showSidebar == null || showSidebar === true ? (
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${false}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
              placement="end"
              style={{
                zIndex: 100000,
              }}
            >
              <Offcanvas.Header className="offHeader" closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${false}`}
                  onClick={() => {
                    setShowLogin(true);
                    setShowSidebar(false);
                  }}
                >
                  <PersonCircle className="me-3 mb-1" />
                  Login
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link className="sidebarlink" href="/">
                    Home
                  </Nav.Link>
                  {console.log(deviceType)}

                  {deviceType === "Mobile" && (
                    <>
                      <Nav.Link
                        className="sidebarlink phoneLink"
                        href="/residential-projects"
                      >
                        Residential Projects
                      </Nav.Link>
                      <Nav.Link
                        className="sidebarlink phoneLink"
                        href="/commercial-projects"
                      >
                        Commercial Projects
                      </Nav.Link>
                      <Nav.Link
                        className="sidebarlink phoneLink"
                        href="/independent-bungalows-villas"
                      >
                        Independent Bungalow/Villas
                      </Nav.Link>
                      <Nav.Link
                        className="sidebarlink phoneLink"
                        href="/home-loan"
                      >
                        Home Loan
                      </Nav.Link>
                    </>
                  )}
                  {/* <Nav.Link className='sidebarlink phoneLink' href="/work-with-us">Work With Us</Nav.Link> */}

                  <Nav.Link className="sidebarlink" href="/about">
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    className="sidebarlink d-none d-md-block"
                    onClick={() => setShowWorkWithUs(true)}
                  >
                    Work With Us
                  </Nav.Link>
                  <Nav.Link className="sidebarlink" href="/blog">
                    Blog
                  </Nav.Link>
                  <Nav.Link className="sidebarlink" href="/contact">
                    Contact Us
                  </Nav.Link>
                  <Nav.Link className="sidebarlink" href="/why-mumbai">
                    Why Mumbai
                  </Nav.Link>
                  <Nav.Link className="sidebarlink" href="/page/privcy-policy">
                    Privacy Policy
                  </Nav.Link>
                  <Nav.Link
                    className="sidebarlink"
                    href="/page/terms-and-conditions"
                  >
                    Terms & Conditions
                  </Nav.Link>
                </Nav>

                <div className="sidebarFooter">
                  <strong className="my-2">Whatsapp :</strong>
                  <div className="d-flex align-items-center">
                    <div className="ms-3">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://wa.me/919930704010"
                      >
                        +91 9930704010
                      </a>
                    </div>
                  </div>

                  <strong className="my-2">Email :</strong>
                  <div className="d-flex align-items-center">
                    <div className="ms-3">
                      sales@zerobroker.in
                      <br />
                      info@zerobroker.in
                    </div>
                  </div>

                  <footer
                    style={{
                      height: 100,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 50,
                    }}
                  >
                    <a
                      href="https://www.facebook.com/profile.php?id=100089858334241"
                      className="iconFooterCanvas"
                    >
                      <i className="fa-brands fa-facebook-f text-dark"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/zerobroker/"
                      className="iconFooterCanvas"
                    >
                      <i className="fa-brands fa-instagram text-dark"></i>
                    </a>
                    {/* <a href="#instagram" className='iconFooterCanvas'><i className='fa-brands fa-whatsapp text-dark'></i></a> */}
                    <a
                      href="https://www.youtube.com/channel/UCoQYuBvCy8DIK_0HJUFx8Pg"
                      className="iconFooterCanvas"
                    >
                      <i className="fa-brands fa-youtube text-dark"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/90894109/admin/"
                      className="iconFooterCanvas"
                    >
                      <i className="fa-brands fa-linkedin text-dark"></i>
                    </a>
                  </footer>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          ) : (
            <></>
          )}
        </div>
      </Navbar>
    </>
  );
}

export default CustomNavbar;
