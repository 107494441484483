import React from "react";
import { CircleFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "./PostCard.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyLoad from "../LazyLoad";

function PostCard({item = []}) {
  return (
    <div className="w-100">
      <Link to={`/blog/BLOGID00${item && item.id}`} state={item} className="text-decoration-none text-dark">
        <div className="card shadow" style={{ border: "none" }}>
          <div
            className="pos-r rounded overflow-hidden shimmer"
            style={{ paddingTop: "calc(31000%/620)", background:"#fff" }}
          >
            <LazyLoad
              src={`${item.image && item.image}`}
              style={{ objectFit: "contain", objectPosition:'center' }}
              lazyClass={"pos-a t-0 l-0 w-100 h-100 bgImg"}
              className="w-100 h-100"
            />
          </div>
          <div className="card-body postCardBody">
            <h5 className="card-title fw-bold">{item.title}</h5>
            <p className="card-text desc" dangerouslySetInnerHTML={{__html: item && item.content}}></p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default PostCard;

export const LoadPostCard = () => {
  return (
    <div className="loader">
      <Link to="/blog/post-title" className="text-decoration-none text-dark">
        <div className="card shadow" style={{ border: "none" }}>
          <div
            className="pos-r rounded overflow-hidden imageWrapper shimmer"
            style={{ paddingTop: "calc(31000%/620)" }}
          ></div>
          <div className="card-body postCardBody">
            <div className="title shimmer"></div>
            <div className="title shimmer"></div>
          </div>
        </div>
      </Link>
    </div>
  );
};
