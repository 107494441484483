import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MiniPostCard from "../components/blog/parts/MiniPostCard";
import PageLayout from "../components/PageLayout";
import { useLocation } from "react-router-dom";
import { api } from "../helpers/api";
import LazyLoad from "../components/LazyLoad";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { Linkedin, Whatsapp } from "react-bootstrap-icons";

function BlogDetail() {
  const [blogDetails, setBlogDetails] = useState([]);
  const [blogData, setBlogData] = useState([]);

  const location = useLocation();
  const state = location.state;
  const blog_id = state?.id;
  const blogTitle = state?.title;

  useEffect(() => {
    const loadDetails = async () => {
      const response = await api({
        method: "post",
        url: "fetch_blog.php",
        data: { blog_id: blog_id, fetch_details: true },
        headers: { "Content-Type": "multipart/form-data" },
      });

      const jsonData = await response.data;

      if (jsonData.status === 200) {
        setBlogDetails(jsonData.data[0]);
      }
    };
    const loadToprojects = async () => {
      const response = await api({
        method: "post",
        url: "fetch_blog.php",
        data: { blog_id: blog_id, fetch_other_data: true },
        headers: { "Content-Type": "multipart/form-data" },
      });
      const jsonData = await response.data;
      if (jsonData.status === 200) {
        setBlogData(jsonData.data);
      }
    };
    Promise.all([loadDetails(), loadToprojects()]);
  }, [blog_id, location]);

  return (
    <PageLayout isTransperant={true} title={blogTitle}>
      <div
        className="container py-5"
        style={{
          minHeight: "20vh",
          marginTop: 60,
        }}
      >
        <div className="row">
          <div className="col-md-8">
            <h1 className="fw-bold">{blogDetails && blogDetails.title}</h1>
            <span className="text-secondary" style={{ fontSize: 14 }}>
              Last Updated -- {blogDetails && blogDetails.created_on}
            </span>
            <br />
           
            <div className="d-flex pt-3">
              <FacebookShareButton
                url={`https://zerobroker.in${location.pathname}`}
              >
                <FacebookIcon size={40} round={true} />
              </FacebookShareButton>
              <WhatsappShareButton
                url={`https://zerobroker.in${location.pathname}`}
                className="mx-1"
                style={{
                  background: "#25D366",
                  color: "white",
                  borderRadius: 100,
                }}
              >
                <Whatsapp size={20} style={{ margin: 10 }} />
              </WhatsappShareButton>
              <LinkedinShareButton
                url={`https://zerobroker.in${location.pathname}`}
                className="mx-1"
                style={{
                  background: "#0A66C2",
                  color: "white",
                  borderRadius: 100,
                }}
              >
                <Linkedin size={20} style={{ margin: 10 }} />
              </LinkedinShareButton>
            </div>
            <br />
            <br />
            <div
              className="pos-r overflow-hidden shimmer d-block"
              style={{
                paddingTop: "calc(31000%/620)",
                background: "#fff",
                border: "1px solid #c8c8c8",
              }}
            >
              <LazyLoad
                src={`${blogDetails && blogDetails.image}`}
                style={{ objectFit: "contain", objectPosition: "center" }}
                lazyClass={"pos-a t-0 l-0 w-100 h-100 bgImg"}
                className="w-100 h-100"
              />
            </div>
            <br />
            <br />
            <p
              style={{ fontSize: 16 }}
              dangerouslySetInnerHTML={{
                __html: blogDetails && blogDetails.content,
              }}
            ></p>
          </div>
          <div className="col-md-4 p-3">
            <div
              className="card p-2 shadow"
              style={{ border: "none", position: "sticky", top: 100 }}
            >
              <span className="px-4 fw-bold" style={{ fontSize: 18 }}>
                More Post
              </span>
              <hr />
              {blogData.length > 0 &&
                blogData.map((blog, idx) => (
                  <>
                    <MiniPostCard item={blog} key={idx} />
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default BlogDetail;
