import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../helpers/api";
import config from "../../config";

const CheckEligibilityForm = () => {
	const cities = ["Delhi", "Noida", "Mumbai"];
	const navigate = useNavigate();

	const [formData, updateFormData] = useState({
		loan_type: "Home Loan",
	});
	const [checked, setChecked] = useState(false);
	const [phone, setPhone] = useState();
	const [otp, setOtp] = useState();
	const [showOtp, setShowOtp] = useState(false);
	const [isVerified, setIsVerified] = useState(false);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!isVerified) {
			toast.error("Please Verify Phone Number");
			return;
		}

		if (!checked) {
			toast.error("Please agree Terms & Condition.");
			return;
		}

		const response = await api({
			method: "post",
			url: "send_inquiry.php",
			data: { ...formData, phone: phone },
			headers: { "Content-Type": "multipart/form-data" },
		});

		const jsonData = await response.data;

		if (jsonData.status === 200) {
			toast.success("Form Submitted Successfull !");
			navigate(0);
		} else {
			toast.error("Something went wrong !");
		}
	};

	const SendOTP = async (e, msg = "Sent") => {
		e.preventDefault();
		// const response = await api.post('send_inquiry.php',{ data: formData});
		const response = await api({
			method: "post",
			url: "verify_mobile.php",
			data: { phone: phone, send_otp: true },
			headers: { "Content-Type": "multipart/form-data" },
		});
		const jsonData = await response.data;
		if (jsonData.Status === "Success") {
			toast.success(`OTP ${msg}`);
			setShowOtp(true);
			setOtp("");
		} else {
			toast.error("Invalid Phone Number");
		}
	};
	const VerifyOTP = async (e) => {
		e.preventDefault();
		const response = await api({
			method: "post",
			url: "verify_mobile.php",
			data: { phone: phone, otp: otp, verify_otp: true },
			headers: { "Content-Type": "multipart/form-data" },
		});
		const jsonData = await response.data;
		if (jsonData.Status === "Success") {
			setIsVerified(true);
			toast.success("OTP Verified.");
		} else {
			toast.error("OTP Mismatch");
		}
	};

	const [timer, setTimer] = useState(60);
	const timeOutCallback = useCallback(
		() => setTimer((currTimer) => currTimer - 1),
		[]
	);

	useEffect(() => {
		let timeout;
		timer > 0 && (timeout = setTimeout(timeOutCallback, 1000));

		return () => clearTimeout(timeout);
	}, [timer, timeOutCallback]);

	const resetTimer = function (e) {
		if (!timer) {
			SendOTP(e, "Resent");
			setTimer(60);
		}
	};

	return (
		<>
			<h3
				className="text-center fw-bold w-100 m-3 home-loan-heads"
				id="inquiryForm">
				CHECK YOUR ELIGIBILITY
			</h3>
			<div className="details-card w-100 calcForm">
				<div className="main_loan_elegibility_form">
					<p>
						We just need a few details to match you with the right
						home loan product{" "}
					</p>
					<hr />

					<div className="row">
						<div className="col-md-4 my-3">
							<div className="form-floating">
								<input
									type="text"
									className="form-control"
									id="loanAmount"
									name="loanAmount"
									placeholder="Loan Amount"
									onChange={handleChange}
								/>
								<label htmlFor="loanAmount">Loan Amount</label>
							</div>
						</div>
						<div className="col-md-4 my-3">
							<div className="form-floating">
								<select
									className="form-control"
									id="loanTenure"
									name="loanTenure"
									onChange={handleChange}
									defaultValue="">
									<option value="">Loan Tenure</option>
									<option defaultValue="5">5</option>
									<option defaultValue="10">10</option>
									<option defaultValue="15">15</option>
									<option defaultValue="25">25</option>
									<option defaultValue="30">30</option>
								</select>
								<label htmlFor="loanTenure">Loan Tenure</label>
							</div>
						</div>
						<div className="col-md-4 my-3">
							<div className="form-floating">
								<input
									type="text"
									className="form-control"
									id="yourAge"
									name="yourAge"
									placeholder="Your Age"
									onChange={handleChange}
								/>
								<label htmlFor="yourAge">Your Age</label>
							</div>
						</div>

						<div className="col-md-4 my-3">
							<div className="form-floating">
								<select
									name="propertyIdentified"
									id="propertyIdentified"
									placeholder="Is your property identified"
									className="form-control"
									onChange={handleChange}>
									<option defaultValue="">Select</option>
									<option defaultValue="1">Yes</option>
									<option defaultValue="0">No</option>
								</select>
								<label htmlFor="propertyIdentified">
									Is your property identified
								</label>
							</div>
						</div>

						<div className="col-md-4 my-3">
							<div className="form-floating">
								<select
									name="propertyCity"
									id="propertyCity"
									placeholder="Property City"
									className="form-control"
									onChange={handleChange}>
									<option defaultValue="">Select</option>
									{cities.map((item, index) => (
										<option defaultValue={item}>
											{item}
										</option>
									))}
								</select>
								<label htmlFor="propertyCity">
									Property City
								</label>
							</div>
						</div>

						<div className="col-md-4 my-3">
							<div className="form-floating">
								<input
									type="text"
									className="form-control"
									name="propertyCost"
									id="propertyCost"
									placeholder="Property Cost"
									onChange={handleChange}
								/>
								<label htmlFor="propertyCost">
									Property Cost
								</label>
							</div>
						</div>

						<div className="col-md-4 my-3">
							<div className="form-floating">
								<select
									name="currentEmployed"
									id="currentEmployed"
									placeholder="How are you currently employed"
									className="form-control"
									onChange={handleChange}>
									<option defaultValue="">Select</option>
									<option defaultValue="Salaried">
										Salaried
									</option>
									<option defaultValue="Self Employed">
										Self Employed
									</option>
									<option defaultValue="Business">
										Business
									</option>
								</select>
								<label htmlFor="currentEmployed">
									How are you currently employed
								</label>
							</div>
						</div>

						<div className="col-md-4 my-3">
							<div className="form-floating">
								<input
									type="text"
									className="form-control"
									id="yourIncome"
									name="yourIncome"
									placeholder="Your Income (Annual)"
									onChange={handleChange}
								/>
								<label htmlFor="yourIncome">
									Your Income (Annual)
								</label>
							</div>
						</div>

						<div className="col-md-4 my-3">
							<div className="form-floating">
								<input
									type="text"
									className="form-control"
									id="CurrentCity"
									name="CurrentCity"
									placeholder="Current City"
									onChange={handleChange}
								/>
								<label htmlFor="CurrentCity">
									Current City
								</label>
							</div>
						</div>

						<div className="col-md-4 my-3">
							<div className="form-floating">
								<input
									type="text"
									className="form-control"
									id="fullName"
									name="fullName"
									placeholder="Full Name"
									onChange={handleChange}
								/>
								<label htmlFor="fullName">Full Name</label>
							</div>
						</div>
						<div className="col-md-4 my-3">
							<div className="form-floating">
								<input
									type="email"
									className="form-control"
									id="email"
									name="email"
									placeholder="Email"
									onChange={handleChange}
								/>
								<label htmlFor="email">Email</label>
							</div>
						</div>

						<div className="col-md-4 my-3">
							{!showOtp ? (
								<div className="input-group">
									<div className="form-floating">
										<input
											type="tel"
											className="form-control"
											id="phone"
											name="phone"
											placeholder="Enter OTP"
											onChange={(e) =>
												setPhone(e.target.value)
											}
										/>
										<label htmlFor="phone">Phone</label>
									</div>
									<button
										className="btn btn-outline-secondary"
										type="button"
										onClick={SendOTP}>
										Send OTP
									</button>
								</div>
							) : (
								<>
									<div className="input-group">
										<div className="form-floating">
											<input
												type="tel"
												className="form-control"
												id="otp"
												name="otp"
												placeholder="Enter OTP"
												value={otp}
												onChange={(e) =>
													setOtp(e.target.value)
												}
											/>
											<label htmlFor="otp">
												Enter OTP
											</label>
										</div>
										{!isVerified ? (
											<button
												className="btn btn-outline-secondary"
												type="button"
												onClick={VerifyOTP}>
												Verify OTP
											</button>
										) : (
											<button
												className="btn btn-outline-secondary"
												type="button"
												disabled>
												Verified
											</button>
										)}
									</div>
									{timer <= 0 ? (
										<p
											className="resend-timer btn-link"
											onClick={(e) => resetTimer(e)}>
											Resend OTP
										</p>
									) : (
										<p className="resend-timer">
											Resend OTP in {timer}
										</p>
									)}
								</>
							)}
						</div>
					</div>
					<div className="d-flex justify-content-center align-items-center">
						<div
							className="d-flex phoneWidth"
							style={{
								justifyContent: "center",
								alignItems: "flex-start",
							}}>
							<input
								type="checkbox"
								className="mx-2"
								name="permission"
								id="permission"
								onChange={() => setChecked(!checked)}
							/>
							<label
								htmlhtmlFor="permission"
								style={{ fontSize: 10 }}>
								I authorize zerobroker.in relevant loan
								providers and their representatives to call, SMS
								or email me with reference to the application &
								accept Zero Broker
								<Link to="/terms-and-conditions">
									“Terms & Conditions”.
								</Link>
							</label>
						</div>
					</div>

					<div className="d-flex justify-content-center align-items-center mt-3 mb-1">
						<Button
							onClick={handleSubmit}
							className="px-3 py-2 phoneWidth submitBtn"
							style={{
								background: config.primaryColor,
								border: "none",
							}}>
							Submit Details
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default CheckEligibilityForm;
