import React, { useState } from "react";
import {
  ArrowDown,
  Calculator,
  ChatSquareText,
  HouseDoor,
  Plus,
  Search,
  Whatsapp,
} from "react-bootstrap-icons";
import config from "../../config";
import ActionPopUp from "./ActionPopUp";
import "./quickaccess.css";
import Modal from "react-bootstrap/Modal";
import { CloseButton } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ImMobile } from "react-icons/im";
import { useDownloadApp } from "../../helpers/useDownloadApp";

function QuickAccess({
  searchInput = null,
  max = null,
  min = null,
  type = null,
}) {
  const [rotateIcon, setRotation] = useState(0);
  const [openModel, setOpenModel] = useState(false);
  const [showForm, setshowForm] = useState(false);
  const [showSearch, setshowSearch] = useState(false);

  const [searchData, setSearchData] = useState({});
  const navigate = useNavigate();
  const downloadApp = useDownloadApp();

  const handleChange = (e) => {
    e.preventDefault();
    setSearchData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setshowSearch(false);
    navigate("/search", { state: searchData });
  };
  function InquiryForm() {
    return (
      <Modal
        show={showForm}
        fullscreen
        onHide={() => setshowForm(false)}
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="">
            <form action="/search" className="customform customformModel">
              <div className="d-flex justify-content-between">
                <h3 className="fw-bold">Enter Details To Download Brochure</h3>
                <CloseButton onClick={() => setshowForm(false)} color="#000" />
              </div>

              <div className="">
                <input
                  type="text"
                  id="searchInput"
                  className="mt-2 mb-4"
                  placeholder="First Name"
                />
                <span className="dividerLight" />
                <input
                  type="text"
                  id="searchInput"
                  className="mt-2 mb-4"
                  placeholder="Last Name"
                />
                <span className="dividerLight" />
                <input
                  type="text"
                  id="searchInput"
                  className="mt-2 mb-4"
                  placeholder="Email Address"
                />
                <span className="dividerLight" />
                <input
                  type="text"
                  id="searchInput"
                  className="mt-2 mb-4"
                  placeholder="Phone"
                />
                <span className="dividerLight" />
                <button>Download</button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {openModel ? (
        <ActionPopUp
          onClose={() => {
            setOpenModel(false);
            setRotation(0);
          }}
        />
      ) : (
        <div />
      )}
      {window.screen.width <= 600 ? (
        <>
          <Modal
            show={showSearch}
            fullscreen
            onHide={() => setshowSearch(false)}
            centered
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body>
              <div className="">
                <div className="d-flex justify-content-between">
                  <h3 className="fw-bold ps-3">Search</h3>
                  <CloseButton
                    onClick={() => setshowSearch(false)}
                    color="#000"
                  />
                </div>

                <div className="">
                  <form className="mobile-modal w-100" onSubmit={handleSearch}>
                    <div className="form-floating pb-3">
                      <select
                        className="form-control"
                        name="type"
                        defaultValue={type}
                        onChange={handleChange}
                      >
                        <option value="" className="label">
                          Select Property Type
                        </option>
                        <option value="Residential">Residential</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Independent Bunglow/Villas">
                          Independent Bunglows/Villas
                        </option>
                      </select>
                      <label htmlFor="name">Select Property Type</label>
                    </div>
                    <div className="row g-0 pb-3">
                      <div className="col-6 pe-1">
                        <div className="form-floating">
                          <select
                            className="form-control"
                            name="min"
                            defaultValue={min}
                            onChange={handleChange}
                          >
                            <option value="">
                              <span className="label">Min Budget</span>
                            </option>
                            <option value="25 Lacs">25 Lacs</option>
                            <option value="50 Lacs">50 Lacs</option>
                            <option value="75 Lacs">75 Lacs</option>
                            <option value="1 Cr">1 Cr</option>
                            <option value="1.50 Cr">1.50 Cr</option>
                            <option value="2 Cr">2 Cr</option>
                            <option value="2.5 Cr">2.5 Cr</option>
                            <option value="3 Cr">3 Cr</option>
                            <option value="3.5 Cr">3.5 Cr</option>
                            <option value="5 Cr & Above">5 Cr & Above</option>
                          </select>
                          <label htmlFor="name">Min Budget</label>
                        </div>
                      </div>
                      <div className="col-6 ps-1">
                        <div className="form-floating">
                          <select
                            className="form-control"
                            name="max"
                            defaultValue={max}
                            onChange={handleChange}
                          >
                            <option value="" className="label">
                              Max Budget
                            </option>
                            <option value="50 Lacs">50 Lacs</option>
                            <option value="75 Lacs">75 Lacs</option>
                            <option value="1 Cr">1 Cr</option>
                            <option value="1.50 Cr">1.50 Cr</option>
                            <option value="2 Cr">2 Cr</option>
                            <option value="2.5 Cr">2.5 Cr</option>
                            <option value="3 Cr">3 Cr</option>
                            <option value="3.5 Cr">3.5 Cr</option>
                            <option value="4 Cr">4 Cr</option>
                            <option value="5 Cr & Above">5 Cr & Above</option>
                          </select>
                          <label htmlFor="name">Max Budget</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        required
                        defaultValue={searchInput}
                        name="searchInput"
                        placeholder="location/Name/Society/Pincode/Keyword"
                        onChange={handleChange}
                      />
                      <label htmlFor="name">
                        location/Project/Society/Pincode/Keyword
                      </label>
                    </div>
                    <button
                      type="submit"
                      className="btn text-white btn-primary mt-3"
                    >
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <InquiryForm />
        </>
      ) : (
        <></>
      )}
      <div
        className="quickbutton shadow-lg"
        onClick={() => {
          if (openModel) {
            setRotation(0);
            setOpenModel(false);
          } else {
            setRotation(135);
            setOpenModel(true);
          }
        }}
        style={{
          background: `linear-gradient(331deg, ${config.primaryColor} 10%, ${config.secondaryColor} 100%, ${config.secondaryColor} 100%`,
        }}
      >
        <Plus
          color="#fff"
          size={50}
          style={{
            transition: "0.2s linear all",
            rotate: `${rotateIcon}deg`,
          }}
        />
      </div>

      <div className="quickactionBar">
        <div className="actionBar">
          <Link aria-current="page" to="/" className="barIcon mobile-bar-icon">
            <HouseDoor />
            <p>Home</p>
          </Link>
          <a
            aria-current="page"
            href="https://wa.me/919930704010"
            className="barIcon mobile-bar-icon"
          >
            <Whatsapp />
            <p>Whatsapp</p>
          </a>
          <HashLink
            aria-current="page"
            to="/contact#contact-enquire"
            className="barIcon mobile-bar-icon"
          >
            <ChatSquareText />
            <p>Enquiry</p>
          </HashLink>
          <HashLink
            aria-current="page"
            to="/home-loan#calc-emi-section-main"
            className="barIcon mobile-bar-icon"
          >
            <Calculator />
            <p>EMI</p>
          </HashLink>
          <button
            type="button"
            onClick={downloadApp}
            className="barIcon mobile-bar-icon border-0"
          >
            <ImMobile />
            <p>Install App</p>
          </button>
          <button
            className="barIcon upArrow border-0  searchButton"
            onClick={() => setshowSearch(true)}
          >
            <Search />
          </button>
        </div>
      </div>
    </>
  );
}

export default QuickAccess;
