import React, { useCallback, useEffect, useState } from "react";
import { Calculator, Telephone } from "react-bootstrap-icons";
import { FilePdf, GeoAltFill, Linkedin, Whatsapp } from "react-bootstrap-icons";
import { ImMobile, ImSpinner } from "react-icons/im";
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinShareButton,
	WhatsappShareButton,
} from "react-share";

import { Link, useLocation, useParams } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import Modal from "react-bootstrap/Modal";
import config from "../config";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { CloseButton } from "react-bootstrap";
import { api } from "../helpers/api";

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";

import hexToRGB from "../helpers/helpers";
import LocationTitle from "../components/property/parts/LocationTitle";
import NearbyLocation from "../components/property/parts/NearbyLocation";
import DetialHeader from "../components/property/parts/detialHeader";
import ProjectCard, {
	LoaderPropertyCard,
} from "../components/home/parts/ProjectCard";
import { useRef } from "react";
import Button from "../components/UI/Button";
import { toast } from "react-toastify";
import { HashLink } from "react-router-hash-link";
import SliderCarousel from "../components/SliderCarousel";
import ReadMore from "../components/ReadMore";
import useMediaQuery from "../components/useMediaQuery";
import { useDownloadApp } from "../helpers/useDownloadApp";
function Property() {
	const siteUrl = useParams();
	const isMobile = useMediaQuery("(max-width: 768px)");
	const downloadApp = useDownloadApp();

	const [showDownloadForm, setShowDownloadForm] = useState(false);
	const [InquiryForm, setInquiryForm] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [propertyDetails, setPropertyDetails] = useState({});
	const [ImageSlider, setImageSlider] = useState([]);
	const [FloorImgs, setFloorImgs] = useState({});
	const [Amenities, setAmenities] = useState([]);
	const [bankOffers, setbankOffers] = useState([]);
	const [SeletedPropConfig, setSeletedPropConfig] = useState([]);
	const [BrochurePDF, setBrochurePDF] = useState();
	const [activeFloor, setActiveFloor] = useState(0);
	const [projectDetails, setprojectDetails] = useState([]);
	const [similarLoactionData, setSimilarLoactionData] = useState([]);
	const [differntProperty, setdifferntProperty] = useState([]);
	const [landMarkArea, setLandMarkArea] = useState();
	const [loading, setLoading] = useState(false);

	const [previewFloor, setPreviewFloor] = useState([]);

	const [fullscreen, setFullscreen] = useState(true);
	const [toggle, setToggle] = useState(false);
	const [sIndex, setSIndex] = useState(0);

	const [toggle1, setToggle1] = useState(false);
	const [sIndex1, setSIndex1] = useState(0);
	const [similarDataLoader, setSimilarDataLoader] = useState(true);
	const [differentocationLoader, setDifferentocationLoader] = useState(true);
	// Handler
	const lightBoxHandler = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};

	const FloorLightBox = (state, floorsImage, sIndex) => {
		setPreviewFloor(floorsImage);
		setToggle1(state);
		setSIndex1(sIndex);
	};

	const inquiryForm = useRef(null);

	const executeScroll = () => inquiryForm.current.scrollIntoView();

	function handleShow(breakpoint) {
		setFullscreen(breakpoint);
		setShowDownloadForm(true);
	}

	const navlinks = [
		{
			name: "Overview",
			link: "#overview",
		},
		{
			name: "Description",
			link: "#description",
		},
		{
			name: "Amenities",
			link: "#amenities",
		},
		{
			name: "Floor Plan",
			link: "#floor-plan",
		},
		{
			name: "Brochure",
			link: "#brochure",
		},
		{
			name: "Map Location",
			link: "#map-location",
		},
		{
			name: "Bank Offers",
			link: "#bank-offers",
		},
	];

	// console.log(siteUrl.slug.split("ZBID00"));

	const location = useLocation();
	const state = location.state;
	const property_id = state?.proj_id ?? siteUrl?.slug?.split("ZBID00")[1];
	const propertyName = state?.proj_name;
	const propertyLocation = state?.proj_loc;
	const propertyType = state?.proj_type;

	const [fullWidth, setFullWidth] = useState(false);
	const [showAll, setShowAll] = useState(false);

	const changeNavbarColor = () => {
		if (window.scrollY >= 600) {
			setFullWidth(true);
		} else {
			setFullWidth(false);
		}
	};

	// console.log(property_id);

	window.addEventListener("scroll", changeNavbarColor);
	const [formData, updateFormData] = useState({
		property_enquire: "true",
	});
	const [checked, setChecked] = useState(false);
	const [phone, setPhone] = useState();
	const [otp, setOtp] = useState();

	const [showOtp, setShowOtp] = useState(false);
	const [isVerified, setIsVerified] = useState(false);

	const multiSelct = (e) => {
		if (e.target.type === "checkbox") {
			if (e.target.checked) {
				setSeletedPropConfig((previousData) => [
					...previousData,
					e.target.value,
				]);
			} else {
				setSeletedPropConfig((previousData) =>
					previousData.filter((data) => data !== e.target.value)
				);
			}
		}
	};

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const download_file = async (fileURL, fileName) => {
		// for non-IE
		if (!window.ActiveXObject) {
			var save = document.createElement("a");
			save.href = fileURL;
			save.target = "_blank";
			var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
			save.download = fileName || filename;
			if (
				navigator.userAgent
					.toLowerCase()
					.match(/(ipad|iphone|safari)/) &&
				navigator.userAgent.search("Chrome") < 0
			) {
				document.location = save.href;
				// window event not working here
			} else {
				var evt = new MouseEvent("click", {
					view: window,
					bubbles: true,
					cancelable: false,
				});
				save.dispatchEvent(evt);
				(window.URL || window.webkitURL).revokeObjectURL(save.href);
			}
		}

		// for IE < 11
		else if (!!window.ActiveXObject && document.execCommand) {
			var _window = window.open(fileURL, "_blank");
			_window.document.close();
			_window.document.execCommand("SaveAs", true, fileName || fileURL);
			_window.close();
		}
	};
	// const docDownLoad = async( pdfurl) => {
	//   await fetch(pdfurl, { method: "GET" }).then((response) => {
	//     response.blob().then((blob) => {
	//       const fileURL = window.URL.createObjectURL(blob);
	//       let alink = document.createElement("a");
	//       alink.href = fileURL;
	//       alink.download = "Brochure.pdf";
	//       alink.click();
	//     });
	//   });
	// };

	const handleDonwloadpdf = async (e) => {
		// var pdfUrl = `${BrochurePDF}`;
		if (isVerified) {
			const response = await api({
				method: "post",
				url: "send_inquiry.php",
				data: {
					...formData,
					property_id: property_id,
					property_enquire: true,
					downloadPDF: true,
					phone: phone,
				},
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			if (jsonData.status === 200) {
				toast.success("Form Submitted Successfully");
				await download_file(BrochurePDF, "Brochure.pdf");
				var close = setInterval(() => {
					setShowDownloadForm(false);
					updateFormData();
					window.location.reload();
					clearInterval(close);
				}, 2000);
			}
		} else {
			toast.error("Please Verify Phone Number");
		}
	};

	const handleInquire = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (isVerified) {
			const response = await api({
				method: "post",
				url: "send_inquiry.php",
				data: {
					...formData,
					property_id: property_id,
					property_enquire: true,
					phone: phone,
					inquiry: true,
					prop_config: SeletedPropConfig,
				},
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			if (jsonData.status === 200) {
				toast.success("Form Submitted Successfully");

				setLoading(false);
				var close = setInterval(() => {
					setShowDownloadForm(false);
					updateFormData();
					window.location.reload();
					clearInterval(close);
				}, 1000);
			}
		} else {
			setLoading(false);
			toast.error("Please Verify Phone Number");
		}
	};

	const SendOTP = async (e, msg = "Sent") => {
		e.preventDefault();
		const response = await api({
			method: "post",
			url: "verify_mobile.php",
			data: { phone: phone, send_otp: true },
			headers: { "Content-Type": "multipart/form-data" },
		});
		const jsonData = await response.data;
		if (jsonData.Status === "Success") {
			setShowOtp(true);
			setOtp("");
			toast.success(`OTP ${msg}`);
		} else {
			toast.error("Invalid Phone Number");
		}
	};
	const VerifyOTP = async (e) => {
		e.preventDefault();
		const response = await api({
			method: "post",
			url: "verify_mobile.php",
			data: { phone: phone, otp: otp, verify_otp: true },
			headers: { "Content-Type": "multipart/form-data" },
		});
		const jsonData = await response.data;
		if (jsonData.Status === "Success") {
			setIsVerified(true);
			toast.success("Phone Number Verified");
		} else {
			toast.error("OTP Mismatch");
		}
	};

	useEffect(() => {
		const loadProperty = async () => {
			const response = await api({
				method: "post",
				url: "fetch_property_details.php",
				data: { property_id: property_id },
				headers: { "Content-Type": "multipart/form-data" },
			});

			const jsonData = await response.data;
			if (jsonData.status === 200) {
				setPropertyDetails(jsonData.data.details);
				setImageSlider(jsonData.data.images);
				setFloorImgs(jsonData.data.floor_img);
				setAmenities(jsonData.data.proj_amenties);
				setprojectDetails(jsonData.data.projectDetails);
				setBrochurePDF(jsonData.data.details.proj_brochure);
				setbankOffers(jsonData.data.bankOffers);
				setLandMarkArea(jsonData.data.nearby_place);
			}
		};

		const loadToprojects = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch_property.php",
					data: {
						proj_loc: propertyLocation,
						similarPrperty_id: property_id,
						propertyType: propertyType,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setSimilarLoactionData(jsonData.data);
			} catch (error) {
				if (error.response.status === 400) {
					setSimilarLoactionData([]);
					setSimilarDataLoader(false);
				}
				return error.response;
			}
		};

		const loaddifferentProperty = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch_property.php",
					data: {
						proj_loc: propertyLocation,
						differntPrperty_id: property_id,
						propertyType: propertyType,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setdifferntProperty(jsonData.data);
			} catch (error) {
				if (error.response.status === 400) {
					setSimilarLoactionData([]);
					setDifferentocationLoader(false);
				}
				return error.response;
			}
		};

		Promise.all([
			loadProperty(),
			loadToprojects(),
			loaddifferentProperty(),
		]);
	}, [propertyLocation, propertyType, property_id]);

	const [timer, setTimer] = useState(60);
	const timeOutCallback = useCallback(
		() => setTimer((currTimer) => currTimer - 1),
		[]
	);

	useEffect(() => {
		let timeout;
		timer > 0 && (timeout = setTimeout(timeOutCallback, 1000));

		return () => clearTimeout(timeout);
	}, [timer, timeOutCallback]);

	const resetTimer = function (e) {
		if (!timer) {
			SendOTP(e, "Resent");
			setTimer(60);
		}
	};

	return (
		<PageLayout
			isTransperant={true}
			isfooter={false}
			title={propertyName}
			isProjectDetails
		>
			<div
				className="container mb-5"
				style={{
					marginTop: 100,
				}}
			>
				{isMobile && (
					<div className="px-3">
						<h3 className="fw-bold mb-0">
							{propertyDetails && propertyDetails.proj_name}
						</h3>
						<div className="d-flex align-items-center mt-1 mb-3">
							<GeoAltFill />
							<p
								className="text-uppercase ms-2"
								style={{
									fontWeight: 500,
								}}
							>
								{propertyDetails.proj_loc &&
									propertyDetails.proj_loc}
								<strong>-</strong>
								{propertyDetails.pincode &&
									propertyDetails.pincode}
							</p>
						</div>
					</div>
				)}
				<div className="row" style={{ width: "100%", margin: 0 }}>
					<div className="col-md-9">
						<div className="row" style={{ margin: 0 }}>
							<Lightbox
								open={toggle}
								close={() => setToggle(false)}
								index={sIndex}
								slides={ImageSlider}
								plugins={[Zoom]}
								animation={{ zoom: 500 }}
								zoom={{
									maxZoomPixelRatio: 10,
									zoomInMultiplier: 2,
									doubleTapDelay: 300,
									doubleClickDelay: 2,
									doubleClickMaxStops: 2,
									keyboardMoveDistance: 50,
									wheelZoomDistanceFactor: 100,
									pinchZoomDistanceFactor: 100,
									scrollToZoom: false,
								}}
							/>
							<div className="col-md-6 pe-md-2 px-0 headerPartcarousal">
								{ImageSlider.length > 0 ? (
									<OwlCarousel
										className="owl-stage-outer"
										items={1}
										loopautoplay="true"
										nav
										loop
										navClass={["nav-prev", "nav-next"]}
										dots={false}
										draggable="false"
										autoplay
									>
										{ImageSlider.length > 0 ? (
											ImageSlider.map((item, index) => (
												<div
													key={index}
													className="item"
													onClick={() => {
														lightBoxHandler(
															true,
															index
														);
													}}
												>
													<div
														className="itemCustom"
														style={{
															backgroundImage: `url('${item.src}')`,
															backgroundColor:
																hexToRGB(
																	config.primaryColor,
																	0.3
																),
														}}
													></div>
												</div>
											))
										) : (
											<></>
										)}
									</OwlCarousel>
								) : (
									<div
										className="shimmer d-block"
										style={{
											paddingTop: "calc(30000%/450)",
										}}
									></div>
								)}
							</div>

							<div
								className={`col-md-6  mt-md-0 mt-4 propertyVideoWrapper pos-r p-0 ${
									propertyDetails.proj_video && "shimmer"
								}`}
							>
								{propertyDetails.proj_video ? (
									<div
										style={{
											paddingTop: "calc(30000%/450)",
										}}
										dangerouslySetInnerHTML={{
											__html:
												propertyDetails &&
												propertyDetails.proj_video,
										}}
									></div>
								) : (
									<div className="pos-r w-100 h-100 d-flex justify-content-center align-items-center videoBlank">
										<p>This video is unavailable.</p>
									</div>
								)}
							</div>

							<div className="col-md-12 py-4">
								<div className="row">
									<div className="col-md-8">
										<h3 className="fw-bold mb-0">
											{propertyDetails &&
												propertyDetails.proj_name}
										</h3>
										<div className="d-flex align-items-center mt-1">
											<GeoAltFill />
											<p
												className="text-uppercase ms-2"
												style={{
													fontWeight: 500,
												}}
											>
												{propertyDetails.proj_loc &&
													propertyDetails.proj_loc}
												<strong>-</strong>
												{propertyDetails.pincode &&
													propertyDetails.pincode}
											</p>
										</div>
										<h4
											className="fw-bold mb-0"
											style={{ fontSize: 18 }}
										>
											₹
											{propertyDetails.proj_display_price &&
												propertyDetails.proj_display_price}
										</h4>
										<div>
											₹{" "}
											{propertyDetails &&
												propertyDetails.proj_area_rate}
											/Per Sq. ft. <br />
											<strong>
												EMI Starting at : ₹
												{propertyDetails &&
													propertyDetails.emi_amount}{" "}
												/ Per Month
												<HashLink
													to="/home-loan#calc-emi-section-main"
													className="text-decoration-none ms-2"
													style={{
														color: config.thirdColor,
													}}
													aria-label="EMI Calculator"
												>
													<Calculator fontSize={20} />
												</HashLink>
											</strong>
										</div>
									</div>
									<div className="col-md-4">
										<div style={{ fontSize: 14 }}>
											Price Excluding of Stamp duty,
											Registration, G.S.T & other charges
											<br />
											EMI calculation is standard for 20
											years @8.60% R.O.I per annum.
											<br />
											<div className="d-flex pt-3">
												<FacebookShareButton
													url={`https://zerobroker.in${location.pathname}`}
												>
													<FacebookIcon
														size={40}
														round={true}
													/>
												</FacebookShareButton>
												<WhatsappShareButton
													title={
														"Check out this awesome property that I found on Zerobroker:"
													}
													url={`https://zerobroker.in${location.pathname}`}
													className="mx-1"
													style={{
														background: "#25D366",
														color: "white",
														borderRadius: 100,
													}}
												>
													<Whatsapp
														size={20}
														style={{ margin: 10 }}
													/>
												</WhatsappShareButton>
												<LinkedinShareButton
													url={`https://zerobroker.in${location.pathname}`}
													className="mx-1"
													style={{
														background: "#0A66C2",
														color: "white",
														borderRadius: 100,
													}}
												>
													<Linkedin
														size={20}
														style={{ margin: 10 }}
													/>
												</LinkedinShareButton>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-3 p-3 shadow">
						<div
							className="card border-0"
							style={{ height: "100%" }}
						>
							<h4 className="fw-bold">About Developer</h4>

							<hr className="mt-0" />

							<ReadMore className="text-justify">
								{propertyDetails &&
									propertyDetails.about_developers}
							</ReadMore>

							<div style={{ height: "100%" }}></div>
							<button
								onClick={executeScroll}
								className="btn  mt-3 text-white"
								style={{ background: config.primaryColor }}
							>
								Contact
							</button>
						</div>
					</div>
				</div>
			</div>

			<Lightbox
				open={toggle1}
				close={() => setToggle1(false)}
				index={sIndex1}
				slides={previewFloor}
				plugins={[Zoom, Captions]}
				animation={{ zoom: 500 }}
				zoom={{
					maxZoomPixelRatio: 10,
					zoomInMultiplier: 4,
					doubleTapDelay: 300,
					doubleClickDelay: 2,
					doubleClickMaxStops: 2,
					keyboardMoveDistance: 50,
					wheelZoomDistanceFactor: 100,
					pinchZoomDistanceFactor: 100,
					scrollToZoom: false,
				}}
			/>
			<nav
				className={`navbar horizontalNav navbar-expand-lg position-sticky ${
					!fullWidth && "container"
				}`}
				style={{ background: config.primaryColor, width: "100%" }}
			>
				<div className="container-fluid">
					<div className="navbar-nav me-auto ms-auto flex-row">
						{navlinks.map((item, index) => (
							<a
								href={item.link}
								key={index}
								style={{ background: config.primaryColor }}
							>
								{item.name}
							</a>
						))}
					</div>
				</div>
			</nav>
			<div className="container pt-4 pb-lg-2 pb-5">
				<div className="row pb-lg-2 pb-5">
					<div className="col-md-8 p-2 pb-lg-3 pb-5">
						<div className="card shadow p-3 border-0">
							<div className="nav-disorder-parent-fix">
								<span
									className="nav-disorder-child-fix"
									id="overview"
								></span>
								<DetialHeader title={"Overview"} />
								<h4 className="fw-light my-3">
									Project Details
								</h4>
								<div className="row">
									{projectDetails.map((item, index) => (
										<div
											key={index}
											className="col-md-4 mb-3"
										>
											<span style={{ fontSize: 14 }}>
												{item.type}
											</span>
											<br />
											<span className="fw-bold">
												{item.detail}
											</span>
										</div>
									))}
								</div>
							</div>

							<div className="nav-disorder-parent-fix">
								<span
									className="nav-disorder-child-fix"
									id="description"
								></span>
								<DetialHeader title={"Description"} />
								<h4 className="fw-light my-3">
									About Property
								</h4>
								<p className="text-justify">
									{propertyDetails &&
										propertyDetails.proj_about}
								</p>
							</div>
							<div className="nav-disorder-parent-fix">
								<span
									className="nav-disorder-child-fix"
									id="amenities"
								></span>
								<DetialHeader title={"AMENITIES"} />
								<h4 className="fw-light my-3">Amenities</h4>
								<div className="amenities row">
									{Amenities &&
										Amenities.map((item, index) =>
											!showAll ? (
												index <= 7 ? (
													<div
														key={index}
														className="amItem col-md-3 col-6"
													>
														<div className="card shadow">
															<img
																src={item.src}
																alt={item.title}
															/>
															<span className="text-center">
																{item.title}
															</span>
														</div>
													</div>
												) : null
											) : (
												<div
													key={index}
													className="amItem col-md-3 col-6"
												>
													<div className="card shadow">
														<img
															src={item.src}
															alt={item.title}
														/>
														<span className="text-center">
															{item.title}
														</span>
													</div>
												</div>
											)
										)}
									{Amenities && Amenities.length >= 7 && (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<button
												className="btn btn-sm m-3"
												style={{
													width: "fit-content",
													background: hexToRGB(
														config.primaryColor,
														0.2
													),
												}}
												onClick={() =>
													setShowAll(!showAll)
												}
											>
												{!showAll ? (
													<>
														<i className="fa fa-arrow-down me-2"></i>
														Show More
													</>
												) : (
													<>
														<i className="fa fa-arrow-up me-2"></i>
														Show Less
													</>
												)}
											</button>
										</div>
									)}
								</div>
							</div>

							<div className="nav-disorder-parent-fix">
								<span
									className="nav-disorder-child-fix"
									id="floor-plan"
								></span>
								<DetialHeader title={"UNITS & FLOOR PLAN"} />
								{/* <h4 className='fw-light my-3'>Units & Floor Plan Images</h4> */}
								<div className="floorplan d-flex mt-3">
									{Object.entries(FloorImgs).length > 0 ? (
										Object.entries(FloorImgs).map(
											(item, index) => {
												return (
													<div key={index}>
														<button
															className={`btn me-2 px-3 ${
																activeFloor ===
																index
																	? "text-white"
																	: ""
															}`}
															style={{
																border: `2px solid ${config.primaryColor}`,
																background:
																	activeFloor ===
																	index
																		? config.primaryColor
																		: "transparent",
															}}
															onClick={() =>
																setActiveFloor(
																	index
																)
															}
														>
															{item[0]}
														</button>
													</div>
												);
											}
										)
									) : (
										<div className="projectNoticeCard err red w-100 mt-4 text-center">
											Floor Plan not Available
										</div>
									)}
								</div>

								<div className="py-2 row">
									{Object.entries(FloorImgs).map(
										(item, index) => {
											return (
												activeFloor === index &&
												item[1].map((floor, id) => {
													return (
														<div
															className="col-lg-4 col-md-6 col-12 pos-r"
															key={id}
														>
															<img
																className="img-fluid"
																src={floor.src}
																alt={floor.area}
																width={300}
																onClick={() => {
																	FloorLightBox(
																		true,
																		item[1],
																		id
																	);
																}}
															/>
															{floor.area && (
																<p className="sqfitcard">
																	{floor.area}{" "}
																	{/* sq.ft */}
																</p>
															)}
														</div>
													);
												})
											);
										}
									)}
								</div>
							</div>

							<DetialHeader title={"NEARBY PROJECTS"} />

							{similarDataLoader ? (
								<div className="container  px-0">
									<SliderCarousel
										dots={false}
										infinite={true}
										speed={500}
										slidesToShow={3}
										slidesToScroll={1}
										arrows={true}
										autoplay={true}
										draggable={false}
										responsive={[
											{
												breakpoint: 900,
												settings: {
													slidesToShow: 3,
													slidesToScroll: 1,
												},
											},
											{
												breakpoint: 768,
												settings: {
													slidesToShow: 2,
													slidesToScroll: 1,
												},
											},
											{
												breakpoint: 500,
												settings: {
													slidesToShow: 1,
													slidesToScroll: 1,
												},
											},
										]}
										className="homepage-offer-slider"
									>
										{similarLoactionData.length > 0 ? (
											similarLoactionData.map(
												(item, index) => (
													<div
														key={index}
														className="p-1"
													>
														<ProjectCard
															key={index}
															item={item}
														/>
													</div>
												)
											)
										) : (
											<>
												<div className="p-1">
													<LoaderPropertyCard />
												</div>
												<div className="p-1">
													<LoaderPropertyCard />
												</div>
												<div className="p-1">
													<LoaderPropertyCard />
												</div>
											</>
										)}
									</SliderCarousel>
									{/* <OwlCarousel
                    className="owl-stage-outer"
                    items={3}
                    navClass={["nav-prev", "nav-next"]}
                    autoPlay
                    autoplayTimeout={4000}
                    loop
                    nav
                    responsive={{
                      0: {
                        items: 1,
                      },
                      700: {
                        items: 3,
                      },
                    }}
                  >
                    {similarLoactionData.length > 0 ? (
                      similarLoactionData.map((item, index) => (
                        <div key={index} className="p-1">
                          <ProjectCard key={index} item={item} />
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="p-1">
                          <LoaderPropertyCard />
                        </div>
                        <div className="p-1">
                          <LoaderPropertyCard />
                        </div>
                        <div className="p-1">
                          <LoaderPropertyCard />
                        </div>
                      </>
                    )}
                  </OwlCarousel> */}
								</div>
							) : (
								<div className="projectNoticeCard err red w-100 mt-4 text-center">
									Property Not Available
								</div>
							)}

							<div className="nav-disorder-parent-fix">
								<DetialHeader title={"BROCHURE"} />
								<span
									className="nav-disorder-child-fix"
									id="brochure"
								></span>
								<button
									onClick={() => handleShow("md-down")}
									className="btn btn-danger btn-lg my-3"
								>
									<FilePdf /> Download E-Brochure
								</button>
							</div>

							<div className="nav-disorder-parent-fix">
								<span
									className="nav-disorder-child-fix"
									id="map-location"
								></span>
								<DetialHeader title={"MAP LOCATION"} />
								<h4 className="fw-light my-3">Map Location</h4>
								<div
									className="shimmer d-block propertyMapWrpper pos-r"
									style={{ paddingTop: "calc(40000%/800)" }}
									dangerouslySetInnerHTML={{
										__html:
											propertyDetails &&
											propertyDetails.proj_map,
									}}
								></div>
								<h4 className="fw-light my-3">Nearby Places</h4>
								{landMarkArea &&
									landMarkArea["landmark"].length > 1 && (
										<>
											<LocationTitle title="Prominent Landmarks" />
											{landMarkArea["landmark"].map(
												(land, index) => (
													<NearbyLocation
														key={index}
														title={
															land.prominent_landmark_name
														}
														distance={
															land.prominent_landmark_distance
														}
													/>
												)
											)}
										</>
									)}

								{landMarkArea &&
									landMarkArea["railway"].length > 1 && (
										<>
											<LocationTitle title="Metro & Railway" />
											{landMarkArea["railway"].map(
												(land, index) => (
													<NearbyLocation
														key={index}
														title={
															land.metro_and_railways_name
														}
														distance={
															land.metro_and_railways_distance
														}
													/>
												)
											)}
										</>
									)}
								{landMarkArea &&
									landMarkArea["malls"].length > 1 && (
										<>
											<LocationTitle title="Malls & Marts" />
											{landMarkArea["malls"].map(
												(land, index) => (
													<NearbyLocation
														key={index}
														title={
															land.malls_and_marts_name
														}
														distance={
															land.malls_and_marts_distance
														}
													/>
												)
											)}
										</>
									)}
								{landMarkArea &&
									landMarkArea["education"].length > 1 && (
										<>
											<LocationTitle title="Educational Institutes" />
											{landMarkArea["education"].map(
												(land, index) => (
													<NearbyLocation
														key={index}
														title={
															land.educational_institute_name
														}
														distance={
															land.educational_institute_distance
														}
													/>
												)
											)}
										</>
									)}
								{landMarkArea &&
									landMarkArea["hospital"].length > 1 && (
										<>
											<LocationTitle title="Hospitals" />
											{landMarkArea["hospital"].map(
												(land, index) => (
													<NearbyLocation
														key={index}
														title={
															land.hospital_name
														}
														distance={
															land.hospital_distance
														}
													/>
												)
											)}
										</>
									)}
							</div>

							<DetialHeader title="OTHER LOCATIONS" />
							{differentocationLoader ? (
								<div className="container px-0">
									<SliderCarousel
										dots={false}
										infinite={true}
										speed={500}
										slidesToShow={3}
										slidesToScroll={1}
										arrows={true}
										autoplay={true}
										draggable={false}
										responsive={[
											{
												breakpoint: 900,
												settings: {
													slidesToShow: 3,
													slidesToScroll: 1,
												},
											},
											{
												breakpoint: 768,
												settings: {
													slidesToShow: 2,
													slidesToScroll: 1,
												},
											},
											{
												breakpoint: 500,
												settings: {
													slidesToShow: 1,
													slidesToScroll: 1,
												},
											},
										]}
										className="homepage-offer-slider"
									>
										{differntProperty.length > 0 ? (
											differntProperty.map(
												(item, index) => (
													<div
														key={index}
														className="p-1"
													>
														<ProjectCard
															item={item}
														/>
													</div>
												)
											)
										) : (
											<>
												<div className="p-1">
													<LoaderPropertyCard />
												</div>
												<div className="p-1">
													<LoaderPropertyCard />
												</div>
												<div className="p-1">
													<LoaderPropertyCard />
												</div>
											</>
										)}
									</SliderCarousel>
									{/* <OwlCarousel
                    className="owl-stage-outer"
                    items={3}
                    navClass={["nav-prev", "nav-next"]}
                    autoPlay
                    autoplayTimeout={4000}
                    loop
                    nav
                    responsive={{
                      0: {
                        items: 1,
                      },
                      700: {
                        items: 3,
                      },
                    }}
                  >
                    {differntProperty.length > 0 ? (
                      differntProperty.map((item, index) => (
                        <div key={index} className="p-1">
                          <ProjectCard item={item} />
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="p-1">
                          <LoaderPropertyCard />
                        </div>
                        <div className="p-1">
                          <LoaderPropertyCard />
                        </div>
                        <div className="p-1">
                          <LoaderPropertyCard />
                        </div>
                      </>
                    )}
                  </OwlCarousel> */}
								</div>
							) : (
								<div className="projectNoticeCard red w-100 mt-4 text-center">
									Property Not Available
								</div>
							)}

							<div className="nav-disorder-parent-fix pt-3">
								<span
									className="nav-disorder-child-fix"
									id="bank-offers"
								></span>
								<DetialHeader title="AVAIL BANK OFFERS" />
								<div className="row mx-1 mt-2">
									{bankOffers.map((item, index) => (
										<div
											className="cardHover col-lg-2 col-md-3 col-6"
											key={index}
										>
											<div
												className="border rounded d-flex h-100 align-items-center justify-content-center p-3"
												style={{
													alignItems: "center",
													overflow: "hidden",
												}}
											>
												<a
													href="/home-loan"
													className="w-100"
												>
													<img
														src={item.src}
														alt="BANK OFFERS"
														className="img-fluid"
													/>
												</a>
											</div>
										</div>
									))}
								</div>
							</div>

							<DetialHeader title={"SHARE"} />
							<div className="d-flex p-3">
								<FacebookShareButton
									url={`https://zerobroker.in${location.pathname}`}
								>
									<FacebookIcon size={40} round={true} />
								</FacebookShareButton>
								<WhatsappShareButton
									url={`https://zerobroker.in${location.pathname}`}
									className="mx-1"
									style={{
										background: "#25D366",
										color: "white",
										borderRadius: 100,
									}}
								>
									<Whatsapp
										size={20}
										style={{ margin: 10 }}
									/>
								</WhatsappShareButton>
								<LinkedinShareButton
									url={`https://zerobroker.in${location.pathname}`}
									className="mx-1"
									style={{
										background: "#0A66C2",
										color: "white",
										borderRadius: 100,
									}}
								>
									<Linkedin
										size={20}
										style={{ margin: 10 }}
									/>
								</LinkedinShareButton>
							</div>
						</div>
					</div>
					<div
						className="col-md-4 p-2 pt-md-5 pt-0 mb-md-2 mb-2"
						ref={inquiryForm}
					>
						<div
							className={`card shadow p-3 border-0 stickyForm offer-card`}
						>
							<h3 className="mb-3">
								For Latest Offers <br />
								Enquire Now!
							</h3>
							<form action="">
								<div className="form-floating mb-3">
									<input
										type="text"
										className="form-control"
										id="full_Name"
										name="fullName"
										placeholder="Full Name"
										onChange={handleChange}
									/>
									<label htmlFor="full_Name">Full Name</label>
								</div>
								<div className="form-floating mb-3">
									<input
										type="email"
										className="form-control"
										id="floatingInput"
										name="email"
										placeholder="name@example.com"
										onChange={handleChange}
									/>
									<label htmlFor="floatingInput">
										Email address
									</label>
								</div>
								{propertyDetails.proj_type !== "Commercial" && (
									<p>Select Configuration</p>
								)}
								{propertyDetails.proj_type !== "Commercial" &&
									Object.entries(FloorImgs).map(
										(item, index) => {
											return (
												<div
													className="form-check mb-1"
													key={index}
												>
													<input
														className="form-check-input"
														type="checkbox"
														value={item[0]}
														name="bhk"
														id={"size" + index}
														onChange={multiSelct}
													/>
													<label
														className="form-check-label"
														htmlFor={"size" + index}
													>
														{item[0]}
													</label>
												</div>
											);
										}
									)}
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										name="Interested"
										id="interested"
										defaultChecked={true}
										onChange={handleChange}
									/>
									<label
										className="form-check-label"
										htmlFor="interested"
									>
										I am Interested
									</label>
								</div>
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										name="Interested_in_home_loan"
										id="loan"
										onChange={handleChange}
									/>
									<label
										className="form-check-label"
										htmlFor="loan"
									>
										I am interested in home loan
									</label>
								</div>
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										id="terms"
										defaultChecked={true}
										onChange={() => setChecked(!checked)}
									/>
									<label
										className="form-check-label"
										htmlFor="terms"
									>
										I agree to be contacted by ZERO BROKER
										for Similar Properties or related
										services via Whatsapp, Phone, SMS,
										E-Mail etc.
									</label>
								</div>
								{!showOtp ? (
									<div className="input-group mt-3">
										<div className="form-floating">
											<input
												type="tel"
												className="form-control"
												id="phone"
												name="phone"
												placeholder="Enter OTP"
												onChange={(e) =>
													setPhone(e.target.value)
												}
											/>
											<label htmlFor="phone">Phone</label>
										</div>
										<Button
											className="btn btn-outline-secondary"
											type="button"
											onClick={(e) => SendOTP(e, "Sent")}
										>
											Send OTP
										</Button>
									</div>
								) : (
									<>
										<div className="input-group">
											<div className="form-floating">
												<input
													type="tel"
													className="form-control"
													id="otp"
													name="otp"
													placeholder="Enter OTP"
													value={otp}
													onChange={(e) =>
														setOtp(e.target.value)
													}
												/>
												<label htmlFor="otp">
													Enter OTP
												</label>
											</div>
											{!isVerified ? (
												<button
													className="btn btn-outline-secondary"
													type="button"
													onClick={VerifyOTP}
												>
													Verify OTP
												</button>
											) : (
												<button
													className="btn btn-outline-secondary"
													type="button"
													disabled
												>
													Verified
												</button>
											)}
										</div>
										{timer <= 0 ? (
											<p
												className="resend-timer btn-link"
												onClick={(e) => resetTimer(e)}
											>
												Resend OTP
											</p>
										) : (
											<p className="resend-timer">
												Resend OTP in {timer}
											</p>
										)}
									</>
								)}
								<Button
									type="button"
									loading={loading}
									className="btn btn-primary mt-1"
									onClick={handleInquire}
								>
									Submit
								</Button>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="side_bar_widgets stickySideBar sticky-right right-0">
				<a
					href={`https://wa.me/919930704010/?text=I’m interested in this project ${
						propertyDetails && propertyDetails.proj_name
					}, ${
						propertyDetails && propertyDetails.proj_loc
					} kindly share me more details`}
					target="_blank"
					rel="noreferrer"
					className="stickySideBar__Nav"
				>
					<div className="icon">
						<Whatsapp />
					</div>
					<p>Whatsapp</p>
				</a>
				<div
					onClick={() => handleShow("md-down")}
					className="stickySideBar__Nav"
				>
					<div className="icon">
						<FilePdf />
					</div>
					<p>Download E-Brochure</p>
				</div>
				<div
					onClick={() => setShowForm(true)}
					className="stickySideBar__Nav custom_mobile"
				>
					<div className="icon">
						<Telephone />
					</div>
					<p>Phone </p>
				</div>
				<Link
					to="/home-loan#bankLoansSection"
					className="stickySideBar__Nav custom_desktop"
					aria-label="EMI Calculator"
				>
					<div className="icon">
						<Calculator />
					</div>
					<p>EMI Calculator</p>
				</Link>
				<button
					type="button"
					onClick={downloadApp}
					className="stickySideBar__Nav custom_desktop"
				>
					<ImMobile />
					<p>Install App</p>
				</button>
			</div>
			{/* <DownloadBrochure /> */}
			<Modal
				show={InquiryForm}
				onHide={() => setInquiryForm(false)}
				centered
				fullscreen={fullscreen}
				backdropClassName="backdropModel"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header className="border-0" closeButton></Modal.Header>
				<Modal.Body>
					<div className="">
						<div
							className={`${
								window.innerWidth < 786 ? "mobile-modal" : ""
							}`}
						>
							<div className={`card border-0 stickyForm`}>
								<h3 className="mb-3">
									For Latest Offers Enquire Now!
								</h3>
								<form action="">
									<div className="form-floating mb-3">
										<input
											type="text"
											className="form-control"
											id="full_Name"
											name="fullName"
											placeholder="Full Name"
											onChange={handleChange}
										/>
										<label htmlFor="full_Name">
											Full Name
										</label>
									</div>
									<div className="form-floating mb-3">
										<input
											type="email"
											className="form-control"
											id="floatingInput"
											name="email"
											placeholder="name@example.com"
											onChange={handleChange}
										/>
										<label htmlFor="floatingInput">
											Email address
										</label>
									</div>
									<p>Select Configuration</p>

									{Object.entries(FloorImgs).map(
										(item, index) => {
											return (
												<div
													className="form-check mb-1"
													key={index}
												>
													<input
														className="form-check-input"
														type="checkbox"
														value={item[0]}
														name="bhk"
														id="size"
														onChange={multiSelct}
													/>
													<label
														className="form-check-label"
														htmlFor="size"
													>
														{item[0]}
													</label>
												</div>
											);
										}
									)}
									<div className="form-check">
										<input
											className="form-check-input"
											type="checkbox"
											name="Interested"
											id="interested"
											defaultChecked={true}
											onChange={handleChange}
										/>
										<label
											className="form-check-label"
											htmlFor="interested"
										>
											I am Interested
										</label>
									</div>
									<div className="form-check">
										<input
											className="form-check-input"
											type="checkbox"
											name="Interested_in_home_loan"
											id="loan"
											onChange={handleChange}
										/>
										<label
											className="form-check-label"
											htmlFor="loan"
										>
											I am interested in home loan
										</label>
									</div>
									<div className="form-check">
										<input
											className="form-check-input"
											type="checkbox"
											id="terms"
											defaultChecked={true}
											onChange={() =>
												setChecked(!checked)
											}
										/>
										<label
											className="form-check-label"
											htmlFor="terms"
										>
											I agree to be contacted by ZERO
											BROKER for Similar Properties or
											related services via Whatsapp,
											Phone, SMS, E-Mail etc.
										</label>
									</div>
									{!showOtp ? (
										<div className="input-group mt-3">
											<div className="form-floating">
												<input
													type="tel"
													className="form-control"
													id="phone"
													name="phone"
													placeholder="Enter OTP"
													onChange={(e) =>
														setPhone(e.target.value)
													}
												/>
												<label htmlFor="phone">
													Phone
												</label>
											</div>
											<button
												className="btn btn-outline-secondary"
												type="button"
												onClick={(e) =>
													SendOTP(e, "Sent")
												}
											>
												<ImSpinner /> Send OTP
											</button>
										</div>
									) : (
										<>
											<div className="input-group">
												<div className="form-floating">
													<input
														type="tel"
														className="form-control"
														id="otp"
														name="otp"
														placeholder="Enter OTP"
														value={otp}
														onChange={(e) =>
															setOtp(
																e.target.value
															)
														}
													/>
													<label htmlFor="otp">
														Enter OTP
													</label>
												</div>
												{!isVerified ? (
													<button
														className="btn btn-outline-secondary"
														type="button"
														onClick={VerifyOTP}
													>
														Verify OTP
													</button>
												) : (
													<button
														className="btn btn-outline-secondary"
														type="button"
														disabled
													>
														Verified
													</button>
												)}
											</div>
											{timer <= 0 ? (
												<p
													className="resend-timer btn-link"
													onClick={(e) =>
														resetTimer(e)
													}
												>
													Resend OTP
												</p>
											) : (
												<p className="resend-timer">
													Resend OTP in {timer}
												</p>
											)}
										</>
									)}
									<Button
										type="button"
										loading={loading}
										className="btn btn-primary mt-1"
										onClick={handleInquire}
									>
										Submit
									</Button>
								</form>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={showDownloadForm}
				onHide={() => setShowDownloadForm(false)}
				centered
				fullscreen={fullscreen}
				backdropClassName="backdropModel"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Body>
					<div className="">
						<div
							className={`${
								window.innerWidth < 786 ? "mobile-modal" : ""
							}`}
						>
							<div className="d-flex justify-content-between">
								<h3 className="mb-4 fw-bold">
									Enter Details To Download E-Brochure
								</h3>
								<CloseButton
									onClick={() => setShowDownloadForm(false)}
									color="#000"
								/>
							</div>
							<div className="form-floating mb-3">
								<input
									type="text"
									className="form-control"
									id="fullName"
									name="fullName"
									placeholder="Name"
									onChange={handleChange}
								/>
								<label htmlFor="fullName">Full Name</label>
							</div>
							<div className="form-floating mb-3">
								<input
									type="email"
									className="form-control"
									id="email"
									name="email"
									placeholder="name@example.com"
									onChange={handleChange}
								/>
								<label htmlFor="email">Email address</label>
							</div>
							{!showOtp ? (
								<div className="input-group mb-3">
									<div className="form-floating">
										<input
											type="tel"
											className="form-control"
											id="phone"
											name="phone"
											placeholder="Enter OTP"
											onChange={(e) =>
												setPhone(e.target.value)
											}
										/>
										<label htmlFor="phone">Phone</label>
									</div>
									<button
										className="btn btn-outline-secondary"
										type="button"
										onClick={(e) => SendOTP(e, "Sent")}
									>
										Send OTP
									</button>
								</div>
							) : (
								<>
									<div className="input-group">
										<div className="form-floating">
											<input
												type="tel"
												className="form-control"
												id="otp"
												name="otp"
												placeholder="Enter OTP"
												value={otp}
												onChange={(e) =>
													setOtp(e.target.value)
												}
											/>
											<label htmlFor="otp">
												Enter OTP
											</label>
										</div>
										{!isVerified ? (
											<button
												className="btn btn-outline-secondary"
												type="button"
												onClick={VerifyOTP}
											>
												Verify OTP
											</button>
										) : (
											<button
												className="btn btn-outline-secondary"
												type="button"
												disabled
											>
												Verified
											</button>
										)}
									</div>
									{timer <= 0 ? (
										<p
											className="resend-timer btn-link"
											onClick={(e) => resetTimer(e)}
										>
											Resend OTP
										</p>
									) : (
										<p className="resend-timer">
											Resend OTP in {timer}
										</p>
									)}
								</>
							)}
							<Button
								type="button"
								loading={loading}
								className="btn btn-primary"
								onClick={handleDonwloadpdf}
							>
								Submit
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={showForm}
				onHide={() => setShowForm(false)}
				centered
				fullscreen={fullscreen}
				backdropClassName="backdropModel"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Body>
					<div className="">
						<div
							className={`${
								window.innerWidth < 786 ? "mobile-modal" : ""
							}`}
						>
							<div className="d-flex justify-content-between">
								<h3 className="mb-4 fw-bold">
									Enter Details To Request Call
								</h3>
								<CloseButton
									onClick={() => setShowForm(false)}
									color="#000"
								/>
							</div>
							<div className="form-floating mb-3">
								<input
									type="text"
									className="form-control"
									id="fullName"
									name="fullName"
									placeholder="Name"
									onChange={handleChange}
								/>
								<label htmlFor="fullName">Full Name</label>
							</div>
							<div className="form-floating mb-3">
								<input
									type="email"
									className="form-control"
									id="email"
									name="email"
									placeholder="name@example.com"
									onChange={handleChange}
								/>
								<label htmlFor="email">Email address</label>
							</div>
							{!showOtp ? (
								<div className="input-group mb-3">
									<div className="form-floating">
										<input
											type="tel"
											className="form-control"
											id="phone"
											name="phone"
											placeholder="Enter OTP"
											onChange={(e) =>
												setPhone(e.target.value)
											}
										/>
										<label htmlFor="phone">Phone</label>
									</div>
									<button
										className="btn btn-outline-secondary"
										type="button"
										onClick={(e) => SendOTP(e, "Sent")}
									>
										Send OTP
									</button>
								</div>
							) : (
								<>
									<div className="input-group">
										<div className="form-floating">
											<input
												type="tel"
												className="form-control"
												id="otp"
												name="otp"
												placeholder="Enter OTP"
												value={otp}
												onChange={(e) =>
													setOtp(e.target.value)
												}
											/>
											<label htmlFor="otp">
												Enter OTP
											</label>
										</div>
										{!isVerified ? (
											<button
												className="btn btn-outline-secondary"
												type="button"
												onClick={VerifyOTP}
											>
												Verify OTP
											</button>
										) : (
											<button
												className="btn btn-outline-secondary"
												type="button"
												disabled
											>
												Verified
											</button>
										)}
									</div>
									{timer <= 0 ? (
										<p
											className="resend-timer btn-link"
											onClick={(e) => resetTimer(e)}
										>
											Resend OTP
										</p>
									) : (
										<p className="resend-timer">
											Resend OTP in {timer}
										</p>
									)}
								</>
							)}
							<button
								className="btn text-white "
								onClick={handleInquire}
								style={{ background: config.primaryColor }}
							>
								Submit
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</PageLayout>
	);
}

export default Property;
