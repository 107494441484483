import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "./../../LazyLoad";

function DeveloperCard({ title, devLink, image, experience, data }) {
  return (
    <div className="col-lg-3 col-md-6 col-6 p-3">
      <Link
        to={`/developer/dev-id000${devLink ? devLink : ""}`}
        state={data}
        className="text-decoration-none text-dark"
      >
        <div
          className="developerCard rounded p-3 d-flex flex-md-row flex-column"
          style={{
            alignItems: "center",
          }}
        >
          <div
            className="border oveflow-hidden rounded me-md-3 pos-r bgImg"
            style={{ height: "100px", width: "100px" }}
          >
            <LazyLoad
              src={image}
              className="pos-a t0 l0"
              style={{ objectFit: "contain" }}
              lazyClass={"pos-a w-100 h-100 t-0 l-0"}
            />
          </div>
          {/* <img
              className=""
              src={image}
              alt={title}
              height={100}
              width={100}
            /> */}
          <div className="custom_desktop">
            <h4>{title}</h4>
            {/* <p>Experience : {experience}</p> */}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default DeveloperCard;
