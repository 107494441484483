import React from 'react'
import { ImSpinner } from 'react-icons/im'

const Button = ({className ,type="button",onClick=()=>{}, loading= false, children, res}) => {
  return (
    <button type={type} className={`btn ${className}`} onClick={onClick} {...res}>
    {
        loading && <ImSpinner className='btn-loader'/> 
    } {children}
    </button>
  )
}

export default Button