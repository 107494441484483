import React, { useState } from "react";
import { toast } from "react-toastify";
import PageLayout from "../components/PageLayout";
import CustomHeader from "../components/useful/CustomHeader";
import TitleWithLocation from "../components/useful/TitleWithLocation";
import config from "../config";
import { api } from "../helpers/api";

function Contact() {
	const [formData, updateFormData] = useState({
		contact_form: true,
	});
	const [checked, setChecked] = useState(false);

	const [errorMsg, setErrorMsg] = useState();

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (checked) {
			// console.log(formData);

			// const response = await api.post('send_inquiry.php',{ data: formData});
			const response = await api({
				method: "post",
				url: "send_inquiry.php",
				data: formData,
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			if (jsonData.status === 200) {
				setErrorMsg();
				e.target.reset();
				toast.success("Your Inquiry Submitted Successfully");
				// window.location.reload();
			}
		} else {
			toast.error("Please agree Terms & Condition");
			setErrorMsg("Please agree Terms & Condition");
		}
	};
	return (
		<PageLayout title={"Contact Us | Zero Broker"}>
			<CustomHeader
				backgroundImage={"../assets/images/banner/contact.jpg"}
				title="CONTACT US"
			/>
			<div className="container py-5">
				<div className="row" id="contact-enquire">
					<div className="col-md-4 my-3">
						<div
							className="card shadow p-4 border-0"
							style={{ height: "100%" }}>
							<TitleWithLocation title="ADDRESS" />
							<p className="text-secondary  pb-3">
								Address: 6, Hari om, Apts, MIDC RD Hari Om IT
								park Mira Road – (E) Mumbai 401107
							</p>

							<TitleWithLocation title="PHONE NUMBER" />
							<p className="text-secondary pb-3">
								Office :{" "}
								<a
									href="tel:+917977351655"
									className="text-decoration-none text-secondary">
									+91 7977351655
								</a>{" "}
								<br />
							</p>

							<TitleWithLocation title="EMAIL ADDRESS" />
							<p className="text-secondary ">
								Sales :{" "}
								<a
									href="mailto:sales@zerobroker.in"
									className="text-decoration-none text-sec">
									sales@zerobroker.in
								</a>
								<br />
								Information :{" "}
								<a
									href="mailto:info@zerobroker.in"
									className="text-decoration-none text-se">
									info@zerobroker.in
								</a>
							</p>

							<div style={{ height: "100%" }}></div>
							<footer
								style={{
									marginTop: 30,
									width: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<a
									href="https://www.facebook.com/profile.php?id=100089858334241"
									className="iconFooterCanvas">
									<i className="fa-brands fa-facebook-f text-dark"></i>
								</a>
								<a
									href="https://www.instagram.com/zerobroker/"
									className="iconFooterCanvas">
									<i className="fa-brands fa-instagram text-dark"></i>
								</a>
								<a
									href="https://www.youtube.com/channel/UCoQYuBvCy8DIK_0HJUFx8Pg"
									className="iconFooterCanvas">
									<i className="fa-brands fa-youtube text-dark"></i>
								</a>
								<a
									href="https://www.linkedin.com/company/90894109/admin/"
									className="iconFooterCanvas">
									<i className="fa-brands fa-linkedin text-dark"></i>
								</a>
							</footer>
						</div>
					</div>
					<div className="col-md-8 my-3">
						<div className="card shadow p-3 border-0">
							<h3
								className="text-center mb-3"
								style={{ color: config.primaryColor }}>
								GET IN TOUCH !
							</h3>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12 mb-3">
										<div className="form-floating">
											<input
												type="text"
												className="form-control"
												id="fullname"
												name="fullname"
												placeholder="Full Name"
												onChange={handleChange}
											/>
											<label htmlFor="fullname">
												Full Name
											</label>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-6 mb-3">
										<div className="form-floating">
											<input
												type="email"
												className="form-control"
												id="email"
												name="email"
												placeholder="Email Address"
												onChange={handleChange}
											/>
											<label htmlFor="email">Email</label>
										</div>
									</div>

									<div className="col-md-6 mb-3">
										<div className="form-floating">
											<input
												type="text"
												className="form-control"
												id="phone"
												name="phone"
												placeholder="Phone"
												onChange={handleChange}
											/>
											<label htmlFor="phone">Phone</label>
										</div>
									</div>
								</div>

								<div className="form-floating">
									<textarea
										className="form-control"
										placeholder="Leave a message here"
										id="message"
										name="message"
										style={{ height: 100 }}
										onChange={handleChange}></textarea>
									<label htmlFor="message">Message</label>
								</div>

								<div className="form-check my-3">
									<input
										className="form-check-input"
										type="checkbox"
										defaultChecked=""
										required
										id="terms"
										onChange={() => setChecked(!checked)}
									/>
									<label
										className="form-check-label"
										htmlFor="terms">
										I agree to be contacted by ZERO BROKER
										for similar properties or related
										services via Whatsapp,{" "}
										<br className="custom_desktop" /> phone,
										SMS, e-mail etc.
									</label>
								</div>
								<button
									className="btn"
									type="submit"
									style={{
										background: config.primaryColor,
										color: "#fff",
										width: 100,
									}}>
									Send
								</button>
								<div
									className="w-100"
									style={{ height: "20px" }}>
									{errorMsg && (
										<p
											className="text-center mb-0 text-danger"
											style={{ fontSize: "12px" }}>
											{errorMsg}
										</p>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
}

export default Contact;
