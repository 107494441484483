import React from "react";
import PageLayout from "../components/PageLayout";

function PrivacyPolicy() {
  document.querySelector("body").style.background = "#f2f2f2";
  return (
    <PageLayout title={"Privacy Policy"} isTransperant={true}>
      <div className="bg-white">
        <div className="container pt100 pb-5">
          <div className="loan_div_main pt-lg-3">
            <h2 className="content_title pb-3">Privacy Policy</h2>
            <p>
              We are committed to protecting the privacy of your personal
              information. This privacy policy is designed to inform you how we
              make use of information gathered from you and others visiting the
              www.zerobroker.in website.
            </p>
            <p>
              Certain pages allow you to request and submit information, such as
              your name, e-mail address, mailing address, phone number, etc.
              (“Personal Information”). You are not under any obligation to
              disclose the Personal Information. We only collects the amount of
              Personal Information that is relevant for the specific product or
              service. We may use the Personal Information to acknowledge
              receipt of your communications and send you information and
              updates about www.zerobroker.in. We do NOT give away this
              information to any third party.
            </p>
            <p className="pb-3">
              We respect your privacy and are committed to protecting your
              personal information that you provide to us. This statement
              explains our policies and practices regarding the use and
              disclosure of your personal information by www.zerobroker.in.
              Please note that, we review and update this Privacy Policy from
              time to time as needed without notice. Therefore, you should
              review the terms of this policy periodically to make sure that you
              are aware of how www.zerobroker.in website collects and uses
              personal information. By using our web site, you consent to the
              collection and use of your personal information as explained
              below.
            </p>
            <h3 className="fw-bold">Personal Information.</h3>
            <p className="pb-3">
              You have complete control over your personal information. In
              general, you can visit our web site without providing us with any
              personal information. However, there are instances where we must
              have your personal information in order for us to grant you an
              access to our protected and secured sites. This information may
              include registration data (your name, address, email address,
              phone number, title, etc.), information request data and response
              data. When you receive your confirmation email or when you receive
              any email from the list server, you will be given instructions on
              how to remove yourself from the list.
            </p>
            <h3 className="fw-bold">Disclosure of User Information.</h3>
            <p className="pb-3">
              www.zerobroker.in does not sell, trade or transfer User
              Information to third parties. However, we may share User
              Information with our business partners for marketing, advertising
              or product/service offering purposes. For example, we provide User
              Information to our service providers for direct emailing of our
              newsletters, online surveys or notifications on www.zerobroker.in
              offerings to our viewers. We also disclose User Information if: we
              have your consent; we need to share it in order to provide you
              with the products and/or services you requested; we respond to a
              court order or you violate our Terms of Use. You may separately
              agree to provide your personal information to third parties that
              provide content for www.zerobroker.in Offerings, in order to
              access and/or use their products and/or services. If you agree to
              provide such information to these third parties, then your
              personal information will be subject to their privacy policies.
            </p>
            <h3 className="fw-bold">Accuracy and Security.</h3>
            <p className="pb-3">
              The accuracy and security of the User Information is important to
              us. If you contact us to correct your User Information, we will
              attempt to correct such inaccuracies in a timely manner. We are
              concerned with the security of your User Information and are
              committed to taking reasonable steps to protect it from
              unauthorized access and use of that personal information. To that
              end, we put in place the appropriate physical, electronic and
              managerial policies and procedures to secure your personal User
              Information. We also continue to implement procedures to maintain
              accurate, complete and current User Information.
            </p>
            <h3 className="fw-bold">Usernames and Passwords.</h3>
            <p className="pb-3">
              Access to certain content on our web site will require a username
              and/or password. In some cases, failure to provide personal
              information may prevent you from accessing certain sections on the
              www.zerobroker.in web site containing certain confidential
              information, products, services, or promotional offers. By
              accessing and using our protected and secured web site sections,
              you agree to maintain the confidentiality of the username and
              password you selected to access such sections and consent to our
              Terms of Use.
            </p>
            <h3 className="fw-bold">Cookies.</h3>
            <p className="pb-3">
              www.zerobroker.in website can use “cookies”. A cookie is a small
              data file that a web site can transfer to a visitor’s hard drive
              to keep records of the visits to such site. A cookie contains
              information such as your username and password that helps us
              recognize the pages you have visited and improve future visits,
              but the only personal information a cookie can contain is the
              information that you provide yourself. A cookie cannot read data
              off of your hard drive or read cookie files created by other
              sites. Information stored in cookies may be encrypted, however, we
              do not store your credit card number in cookies. If you prefer not
              to accept a cookie, you can set your web browser (Microsoft
              Internet Explorer or Netscape Navigator) to warn you before
              accepting cookies or you can refuse all cookies by turning them
              off in your web browser. However, access to our secured web site
              sections may require the use of per-session cookies in Microsoft
              Internet Explorer, which you must enable only cookies that get
              sent back to the originating server. Otherwise, you can still
              access most of the features on our web site even without accepting
              a cookie.
            </p>
            <h3 className="fw-bold">External Links.</h3>
            <p>
              www.zerobroker.in web site provides links to other third party web
              sites. Even if the third party is affiliated with us through a
              business partnership or otherwise, we are not responsible for the
              privacy policies or practices or the content of such external
              links. These links are provided to you for convenience purposes
              only and you access them at your own risk.
            </p>
            {/* <!--<a href="loan_type.php?type=loan_against_property" className="spread_link"></a>-->
            <!--</div>--> */}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default PrivacyPolicy;
