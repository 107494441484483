import React from "react";
import PageLayout from "../components/PageLayout";

function TermsAndConditon() {
  document.querySelector("body").style.background = "#f2f2f2";
  return (
    <PageLayout title={"Terms and Condition"} isTransperant={true}>
      <div className="bg-white">
        <div className="container pt100 pb-5">
          <div className="loan_div_main pt-lg-3">
            <h2 className="content_title">
              Terms and Conditions of www.zerobroker.in
            </h2>

            <ol>
              <br />
              <li>
                This document covers the terms and conditions of the site apply
                on all registered members/ unregistered users the under which
                these services are provided by Www.zerobroker.in{" "}
              </li>
              <li>
                These terms and conditions also apply on all registered members/
                unregistered users in respect of all new services or features
                introduced on Www.zerobroker.in in future as well.
              </li>
              <li>
                If you do not agree with any of the terms and conditions or with
                the privacy policy of Www.zerobroker.in , you are NOT granted
                rights to use Www.zerobroker.in services and must not register
                as a member of Www.zerobroker.in or use any of its services.
              </li>
              <li>
                www.zerobroker.in reserves the right to update the Policy of
                terms, conditions and notices of this agreement without any
                prior notice. Your continued use of Www.zerobroker.in confirms
                your acceptance of revised policy.
              </li>
              <li>
                This Agreement governs your use of the Www.zerobroker.in
                services, superseding any prior agreements between you and
                Www.zerobroker.in
              </li>
              <li>
                www.zerobroker.in .com, reserves its right to withdraw wholly or
                in partly, without assigning any reason, any of
                Www.zerobroker.in services or products with or without any
                notice.
              </li>
              <li>
                www.zerobroker.in is the sole authority to assign this
                agreement, and all rights and obligations hereunder, to a
                successor to all or any of its assets, whether by sale, merger
                or otherwise. These terms shall be binding upon and shall inure
                to the benefit of the parties hereto and their respective
                representatives, heirs, administrators, successors and permitted
                assigns except as otherwise provided herein.
              </li>
              <li>
                www.zerobroker.in .com doesn’t subscribe in any manner to the
                views/ expressions/details posted on the website by the users of
                the services and are not those of Www.zerobroker.in .
              </li>
              <li>
                The use of this site constitutes agreement between you and the
                www.zerobroker.in to hold Www.zerobroker.in and its sponsors,
                owners, shareholders or employees harmless against any and all
                claims related any errors or omissions or to the opinions
                expressed herein by the others.
              </li>
            </ol>
            <h3 className="fw-bold">Restrictions</h3>
            <p>You, the user of Www.zerobroker.in services, agree not to:</p>
            <ol>
              <li>
                Access data which is not meant for you or logon to an account or
                server you are not authorized to access.
              </li>
              <li>
                Attempt to breach security or authentication mechanisms of
                Www.zerobroker.in website and servers.
              </li>
              <li>
                Access Www.zerobroker.in website data in an automated manner by
                the use of bots, crawlers, spiders and other such software
                programs.
              </li>
              <li>
                Remove any copyright, trade mark, water marks or other
                intellectual property notices contained in any material copied,
                downloaded or printed off from Www.zerobroker.in website.
              </li>
              <li>
                Copy, distribute, download, display, reproduce, modify, edit,
                alter, enhance, broadcast or tamper with in any way or otherwise
                use any data or material contained in Www.zerobroker.in website
                except as permitted.
              </li>
              <li>
                Resell any data obtained from Www.zerobroker.in to third
                parties.
              </li>
              <li>
                Use Www.zerobroker.in services to send unsolicited promotional
                messages.
              </li>
              <li>
                Utilise www.zerobroker.in website in any manner so as to impair
                its performance or functioning.
              </li>
            </ol>
            <p>
              In addition to the above, the user/subscriber is prohibited from
              uploading, posting, or sharing on www.zerobroker.in website any
              information that:
            </p>
            <ol>
              <li>
                The user does not have copyrights to use, reproduce, or
                distribute.
              </li>
              <li>
                Infringes any patent, trademark, copyright or other proprietary
                rights.
              </li>
              <li>Is misleading, inaccurate, or fraudulent in nature.</li>
              <li>
                Is hateful, harassing, defamatory, obscene, pornographic, or
                racially, ethnically objectionable, or discriminatory in terms
                of religion, caste, gender, race, etc.
              </li>
              <li>
                Deceives or misleads about the origin of such messages or
                communication, or impersonates any person or organization.
              </li>
              <li>Is unlawful in any manner.</li>
            </ol>
            <h3 className="fw-bold">Communication from Www.zerobroker.in</h3>
            <p className="pb-3">
              By becoming a member of Www.zerobroker.in .com and/or using
              Www.zerobroker.in .com services, you agree to receive certain
              communication from Www.zerobroker.in .com. You hereby confirm
              that, you do not have any objection to receiving emails, messages
              and calls from Www.zerobroker.in .com and
              partners/clients/affiliates/members of Www.zerobroker.in .com.
              This consent shall supersede any preferences set by you with or
              registration done with the Do Not Disturb (DND Register)/ National
              Customer Preference Register (NCPR).
            </p>
            <h3 className="fw-bold">Jurisdiction</h3>
            <p className="pb-3">
              All matters relating to Www.zerobroker.in and these Terms and
              Conditions shall be governed by and construed in accordance with
              the laws of India and in any dispute arising out of or relating to
              this agreement, the Parties submit to the exclusive jurisdiction
              of the Courts situated at Mumbai{" "}
            </p>
            <h3 className="fw-bold">MAHA RERA Compliance</h3>
            <p>
              www.zerobroker.in .com is only an advertising platform to help
              connect buyers and sellers and is not a party to any transaction,
              nor shall be responsible or liable to resolve any disputes between
              them. Any information provided on this website, including facts
              and figures, should be verified independently before entering into
              any transaction. In case of real estate projects, you may verify
              the completeness and accuracy of information from MAHA RERA
              website of respective state where the Project is located; and
              Www.zerobroker.in being an advertising platform has not validated
              the compliance of these Projects under MAHA RERA.
            </p>
            <p className="pb-3">
              www.zerobroker.in is neither functioning as a Real Estate Agent
              nor has any means to ascertain any actual real estate
              sale/purchase transactions made by the users of this Website or to
              identify the parties to it and therefore we disclaim all liability
              and responsibility under RERA.
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default TermsAndConditon;
