import React, { useEffect, useState } from "react";
import PageLayout from "../components/PageLayout";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import  "../components/blog/HotTopics";
import PostCard, { LoadPostCard } from "../components/PostCard/PostCard";
import { api } from "./../helpers/api";
import LoadMoreButton from "../components/useful/LoadMoreButton";

function Blog() {
  const [blogsData, setBlogsData] = useState([]);
  const [latestBlogsData, setLatestBlogsData] = useState([]);
  const [showMore, setShowMore] = useState(5);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    const loadBlogs = async () => {
      const response = await api({
        method: "post",
        url: "fetch_blog.php",
        data: { fetch_all: true },
        headers: { "Content-Type": "multipart/form-data" },
      });
      const jsonData = await response.data;
      if (jsonData.status === 200) {
        setBlogsData(jsonData.data);
        setLoading(true);
      } else {
        setLoading(false);
      }
    };
    const latestBlogs = async () => {
      const response = await api({
        method: "post",
        url: "fetch_blog.php",
        data: { latest_all: true },
        headers: { "Content-Type": "multipart/form-data" },
      });
      const jsonData = await response.data;
      if (jsonData.status === 200) {
        setLatestBlogsData(jsonData.data);
        setLoading(true);
      } else {
        setLoading(false);
      }
    };
    Promise.all([loadBlogs(), latestBlogs()]);
    setShowMore(5);
  }, []);
  console.log(showMore);

  return (
    <PageLayout title={"Blog Zero Broker"} isTransperant={true}>
      <div className="container contentArea pb-5">
        <h3 className="text-center fw-bold">Latest Blog</h3>
        {latestBlogsData.length > 0 ? (
          <OwlCarousel
            className="owl-stage-outer"
            items={4}
            navClass={["nav-prev", "nav-next"]}
            autoplay
            nav
            style={{
              marginTop: 20,
            }}
            responsive={{
              0: {
                items: 1,
              },
              700: {
                items: 2,
              },
              1000: {
                items: 3,
              },
            }}
          >
            {latestBlogsData.map((blog, id) => (
              <div className="item p-2" key={id}>
                <PostCard item={blog} />
              </div>
            ))}
          </OwlCarousel>
        ) : (
          <div className="row mt-2">
            <div className="col-md-4 col-sm-6 col-12 mb-5">
              <LoadPostCard />
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-5">
              <LoadPostCard />
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-5">
              <LoadPostCard />
            </div>
          </div>
        )}
        <h3 className="text-center fw-bold">Blog</h3>
        <div className="row">
          {Loading ? (
            blogsData.length > 0 ? (
              blogsData.map(
                (blog, index) =>
                  index <= showMore && (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5"
                      key={index}
                    >
                      <PostCard item={blog} />
                    </div>
                  )
              )
            ) : (
              <>
                <div className="col-md-4 col-sm-6 col-12 mb-5">
                  <LoadPostCard />
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-5">
                  <LoadPostCard />
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-5">
                  <LoadPostCard />
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-5">
                  <LoadPostCard />
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-5">
                  <LoadPostCard />
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-5">
                  <LoadPostCard />
                </div>
              </>
            )
          ) : (
            <div className="projectNoticeCard red w-100 text-center">
              Blog Not Found
            </div>
          )}
          {Loading && showMore <= blogsData.length && (
            <LoadMoreButton onClick={() => setShowMore((prev) => prev + 3)} />
          )}
        </div>
      </div>
    </PageLayout>
  );
}

export default Blog;
