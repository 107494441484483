import React, { useLayoutEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import QuickAccess from "./components/quickaccess/QuickAccess";
import About from "./views/About";
import AllDevelopers from "./views/AllDevelopers";
import Home from "./views/Home";
import Blog from "./views/Blog";
import BlogDetail from "./views/BlogDetail";
import Contact from "./views/Contact";
import DeveloperDetails from "./views/DeveloperDetails";
import Loan from "./views/Loan";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Property from "./views/Property";
import Residential from "./views/Residential";
import Search from "./views/Search";
import WhyMumbai from "./views/WhyMumbai";
import WorkWithUs from "./views/WorkWithUs";
import HomeLoan from "./views/HomeLoan";
import HomeLoanBTC from "./views/HomeLoanBTC";
import LoanAgainst from "./views/LoanAgainst";
import TermsAndConditon from "./views/TermsAndConditon";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function AppRouter() {
  const ScrollToTop = ({ children }) => {
    const location = useLocation();

    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return children;
  };
  return (
    <>
      <BrowserRouter>
      <ToastContainer />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/residential-projects"
              element={
                <Residential title="Residential" propertyType="Residential" />
              }
            />
            <Route
              path="/commercial-projects"
              element={
                <Residential title="Commercial" propertyType="Commercial" />
              }
            />
            <Route
              path="/independent-bungalows-villas"
              element={
                <Residential
                  title="Independent Bungalow & Villas"
                  propertyType="Independent Bunglow/Villas"
                />
              }
            />
            <Route path="/blog" element={<Blog />} />
            <Route path="/property/:slug" element={<Property />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privcy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditon />}
            />
            <Route path="/blog/:slug" element={<BlogDetail />} />
            <Route path="/why-mumbai" element={<WhyMumbai />} />
            <Route path="/work-with-us" element={<WorkWithUs />} />
            <Route path="/home-loan" element={<Loan />} />
            <Route path="/home-loan-details" element={<HomeLoan />} />
            <Route path="/loan-against-property" element={<LoanAgainst />} />
            <Route
              path="/home-loan-balance-transfer"
              element={<HomeLoanBTC />}
            />
            <Route path="/developers" element={<AllDevelopers />} />
            <Route path="/developer/:slug" element={<DeveloperDetails />} />
            <Route path="/search" element={<Search />} />
          </Routes>
          <QuickAccess />
        </ScrollToTop>
      </BrowserRouter>
      {/* <QuickAccess /> */}
    </>
  );
}

export default AppRouter;
