import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ProjectCard, {
  LoaderPropertyCard,
} from "../components/home/parts/ProjectCard";
import PageLayout from "../components/PageLayout";
import TopBanner from "../components/TopBanner/TopBanner";
import LoadMoreButton from "../components/useful/LoadMoreButton";
import { api } from "../helpers/api";

function Residential({ title, propertyType }) {
  const Location = useLocation();
  const [bannerData, setBannerData] = useState([]);
  const [topProjectData, setTopProjectData] = useState([]);
  const [showMore, setShowMore] = useState(15);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    setTopProjectData([]);
    setLoading(true)
    const loadSlider = async () => {
      const response = await api({
        method: "post",
        url: "fetch_slider.php",
        data: { SliderType: title },
        headers: { "Content-Type": "multipart/form-data" },
      });
      const jsonData = await response.data;
      if (response.status === 200) {
        setBannerData(jsonData.data);
      }
    };
    const loadToprojects = async () => {
      const response = await api({
        method: "post",
        url: "fetch_property.php",
        data: { proj_type: propertyType },
        headers: { "Content-Type": "multipart/form-data" },
      });
      const jsonData = await response.data;
      if (jsonData.status === 200) {
        setTopProjectData(jsonData.data);
        setLoading(true);
      } else {
        setLoading(false);
      }
    };
    Promise.all([loadSlider(), loadToprojects()]);
    setShowMore(15);
  }, [Location, propertyType, title]);

  return (
    <PageLayout title={title}>
      <TopBanner title={title} backgroundImages={bannerData} />
      <div className="d-flex justify-content-center align-items-center">
      <h3 className="text-center my-3 text-uppercase fw-bold">New Projects</h3>
      </div>
      <div className="container-fluid px-md-5 pb-5">
        <div className="row">
          {Loading ? (
            topProjectData.length > 0 ? (
              topProjectData.map(
                (item, index) =>
                  index <= showMore && (
                    <div className="col-lg-3 col-md-6" key={index}>
                      <ProjectCard item={item} />
                    </div>
                  )
              )
            ) : (
              <>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
              </>
            )
          ) : (
            <div className="projectNoticeCard red w-100 text-center">
              Property Not Found
            </div>
          )}
          {Loading && showMore <= topProjectData.length && (
            <LoadMoreButton onClick={() => setShowMore((prev) => prev + 8)} />
          )}
        </div>
      </div>
    </PageLayout>
  );
}

export default Residential;
