import React from 'react';

import CustomHeader from '../components/useful/CustomHeader';
import hexToRGB from '../helpers/helpers';
import PageLayout from '../components/PageLayout'


function WhyMumbai() {
  return (
    <PageLayout title={"Why Mumbai | Zero Broker"}>
      <CustomHeader
        backgroundImage={"./assets/images/banner/mumbai.jpg"}
        title={"WHY MUMBAI"}
      />


      <div className="container" style={{
        marginTop: 60,
        marginBottom: 60
      }}>
        <div className="row">
          <div className="col-md-4">
            <img src={require("../assets/images/temp.jpg")} alt="Why Mumbai" style={{ borderRadius: 20, width: "100%" }} />
          </div>
          <div className="col-md-8">
            <div className="card p-4" style={{
              border: "none",
              background: hexToRGB("#8486a6", 0.1),
            }}>
              <h3 className='text-center fw-bold'>WHY MUMBAI ?</h3>
              <br />
              <p className='text-secondary' style={{ fontSize: 20 }}>Mumbai is among the major metro cities in India. It is the capital of Maharashtra. It is one of many densely populated cities in India. It is just a coastal city. It located on the western coast of India. It is the centre of film production. It’s also known as the commercial capital of India. Mumbai situated in the moderate climate zone. The climate here is pleasant throughout the entire year. It is neither too cold nor too hot. The facilities are well supplied with water, electricity and other basic amenities of life. Electricity supply is excellent in Mumbai. Mumbai has been many tourist destinations. You can also visit Church Gate, Victoria Terminus, Juhu Beach, and many others. Beautiful buildings increase the beauty of the city. You will find great shopping malls and shopping complexes in Mumbai. You did plenty of shopping there. Shopping itself is just a great enjoyment.</p>
              <p className='text-end' style={{ fontSize: 20 }}>— Love Mumbai, <i>Zerobroker</i> </p>
            </div>
          </div>

        </div>

      </div>

    </PageLayout>
  )
}

export default WhyMumbai