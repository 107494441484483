import React, { useEffect, useState } from "react";
import { Calculator, Whatsapp } from "react-bootstrap-icons";
import { ImMobile } from "react-icons/im";
import { HashLink } from "react-router-hash-link";
import AppAds from "../components/AppAds/AppAds";
import Disclaimer from "../components/Disclaimer/Disclaimer";
import TopDevelopers from "../components/home/TopDevelopers";
import TopProjects from "../components/home/TopProjects";
import PageLayout from "../components/PageLayout";
import TopBanner from "../components/TopBanner/TopBanner";
import useMediaQuery from "../components/useMediaQuery";
import { api } from "../helpers/api";
import { useDownloadApp } from "./../helpers/useDownloadApp";

export default function App() {
	const [bannerData, setBannerData] = useState([]);
	const [Loading, setLoading] = useState(true);
	const [topProjectData, setTopProjectData] = useState([]);
	const [developers, setDevelopers] = useState([]);
	const isMobile = useMediaQuery("(max-width: 768px)");
	const downloadApp = useDownloadApp();

	useEffect(() => {
		const loadSlider = async () => {
			const response = await api({
				method: "post",
				url: "fetch_slider.php",
				data: { SliderType: "Home" },
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			setBannerData(jsonData.data);
		};

		const loadToprojects = async () => {
			const response = await api({
				method: "post",
				url: "fetch_property.php",
				data: { isfeatured: true },
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			if (jsonData.status === 200) {
				setTopProjectData(jsonData.data);
				setLoading(true);
			} else setLoading(false);
		};
		const loadDeveloper = async () => {
			const response = await api.post("fetch_developers.php");
			const jsonData = await response.data;
			setDevelopers(jsonData.data);
		};
		Promise.all([loadSlider(), loadToprojects(), loadDeveloper()]);
	}, []);

	var ResidentialProperty = null;
	var CommericialProperty = null;
	var VillasProperty = null;

	if (topProjectData) {
		ResidentialProperty = topProjectData.filter((prop) => {
			return prop["proj_type"] === "Residential";
		});
		CommericialProperty = topProjectData.filter((prop) => {
			return prop["proj_type"] === "Commercial";
		});
		VillasProperty = topProjectData.filter((prop) => {
			return prop["proj_type"] === "Independent Bunglow/Villas";
		});
	}

	return (
		<PageLayout>
			<TopBanner backgroundImages={bannerData} />
			<TopProjects
				title="Top Projects"
				data={topProjectData}
				loadingStatus={Loading}
			/>
			{ResidentialProperty.length > 4 && (
				<TopProjects
					title="Residential Projects"
					data={ResidentialProperty}
					loadingStatus={Loading}
				/>
			)}
			{CommericialProperty.length > 4 && (
				<TopProjects
					title="Commercial Projects"
					data={CommericialProperty}
					loadingStatus={Loading}
				/>
			)}
			{VillasProperty.length > 4 && (
				<TopProjects
					title="Independent Bungalow & Villas"
					data={VillasProperty}
					loadingStatus={Loading}
				/>
			)}
			{developers.length > 0 && <TopDevelopers data={developers} />}
			<AppAds />
			<Disclaimer />

			{!isMobile && (
				<div className="side_bar_widgets stickySideBar homePage_sticky sticky-right right-0 custom_desktop">
					<a
						href={`https://wa.me/919930704010/`}
						target="_blank"
						rel="noreferrer"
						className="stickySideBar__Nav"
					>
						<div className="icon">
							<Whatsapp />
						</div>
						<p>Whatsapp</p>
					</a>
					<HashLink
						aria-current="page"
						to="/home-loan#calc-emi-section-main"
						className="stickySideBar__Nav"
					>
						<div className="icon">
							<Calculator />
						</div>
						<p>EMI Calculator</p>
					</HashLink>
					<button
						type="button"
						onClick={downloadApp}
						className="stickySideBar__Nav"
					>
						<div className="icon">
							<ImMobile />
						</div>
						<p>Download App</p>
					</button>
				</div>
			)}
		</PageLayout>
	);
}
