import React, { useEffect, useState } from "react";
import {
	Calculator,
	ChatSquareText,
	CloudDownload,
	Whatsapp,
	X,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import hexToRGB from "../../helpers/helpers";
import "./quickaccess.css";

function ActionPopUp({ onClose }) {
	const items = [
		{
			title: "EMI Calculator",
			color: "#cc33ff",
			icon: <Calculator size={25} color="#cc33ff" />,
			link: "/home-loan#calc-emi-section-main",
		},
		{
			title: "Chat Support",
			icon: <Whatsapp size={25} color="#009933" />,
			color: "#009933",
			link: "https://wa.me/919930704010",
		},
		{
			title: "Enquire Now",
			icon: <ChatSquareText size={25} color="#66ccff" />,
			color: "#66ccff",
			link: "/contact",
		},
	];
	return (
		<>
			<div className="popupOverlay" onClick={onClose} />
			<div className="popup">
				<div
					className="d-flex justify-content-between mb-2"
					style={{ alignItems: "center" }}>
					<h4 className="fw-bold">Quick Access</h4>
					<X size={40} onClick={onClose} cursor="pointer" />
				</div>
				<div>
					{items.map((item, index) =>
						item.link === "/home-loan#calc-emi-section-main" ? (
							<HashLink
								to={item.link}
								onClick={onClose}
								key={index}
								className="d-flex text-decoration-none text-dark"
								style={{ alignItems: "center" }}>
								<div
									className="iconContainer"
									style={{
										backgroundColor: hexToRGB(
											item.color,
											0.3
										),
									}}>
									{item.icon}
								</div>
								<p className="mt-md-0 mt-2 fw-bold">
									{item.title}
								</p>
							</HashLink>
						) : (
							<a
								href={item.link}
								target={"_blank"}
								key={index}
								className="d-flex text-decoration-none text-dark"
								style={{ alignItems: "center" }}
								rel="noreferrer">
								<div
									className="iconContainer"
									style={{
										backgroundColor: hexToRGB(
											item.color,
											0.3
										),
									}}>
									{item.icon}
								</div>
								<p className="mt-md-0 mt-2 fw-bold">
									{item.title}
								</p>
							</a>
						)
					)}
				</div>
			</div>
		</>
	);
}

export default ActionPopUp;
