import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyLoad = ({ src, alt, lazyClass, ...rest }) => {
  return (
    <LazyLoadImage
      src={src}
      alt={alt}
      {...rest}
      className={lazyClass??''}
      placeholderSrc={"/assets/images/placeholder.jpg"}
      // effect="blur"
      onError={(e) => (e.target.src = "/assets/images/placeholder.jpg")}
    />
  );
};

export default LazyLoad;
