import React from 'react'
import CustomNavbar from '../components/useful/CustomNavbar'
import config from '../config'

function WorkWithUs() {
    return (
        <div className='workwithus' style={{background :"linear-gradient(#000000, #000000)"}}>
            <div className='workwithusOverlay' />
            <CustomNavbar />

            <div className='workwithusForm'>
                <form action="">
                    <h3 className='mb-4 fw-bold'>Work With Us</h3>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control form-control-wwu" id="name" placeholder="Name" />
                        <label htmlFor="name">Name</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control form-control-wwu" id="phone" placeholder="Mobile Number" />
                        <label htmlFor="phone">Mobile Number</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="email" className="form-control form-control-wwu" id="email" placeholder="name@example.com" />
                        <label htmlFor="email">Email address</label>
                    </div>


                    <div className="form-floating mb-3">
                        <input type="text" className="form-control form-control-wwu" id="location" placeholder="Location" />
                        <label htmlFor="location">Location</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control form-control-wwu" id="rera" placeholder="RERA Number" />
                        <label htmlFor="rera">RERA Number</label>
                        <span>(Note: RERA No. is Compulsory to Register as Partner)</span>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Broker
                            </label>
                    </div>
                    <div className="form-check mb-3">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Developer
                            </label>
                    </div>

                    <button className='btn text-white ' style={{background:config.primaryColor}}>Submit</button>

                </form>
            </div>
        </div>
    )
}

export default WorkWithUs