import React from "react";
import { Link } from "react-router-dom";
import "./parts.css";
import LazyLoad from "./../../LazyLoad";

function MiniPostCard({ item = [] }) {
  return (
    <div className="p-2">
      <Link
        to={`/blog/BLOGID00${item && item.id}`}
        state={item}
        className="text-decoration-none text-dark quickPostCard"
      >
        <div className="card d-flex flex-row" style={{ border: "none" }}>
          <img
            src={`${item.image && item.image}`}
            style={{ objectFit: "contain" , objectPosition:"center"}}
            className="miniPostImg border"
            alt="post"
          />

          <div className="flex-fill card-body postCardBody pt-0">
            <h5 className="card-title fw-bold">{item && item.title}</h5>
            <p
              className="card-text desc"
              dangerouslySetInnerHTML={{ __html: item && item.content }}
            ></p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default MiniPostCard;
