import React, { useState } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import LoadMoreButton from "../useful/LoadMoreButton";
import DeveloperCard from "./parts/DeveloperCard";

function TopDevelopers({ isHomePage = true, data = [] }) {
  const [loadMore, setLoadMore] = useState(15);

  const handleMore = () => {
    setLoadMore((prev) => prev + 4);
  };
  return (
    <div className="container my-3 mb-4">
      {isHomePage && (
        <h3 className="text-center my-3 text-uppercase fw-bold">
          Top Developers in Mumbai
        </h3>
      )}
      <div className="row">
        {data.length > 0
          ? data.map((item, index) =>
           index <= loadMore && (
              <DeveloperCard
                key={index}
                devLink={item.id}
                data={item}
                title={item.name}
                image={item.image}
                experience={item.experience}
              />
            ))
          : null}
      </div>
      {/* {data.length > 16 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
          }}
        ></div>
      )} */}

      {isHomePage ? (
        <Link
          to="/developers"
          className="btn px-3 text-white d-table mx-auto"
          style={{
            background: config.primaryColor,
            alignSelf: "center",
            borderRadius: 100,
          }}
        >
          View All Developers
        </Link>
      ) : (
        data.length > loadMore && <LoadMoreButton onClick={handleMore} />
      )}
    </div>
  );
}

export default TopDevelopers;
