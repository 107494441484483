import React from "react";
import config from "../../config";
import hexToRGB from "../../helpers/helpers";
import "./Footer.css";

function Footer({
  showApplication = false,
  isfooter = "true",
  isProjectDetails = false,
}) {
  return (
    <>
      <div
        className="footerBottom"
        style={{
          width: "100%",
          background: hexToRGB(config.primaryColor, 0.1),
        }}
      >
        {showApplication ? (
          <div
            className="container d-md-flex d-block justify-content-between"
            style={{
              alignItems: "center",
            }}
          >
            <div className="d-flex flex-column align-items-center mb-5 mx-3 phonePromotionArea">
              <h1 className="fw-bold text-center">Real-Estate in you pocket</h1>
              <p className="text-center">
                With our web app, spend less time searching and more time at
                your dream home. Download now!
              </p>
              <a
                href="#!"
                className="btn text-white px-4"
                style={{
                  fontSize: 20,
                  background: config.primaryColor,
                  borderRadius: 100,
                }}
              >
                Download Now
              </a>
            </div>
            <div className="d-md-block d-none">
              <img
                src={require("../../assets/images/appstore.png")}
                alt="App Preview"
                height={400}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <footer
          className={isProjectDetails ? "mobile-project-footer" : ""}
          style={{
            height: 100,
            width: "100%",
            backgroundColor: config.secondaryColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href="https://www.facebook.com/profile.php?id=100089858334241"
            className="iconFooter"
          >
            <i className="fa-brands fa-facebook-f"></i>
          </a>
          <a
            href="https://www.instagram.com/zerobroker/"
            className="iconFooter"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UCoQYuBvCy8DIK_0HJUFx8Pg"
            className="iconFooter"
          >
            <i className="fa-brands fa-youtube"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/90894109/admin/"
            className="iconFooter"
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>
        </footer>
      </div>
    </>
  );
}

export default Footer;
