import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";

const FloatingEmiCalc = ({ showCalc, href }) => {
	const [showPopup, setShowPopup] = useState(true);

	return (
		<>
			{showPopup && (
				<span className="calculate-emi-sticky">
					<span>Calculate EMI</span>
					<span
						className="calc-close-icon"
						onClick={() => setShowPopup(false)}>
						<FaTimes fontSize={12} />
					</span>
					<a
						className="btn btn-primary emi-calc-btn"
						onClick={() => {
							if (showCalc) {
								showCalc(true);
							}
						}}
						href={href ?? "#!"}>
						Click Here
					</a>
				</span>
			)}
		</>
	);
};

export default FloatingEmiCalc;
