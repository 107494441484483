import React from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ProjectCardSmall from "../property/parts/ProjectCardSmall";

function TopBanks() {
  const banks = [
    {
      image: "assets/images/banks/SBI.svg",
      title: "ROI 7.55%",
      offer: "P.F ₹10,000",
    },

    {
      image: "assets/images/banks/BOB.svg",
      title: "ROI 7.55%",
      offer: "P.F ₹10,000",
    },
    {
      image: "assets/images/banks/BOI.svg",
      title: "ROI 7.55%",
      offer: "P.F ₹10,000",
    },
    {
      image: "assets/images/banks/HDFC.svg",
      title: "ROI 7.55%",
      offer: "P.F ₹10,000",
    },
    {
      image: "assets/images/banks/Axis.svg",
      title: "ROI 7.55%",
      offer: "P.F ₹10,000",
    },
    {
      image: "assets/images/banks/CBI.svg",
      title: "ROI 7.55%",
      offer: "P.F ₹10,000",
    },
    {
      image: "assets/images/banks/CB.svg",
      title: "ROI 7.55%",
      offer: "P.F ₹10,000",
    },
    {
      image: "assets/images/banks/UBI.svg",
      title: "ROI 7.55%",
      offer: "P.F ₹10,000",
    },
    {
      image: "assets/images/banks/ICICI.svg",
      title: "ROI 7.55%",
      offer: "P.F ₹10,000",
    },
  ];
  return (
    <>
      <OwlCarousel
        className="w-100 owl-stage-outer px-lg-4 px-2"
        items={4}
        margin={5}
        nav={false}
        dots={false}
        autoplay
        loop
        responsive={{
          0: {
            items: 2,
          },
          700: {
            items: 3,
          },
          1000: {
            items: 6,
          },
          1400: {
            items: 8,
          },
        }}
      >
        {banks.map((item, index) => (
          <div className="">
            <div className="card w-100 p-2">
              <div
                className="d-flex"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="pos-r w-75 mx-auto overflow-hidden"
                  style={{ paddingTop: "calc(10000%/280)" }}
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className="pos-a t-0 l-0 img-fluid"
                  />
                </div>
                <p className="mt-2 mb-0 text-dark">{item.title}</p>
                <p className="text-center text-dark mb-0">offer 10%</p>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </>
  );
}

export default TopBanks;
