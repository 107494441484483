import React from 'react'
import "./parts.css";
function NearbyLocation({
    title,
    distance
}) {
  return (
    <div className='nearby-location'>
        <p>{title}</p>
        {/* <div className="divider"></div> */}
        <hr />
        <p>{distance}</p>
    </div>
  )
}

export default NearbyLocation