import React from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const PageLayout = ({
	children,
	title,
	isTransperant,
	showApplication,
	isfooter,
	isProjectDetails,
}) => {
	document.title = title ? title : "Zero Broker";
	return (
		<>
			<Header headerTransparent={isTransperant} />
			<main>{children}</main>
			<Footer
				isfooter={isfooter}
				showApplication={showApplication}
				isProjectDetails={isProjectDetails}
			/>
		</>
	);
};

export default PageLayout;
