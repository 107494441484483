import React, { useEffect, useState } from "react";
import AppAds from "../components/AppAds/AppAds";
import TopDevelopers from "../components/home/TopDevelopers";
import PageLayout from "../components/PageLayout";
import TopBanner from "../components/TopBanner/TopBanner";
import { api } from "../helpers/api";

function AllDevelopers() {
  const [developers, setDevelopers] = useState([]);

  useEffect(() => {
    const loadDeveloper = async () => {
      const response = await api.post("fetch_developers.php");
      const jsonData = await response.data;
      setDevelopers(jsonData.data);
    };
    loadDeveloper();
  }, []);
  return (
    <PageLayout title={"Top Developers"}>
      <TopBanner
       backgroundImages={[
          "https://static-cse.canva.com/blob/571910/PerfectWebBannerAdfeaturedimage1.jpg",
        ]} title={"Top Developers"} />
      <div className="container pb-5">
        <TopDevelopers isHomePage={false} data={developers} />
      </div>
      <AppAds/>
    </PageLayout>
  );
}

export default AllDevelopers;
