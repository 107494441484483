import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppAds from "../components/AppAds/AppAds";
import ProjectCard, {
  LoaderPropertyCard,
} from "../components/home/parts/ProjectCard";
import PageLayout from "../components/PageLayout";

import CustomHeader from "../components/useful/CustomHeader";
import LoadMoreButton from "../components/useful/LoadMoreButton";
import { api } from "../helpers/api";

function DeveloperDetails(props) {
  const [PropertyData, setPropertyData] = useState([]);
  const [showMore, setShowMore] = useState(16);
  const [Loading, setLoading] = useState(true);

  const location = useLocation();
  const state = location.state;
  const developer_id = state.id;
  const developerName = state.name;

  useEffect(() => {
    const loadproperty = async () => {
      try {
        const response = await api({
          method: "post",
          url: "fetch_property.php",
          data: { developer_id: developer_id },
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await response.data;

        if (jsonData.status === 200) {
          setPropertyData(jsonData.data);
          setLoading(true);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.log(error.response); 
        if (error.response.status === 400) {
            setPropertyData([]);
            setLoading(false);
        }
        return error.response;
      }
    };
    loadproperty();
  }, [developer_id]);
  
  return (
    <PageLayout title={"Developers"}>
      <CustomHeader
        title={developerName ? developerName : "Zerobroker"}
        backgroundImage={require("../assets/images/about.jpg")}
        developer={true}
      />
      <div className="d-flex justify-content-center align-items-center">
        <h2 className="fw-bold my-3">PROJECTS</h2>
      </div>
      <div className="container-fluid px-md-5 pb-5">
        <div className="row">
          {Loading ? (
            PropertyData.length > 0 ? (
              PropertyData.map(
                (item, index) =>
                  index <= showMore && (
                    <div className="col-lg-3 col-md-6">
                      <ProjectCard key={index} item={item} />
                    </div>
                  )
              )
            ) : (
              <>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
              </>
            )
          ) : (
            <div className="projectNoticeCard red w-100 text-center">
              Property Not Found
            </div>
          )}
          {Loading && showMore <= PropertyData.length && (
            <LoadMoreButton onClick={() => setShowMore((prev) => prev + 8)} />
          )}
        </div>
      </div>
      <AppAds/>
    </PageLayout>
  );
}

export default DeveloperDetails;
