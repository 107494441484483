import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectCard, {
  LoaderPropertyCard,
} from "../components/home/parts/ProjectCard";
import PageLayout from "../components/PageLayout";
import TopBanner from "../components/TopBanner/TopBanner";
import LoadMoreButton from "../components/useful/LoadMoreButton";
import { api } from "../helpers/api";

function Search() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [propertyData, setPropertyData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(15);

  const searchInput = state?.searchInput ?? "";
  const min = state?.min ?? "";
  const max = state?.max ?? "";
  const type = state?.type ?? "";

  useEffect(() => {
    setPropertyData([]);
    setLoading(true);
    if (
      searchInput === undefined ||
      searchInput === null ||
      searchInput === ""
    ) {
      navigate("/");
      return;
    }
    const loadToprojects = async () => {
      try {
        const response = await api({
          method: "post",
          url: "fetch_property.php",
          data: {
            prop_search: true,
            searchInput: searchInput,
            min: min,
            max: max,
            type: type,
          },
          headers: { "Content-Type": "multipart/form-data" },
        });

        const jsonData = await response.data;
        console.log("Error:::", await response);
        if (response.status === 200) {
          setPropertyData(jsonData.data);
          setLoading(true);
        } else {
          setLoading(false);
        }
      } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response; // take everything but 'request'
        console.log(errorObject);
        if(errorObject.status===400 || errorObject.status===500){
          setLoading(false)
        }
      }
    };
    loadToprojects();
    setShowMore(15);
  }, [max, min, navigate, searchInput, state, type]);

  return (
    <PageLayout title={"Search"}>
      <TopBanner
        title={"Search"}
        searchInput={searchInput ? searchInput : ""}
        min={min ? min : ""}
        max={max ? max : ""}
        backgroundImages={[
          "https://static-cse.canva.com/blob/571910/PerfectWebBannerAdfeaturedimage1.jpg",
        ]}
        type={type ? type : ""}
      />

      <div className="d-flex justify-content-center align-items-center">
        <h3 className="mt-4">
          Search Result For :{" "}
          <strong>
            {Loading
              ? propertyData.length > 0
                ? "Property Found"
                : "Searching...."
              : "Not Found Property"}
          </strong>
        </h3>
      </div>
      <div className="container-fluid px-md-5 pb-5">
        <div className="row">
          {Loading ? (
            propertyData.length > 0 ? (
              propertyData.map(
                (item, index) =>
                  index <= showMore && (
                    <div className="col-lg-3 col-md-6">
                      <ProjectCard key={index} item={item} />
                    </div>
                  )
              )
            ) : (
              <>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
                <div className="col-lg-3 col-md-6">
                  <LoaderPropertyCard />
                </div>
              </>
            )
          ) : (
            <div className="projectNoticeCard red w-100 text-center">
              Property Not Found
            </div>
          )}
          {Loading && showMore <= propertyData.length && (
            <LoadMoreButton onClick={() => setShowMore((prev) => prev + 8)} />
          )}
        </div>
      </div>
    </PageLayout>
  );
}

export default Search;
