import React, { useEffect, useRef, useState } from "react";
import useMediaQuery from "./useMediaQuery";

const ReadMore = ({
	children,
	openName = "read more",
	closeName = "read less",
	limit = 65,
	...rest
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const length = children ? children.split(" ").length : children;
	const readMoreRef = useRef(null);
	const isNotMobile = useMediaQuery("(min-width: 768px)");

	function trim_words(theString, numWords) {
		const expString = theString.split(/\s+/, numWords);
		const theNewString = expString.join(" ");
		return theNewString;
	}

	useEffect(() => {
		if (readMoreRef.current && isNotMobile) {
			window.scrollTo({
				top:
					readMoreRef.current.getBoundingClientRect().top +
					window.scrollY -
					150,
				behavior: "smooth",
			});
		}
	}, [isNotMobile, isOpen]);

	return (
		<div
			ref={readMoreRef}
			className={!isOpen ? "max-line-limit" : ""}
			{...rest}>
			{length > limit
				? isOpen
					? children
					: `${trim_words(children, limit)}...`
				: children}

			{length > limit && (
				<a
					href="#!"
					className="fw-bold"
					onClick={(e) => {
						e.preventDefault();
						setIsOpen((prevState) => !prevState);
					}}>
					{isOpen ? closeName : openName}
				</a>
			)}
		</div>
	);
};

export default ReadMore;
