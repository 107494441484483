import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Cookies from "universal-cookie";

const Disclaimer = () => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const [isOpen, setIsOpen] = useState(true);

	useEffect(() => {
		const cookies = new Cookies();

		if (cookies.get("registered")) {
			setIsOpen(false); //Modal does not open if cookie exists
		} else if (!cookies.get("registered")) {
			cookies.set("registered", "true", {
				path: "/",
			});
			setIsOpen(true); //Creates a cookie and shows modal.
		}
	}, []);

	return (
		<Modal
			show={isOpen}
			// centered
			className="disclaimer-model"
			contentClassName="bg-blue"
		>
			<Modal.Body>
				<div className="d-flex flex-column align-items-center">
					<p
						style={{
							textAlign: "center",
							marginTop: "4px",
							fontSize: "24px",
							fontWeight: "800",
						}}
					>
						<strong>DISCLAIMER</strong>
					</p>
					<p className="pt-2">
						Zerobroker is an advertising platform to help connect
						buyers and sellers and is not a party to any
						transaction, nor shall be responsible or liable to
						resolve any disputes between them. Any information
						provided on this website, including facts and figures,
						should be verified independently before entering into
						any transaction. Information on projects can be verified
						from RERA website of respective location where the
						Project is located; and Zerobroker being an advertising
						platform has not validated the compliance of these
						Projects under RERA. Zerobroker is neither functioning
						as a Real Estate Agent nor has any means to ascertain
						any actual real estate sale/purchase transactions made
						by the users of this Website or to identify the parties
						to it and therefore we disclaim all liability and
						responsibility under RERA.
					</p>
					<button
						className="btn btn-light mt-4 px-4 py-2"
						onClick={() => setIsOpen(false)}
					>
						I Agree
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default Disclaimer;
