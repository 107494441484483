import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const LeftArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button className={`slider-slick-nav nav-prev`} style={{ ...style }}  role="presentation" onClick={onClick}>
      <span aria-label="Previous">‹</span>
    </button>
  );
};

const RightArrow = (props) => {
  const { className, style, onClick } = props;
  return (
	<button className={`slider-slick-nav nav-next`} style={{ ...style }}  role="presentation" onClick={onClick}>
	<span aria-label="Next">›</span>
  </button>
  );
};

const SliderCarousel = (props) => {
  var settings = {
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    dots: props.dots === true ?? false,
    infinite: props.infinite === true ?? false,
    speed: props.speed ?? 500,
    slidesToShow: props.slidesToShow ?? 1,
    slidesToScroll: props.slidesToScroll ?? 1,
    arrows: props.arrows ?? false,
    autoplay: props.autoplay ?? true,
	autoplaySpeed: props.autoplaySpeed ?? 4000,
    draggable: props.draggable ?? true,
    lazyLoad: props.lazyLoad ?? "progressive",
    centerPadding: props.centerPadding ?? "50px",
    centerMode: props.centerMode ?? false,
    responsive:
      props.slidesToShow >= 3 && props.responsive !== undefined
        ? props.responsive
        : props.responsive !== undefined
        ? [
            {
              breakpoint: 1800,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              },
            },
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]
        : [],
  };

  return (
    <Slider {...settings } className={props.className}>
      {props.children}
    </Slider>
  );
};

export default SliderCarousel;
