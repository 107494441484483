import React from 'react'
import config from '../../config'

function TitleWithLocation({title}) {
  return (
    <div className="d-flex" style={{
        color:config.primaryColor,
        fontSize:20,
        alignItems:"center",
    }}>
        <i className='fa fa-location-dot'></i>
        <span className='ms-2'>{title}</span>
    </div>
  )
}

export default TitleWithLocation