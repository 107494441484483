import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./home.css";

function TopBanner({
	searchInput = null,
	max = null,
	min = null,
	type = null,
	title,
	backgroundImages = [],
}) {
	const [searchData, setSearchData] = useState({});
	const navigate = useNavigate();

	const handleChange = (e) => {
		e.preventDefault();
		setSearchData((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value.trim(),
		}));
	};

	const handleSearch = (e) => {
		e.preventDefault();
		navigate("/search", { state: searchData });
	};
	// console.log("searchData",searchData);

	return (
		<div className="headerContainer pos-r" id="topSliderBanner">
			{backgroundImages.length > 0 ? (
				<OwlCarousel
					className="h-100 w-100 pos-a z-minu10"
					items={1}
					animateIn={true}
					autoplayTimeout={2000}
					loop
					autoplay>
					{backgroundImages &&
						backgroundImages.map((img, idx) => {
							return (
								<img
									src={img.image ? img.image : img}
									key={idx}
									alt={"zerobroker-banner"}
									className="img-fluid bgImg w-100 h-100"
								/>
							);
						})}
				</OwlCarousel>
			) : null}

			<div
				style={{
					position: "absolute",
					height: "100%",
					width: "100%",
					background:
						"linear-gradient(180deg, rgba(0,0,0,0.8323704481792717) 0%, rgba(0,0,0,0.3841911764705882) 100%, rgba(0,0,0,0.577468487394958) 100%)",
				}}
			/>

			<div className="content">
				<h1>ZERO BROK₹AGE HOMES</h1>

				<form className="customform" onSubmit={handleSearch}>
					<select
						id="select1"
						name="type"
						defaultValue={type}
						onChange={handleChange}>
						<option value="" className="label">
							Select Property Type
						</option>
						<option value="Residential">Residential</option>
						<option value="Commercial">Commercial</option>
						<option value="Independent Bunglow/Villas">
							Independent Bunglows/Villas
						</option>
					</select>
					<span className="dividerLight" />
					<div className="priceGroup">
						<select
							id="select2"
							className="p-1"
							name="min"
							defaultValue={min}
							onChange={handleChange}>
							<option value="" className="label">
								Min Budget
							</option>
							<option value="25 Lacs">25 Lacs</option>
							<option value="50 Lacs">50 Lacs</option>
							<option value="75 Lacs">75 Lacs</option>
							<option value="1 Cr">1 Cr</option>
							<option value="1.50 Cr">1.50 Cr</option>
							<option value="2 Cr">2 Cr</option>
							<option value="2.5 Cr">2.5 Cr</option>
							<option value="3 Cr">3 Cr</option>
							<option value="3.5 Cr">3.5 Cr</option>
							<option value="5 Cr & Above">5 Cr & Above</option>
						</select>
						<select
							id="select3"
							className="p-1"
							name="max"
							defaultValue={max}
							onChange={handleChange}>
							<option value="" className="label">
								Max Budget
							</option>
							<option value="50 Lacs">50 Lacs</option>
							<option value="75 Lacs">75 Lacs</option>
							<option value="1 Cr">1 Cr</option>
							<option value="1.50 Cr">1.50 Cr</option>
							<option value="2 Cr">2 Cr</option>
							<option value="2.5 Cr">2.5 Cr</option>
							<option value="3 Cr">3 Cr</option>
							<option value="3.5 Cr">3.5 Cr</option>
							<option value="4 Cr">4 Cr</option>
							<option value="5 Cr & Above">5 Cr & Above</option>
						</select>
					</div>
					<span className="dividerLight" />
					<input
						type="text"
						id="searchInput"
						required
						defaultValue={searchInput}
						name="searchInput"
						placeholder="location/Name/Society/Pincode/Keyword"
						onChange={handleChange}
					/>
					<span className="dividerLight" />
					<button type="submit">Search</button>
				</form>

				{title && <h4 className="mt-3">{title}</h4>}
			</div>
		</div>
	);
}

export default TopBanner;
