import React from 'react'
import config from '../../../config';
import "./parts.css";
function DetialHeader({title}) {
  return (
    <div  className="header" style={{
      borderBottom:`2px solid ${config.primaryColor}`
    }}>
      <h4 style={{
        background:config.primaryColor,
      }}>{title}</h4>
    </div>
  )
}

export default DetialHeader