import React from 'react'
import CustomHeader from '../components/useful/CustomHeader'
import ABOUT_US_IMAGE from "../assets/images/about_us.svg"
import config from '../config'
import PageLayout from '../components/PageLayout'

function About() {
  return (
    <PageLayout title={"About Us | Zero Broker"}>
      <CustomHeader
        backgroundImage={"./assets/images/banner/about.jpg"}
        title="OUR VISION"
        subtitle="ZERO BROK₹AGE HOMES"
        CustomeClass={"about-page-hero"}
      />
      <div className="container">
        <div className="row  py-5" >
          <div className="col-md-4 my-4 my-md-0">
            <img src={ABOUT_US_IMAGE} height={250} alt="About Page Image" />
          </div>
          <div className="col-md-8">
            <p style={{
              color: config.primaryColor,
              fontSize: 30
            }}>WELCOME TO ZERO BROKER</p>
            <p className='fs16'>We Zero broker sturdy growing web portal in real estate online arena. Born out of the need to simplify the search for a property at Zero brokerage homes with genuine listings and Real Prices, we created a unique property search platform that filled the gaps left by others in the market. We are poised to become the most trusted brand to find a property in Mumbai, Navi Mumbai & Thane.</p>
          </div>
        </div>

        <div className="row  py-5">
          <div className="col-md-8">
            <p style={{
              color: config.primaryColor,
              fontSize: 30
            }}>KNOW US</p>
            <p className='fs16'>We are serving this industry since 2017 by providing an online platform acting as a search engine or advertising agency. With our vast experience in the same field, we have emerged as the solitary solution provider. With such an immense exposure in the said domain, we completely understand the needs of our consumers and strive hard to meet the same.
              <br />
              <br />
              Being substantial in the realm of Real estate, we have stridden a long way in a short span of time. With our resolution to raise high, we beat the all hurdles and stood against all odds. This marvelous achievement has been the upshot of the trust, which our clients have shown over the past years.
            </p>
          </div>


          <div className="col-md-4 my-4 my-md-0">
            <img src="./assets/images/zerobroker.png" className='img-fluid' alt="About Page Image" />
          </div>

        </div>

        <div className="row pb-5">
          <div className="col-md-12">
            <p style={{
              color: config.primaryColor,
              fontSize: 30
            }}>OUR MISSION</p>
            <p className='fs16'>To be the first choice for our users in their journey of discovering, buying, and financing loans. We do that with data, design, technology, and above all the passion of our people, while delivering value to our valued clients.
              <br />
              <br />
              Founded in 2017 in Mumbai, Zero Broker has left a memorable footprint in all over Mumbai. And we continue to work towards Zero brokerage homes for all.
            </p>
          </div>
        </div>

      </div>
    </PageLayout>
  )
}

export default About