import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "../../LazyLoad";
import "./parts.css";

function ProjectCard({ item }) {
	let notiClass = "";
	if (item?.proj_possesion === "Ready To Move") {
		notiClass = "green";
	}
	if (item?.proj_possesion === "Possession Near Completion") {
		notiClass = "orange";
	}
	if (item?.proj_possesion === "Under Construction") {
		notiClass = "red";
	}
	if (item?.proj_possesion === "New Launch") {
		notiClass = "orange";
	}

	return (
		<>
			{item ? (
				<Link
					to={`/property/ZBID00${item.proj_id}`}
					state={item}
					className="item p-2 pcard-container">
					<div className="card pcard border-0 rounded shadow">
						<div
							className="pos-r rounded overflow-hidden shine"
							style={{ paddingTop: "calc(31000%/320)" }}>
							<LazyLoad
								src={
									item.proj_images &&
									"https://zerobroker.in/assets/images/thumbnail/" +
										item.thumbnail
								}
								alt={item.proj_name}
								lazyClass={"pos-a t-0 l-0 w-100 h-100"}
								className="w-100 h-100"
							/>
							<div
								className={`pos-a z-1 projectNoticeCard ${notiClass}`}>
								{item.proj_possesion}
							</div>
						</div>
						<div className="overlayContent">
							<div className="w-100 d-flex flex-col justify-content-between align-items-center">
								<div>
									<div className="title max-line-1">
										{item.proj_name && item.proj_name}
									</div>
									<div className="text-secondary subtitle fz-12">
										{item.proj_loc && item.proj_loc}{" "}
										<strong>-</strong>{" "}
										{item.pincode && item.pincode}
									</div>
								</div>
							</div>
							<div className="pt-2">
								<span className="priceMain">
									₹{" "}
									{item.proj_display_price &&
										item.proj_display_price}
								</span>
							</div>
							<div className="d-flex pt-2 align-items-center">
								<span className="area">
									{item.proj_bhk
										? item.proj_bhk
										: item.commercial_type}
								</span>
								<hr className="dividerLight mt-0 mb-0" />

								<span className="area">
									{item.rera_carpet && item.rera_carpet}
								</span>
							</div>
						</div>
					</div>
				</Link>
			) : (
				<LoaderPropertyCard />
			)}
		</>
	);
}

export default ProjectCard;

export const LoaderPropertyCard = () => {
	return (
		<div className="item p-2 my-3 pcard-container w-100 loader">
			<div className="card pcard border-0 rounded shadow">
				<div
					className="pos-r rounded overflow-hidden imageWrapper shimmer"
					style={{ paddingTop: "calc(31000%/320)" }}></div>
				<div className="overlayContent">
					<div className="w-100 d-flex flex-col justify-content-between w-100">
						<div className="w-100 me-3">
							<div className="title shimmer"></div>
							<div className="subtitle shimmer"></div>
						</div>
						<div>
							<div className="projectNoticeCard shimmer"></div>
						</div>
					</div>
					<div className="pt-4">
						<span className="priceMain shimmer"></span>
					</div>
					<div className="area pt-2 shimmer"></div>
				</div>
			</div>
		</div>
	);
};
