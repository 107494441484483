import React from 'react'

function Logo({src,width}) {
  return (
    <img className='img-fluid' src={src} style={{ 
        maxWidth:width,
        width:"100%",
        transition:"0.1s linear all"
    }}/>
  )
}

export default Logo