import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import config from "../../config";

const EMICalculator = ({ setModalShow, href, marginTop }) => {
	const [type, setType] = useState(1); // 0 is Month & 1 is Year
	const [loanAmount, setLoanAmount] = useState(1000000);
	const [interestAmount, setInterestAmount] = useState(8.6);
	const [loanTenure, setTenure] = useState(10);

	// Calculated Data
	const [emi, setEmi] = useState(11457);
	const [payableInterest, setPayableInterest] = useState(374819);
	const [total, setTotal] = useState(1374819);

	useEffect(() => {
		if (type === 0) {
			setTenure(10 * 12);
		}
		if (type === 1) {
			setTenure(10);
		}
	}, [type]);

	function calculateEmi(principalAmount, interest, months) {
		var monthlyInterest =
			(parseFloat(principalAmount) * (parseFloat(interest) / 100)) / 12;
		var pmt =
			parseFloat(monthlyInterest) /
			(1 -
				Math.pow(
					1 + parseFloat(interest) / 100 / 12,
					-parseFloat(months)
				));
		var total = parseFloat(pmt) * Math.floor(parseFloat(months));
		var totalInterest = parseFloat(total) - parseFloat(principalAmount);
		setEmi(pmt.toFixed(0));
		setPayableInterest(totalInterest.toFixed(0));
		setTotal(Math.round(parseFloat(loanAmount) + totalInterest));
	}

	function calculate() {
		var checkYear = type === 1 ? loanTenure * 12 : loanTenure;
		calculateEmi(loanAmount, interestAmount, checkYear);
	}

	return (
		<>
			<div
				className={
					marginTop
						? marginTop + " pt-4 container"
						: "mt-5 pt-4 container"
				}
				id="emi-calculator-section"
			>
				<h3 className="check-homeloan-title">Check Loan Eligibility</h3>
				<p className="check-homeloan-subtitle">
					Please fill the details below and check your loan
					eligibility easily and get your dream house.
				</p>

				<div className="card shadow">
					{/* <hr /> */}
					<div className="row">
						<div className="col-md-7">
							<div className="d-flex align-items-center ps-4">
								<label>Select :</label>
								<div className="typeButtonArea">
									<span
										className={type === 1 ? "active" : ""}
										onClick={() => setType(1)}
									>
										Year
									</span>
									<span
										className={type === 0 ? "active" : ""}
										onClick={() => setType(0)}
									>
										Month
									</span>
								</div>
							</div>

							<div className="form-floating m-3">
								<input
									type="number"
									className="form-control"
									id="loanAmount"
									placeholder="Loan Amount"
									value={loanAmount}
									onChange={(e) =>
										setLoanAmount(e.target.value)
									}
								/>
								<label htmlFor="loanAmount">Loan Amount</label>
							</div>

							<div className="form-floating m-3">
								<input
									type="number"
									className="form-control"
									id="interestRate"
									placeholder="Interest Rate"
									value={interestAmount}
									onChange={(e) =>
										setInterestAmount(e.target.value)
									}
								/>
								<label htmlFor="interestRate">
									Interest Rate
								</label>
							</div>

							<div className="form-floating m-3">
								<input
									type="number"
									className="form-control"
									id="loanTenure"
									placeholder="Loan Tenure"
									value={loanTenure}
									onChange={(e) => setTenure(e.target.value)}
								/>
								<label htmlFor="loanTenure">Loan Tenure</label>
							</div>

							<button
								className="m-3 btn text-white px-4"
								style={{
									background: config.primaryColor,
									borderRadius: 100,
									fontSize: 16,
								}}
								onClick={() => {
									calculate();
									// setShowApplyLoan(true);
								}}
							>
								Calculate
							</button>
						</div>
						<div className="col-md-5">
							<div className="calculator-result">
								<div className="chart-div p-md-3 p-1 pt-3">
									<PieChart
										data={[
											{
												title:
													`₹ ${parseFloat(loanAmount)
														.toFixed(2)
														.replace(
															/\d(?=(\d{3})+\.)/g,
															"$&,"
														)}` ??
													"Principle Amount",
												value: parseFloat(loanAmount),
												color: config.primaryColor,
											},
											{
												title:
													`₹ ${parseFloat(
														payableInterest
													)
														.toFixed(2)
														.replace(
															/\d(?=(\d{3})+\.)/g,
															"$&,"
														)}` ??
													"Interest Payable",
												value: parseFloat(
													payableInterest
												),
												color: "#ff4356",
											},
										]}
										// label={({ dataEntry }) =>
										// 	Math.round(
										// 		dataEntry.percentage
										// 	) + "%"
										// }
										labelStyle={{
											fontSize: "5px",
											fontFamily: "sans-serif",
										}}
										segmentsStyle={{
											transition: "stroke .3s",
											cursor: "pointer",
										}}
										style={{
											maxWidth: "80%",
										}}
										lineWidth={40}
										paddingAngle={1.5}
										startAngle={180}
										lengthAngle={180}
										viewBoxSize={[100, 50]}
									/>

									<div className="chart-info-div">
										<p>
											<span className="color-title">
												<span className="color principle"></span>
												<span>Principle Amount</span>
											</span>

											<span className="amount">
												₹{" "}
												{parseFloat(loanAmount)
													.toFixed(2)
													.replace(
														/\d(?=(\d{3})+\.)/g,
														"$&,"
													)}
											</span>
										</p>

										<p>
											<span className="color-title">
												<span className="color interest"></span>
												<span>Interest Payable</span>
											</span>

											<span className="amount">
												₹{" "}
												{parseFloat(payableInterest)
													.toFixed(2)
													.replace(
														/\d(?=(\d{3})+\.)/g,
														"$&,"
													)}
											</span>
										</p>

										<p>
											<span className="color-title">
												{/* <span className="color"></span> */}
												<span className="fw-bold">
													Total Amount Payable
												</span>
											</span>

											<span className="amount fw-bold">
												₹{" "}
												{parseFloat(total)
													.toFixed(2)
													.replace(
														/\d(?=(\d{3})+\.)/g,
														"$&,"
													)}
											</span>
										</p>

										<p>
											<span className="color-title">
												{/* <span className="color"></span> */}
												<span className="fw-bold">
													Loan EMI
												</span>
											</span>

											<span className="amount fw-bold">
												₹{" "}
												{parseFloat(emi)
													.toFixed(2)
													.replace(
														/\d(?=(\d{3})+\.)/g,
														"$&,"
													)}
											</span>
										</p>
									</div>

									<div className="mt-3 col-12 d-flex justify-content-center">
										<button
											className="btn text-white p-2 apply-loan-btn"
											style={{
												width: "100%",
												background: config.primaryColor,
												alignSelf: "center",
											}}
											onClick={() => {
												// setShowApplyLoan(false);
												setModalShow(true);
											}}
										>
											Apply For Loan
										</button>
									</div>
								</div>
							</div>
							{/* <Lottie
									width={"100%"}
									animationData={loanCalculator}
									loop={true}
									autoPlay={true}
								/> */}
						</div>

						{/* <Modal
							show={showApplyLoan}
							onHide={() => setShowApplyLoan(false)}
							size="xl">
							<Modal.Header closeButton>
								<Modal.Title>Result</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="calculator-result row">
									<div className="chart-div mt-2 col-lg-5">
										<PieChart
											data={[
												{
													title:
														`₹ ${parseFloat(
															loanAmount
														)
															.toFixed(2)
															.replace(
																/\d(?=(\d{3})+\.)/g,
																"$&,"
															)}` ??
														"Principle Amount",
													value: loanAmount,
													color: "#18136d",
												},
												{
													title:
														`₹ ${parseFloat(
															payableInterest
														)
															.toFixed(2)
															.replace(
																/\d(?=(\d{3})+\.)/g,
																"$&,"
															)}` ??
														"Interest Payable",
													value: parseFloat(
														payableInterest
													),
													color: "#ff4356",
												},
											]}
											// label={({ dataEntry }) =>
											// 	Math.round(
											// 		dataEntry.percentage
											// 	) + "%"
											// }
											labelStyle={{
												fontSize: "5px",
												fontFamily: "sans-serif",
											}}
											segmentsStyle={{
												transition: "stroke .3s",
												cursor: "pointer",
											}}
											style={{
												maxWidth: "80%",
											}}
											lineWidth={40}
											paddingAngle={1.5}
											startAngle={180}
											lengthAngle={180}
											viewBoxSize={[100, 50]}
										/>

										<div className="chart-info-div">
											<p>
												<span className="color-title">
													<span className="color principle"></span>
													<span>
														Principle Amount
													</span>
												</span>

												<span className="amount">
													₹{" "}
													{parseFloat(loanAmount)
														.toFixed(2)
														.replace(
															/\d(?=(\d{3})+\.)/g,
															"$&,"
														)}
												</span>
											</p>

											<p>
												<span className="color-title">
													<span className="color interest"></span>
													<span>
														Interest Payable
													</span>
												</span>

												<span className="amount">
													₹{" "}
													{parseFloat(payableInterest)
														.toFixed(2)
														.replace(
															/\d(?=(\d{3})+\.)/g,
															"$&,"
														)}
												</span>
											</p>
										</div>

										<div className="mt-3 col-12 d-flex justify-content-center">
											<button
												className="btn text-white p-2 apply-loan-btn"
												style={{
													width: "100%",
													background:
														config.primaryColor,
													alignSelf: "center",
												}}
												onClick={() => {
													setShowApplyLoan(false);
													setModalShow(true);
												}}>
												Apply For Loan
											</button>
										</div>
									</div>
									<!-- <h5 className="text-center fw-bold calc-title">
											Result
										</h5> --!>
									<div className="col-lg-7">
										<div className="card p-4 m-3">
											<span className="text-center">
												Loan EMI
											</span>
											<span className="text-center fw-bold fs-5">
												₹{" "}
												{parseFloat(emi)
													.toFixed(2)
													.replace(
														/\d(?=(\d{3})+\.)/g,
														"$&,"
													)}
											</span>

											<hr />

											<span className="text-center">
												Total Interest Payable
											</span>
											<span className="text-center fw-bold fs-5">
												₹{" "}
												{parseFloat(payableInterest)
													.toFixed(2)
													.replace(
														/\d(?=(\d{3})+\.)/g,
														"$&,"
													)}
											</span>

											<hr />

											<span className="text-center">
												Total of Payments <br />
												(Principal + Interest)
											</span>
											<span className="text-center fw-bold fs-5">
												₹{" "}
												{parseFloat(total)
													.toFixed(2)
													.replace(
														/\d(?=(\d{3})+\.)/g,
														"$&,"
													)}
											</span>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default EMICalculator;
