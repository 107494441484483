import React, { useCallback, useEffect, useState } from "react";
import RangeSlider from "react-bootstrap-range-slider";

import Modal from "react-bootstrap/Modal";
import config from "../config";
import { CloseButton } from "react-bootstrap";

import PageLayout from "../components/PageLayout";
import CustomHeader from "../components/useful/CustomHeader";
import { api } from "../helpers/api";
import { toast } from "react-toastify";
import Banks from "../components/loan/Banks";
import CheckEligibilityForm from "../components/loan/CheckEligibilityForm";

function HomeLoanBTC() {
  const [showLogin, setShowLogin] = useState(false);

  const [PrincipalAmount, setPrincipalAmount] = useState(500000);
  const [rateInterest, setRateInterest] = useState(8.6);
  const [loanTenure, setLoanTenure] = useState(10);
  const [numberOfEMi, setNumberOfEMi] = useState(0);
  const [expRateofInterset, setExpRateofInterset] = useState(6.5);

  // Calculated Data
  const [emi, setEmi] = useState(0);
  const [payableInterest, setPayableInterest] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);

  function calculate() {
    let pr_amount = parseFloat(PrincipalAmount),
      calculatedInterest = parseFloat(rateInterest) / 100 / 12,
      calculatedPayments = parseFloat(loanTenure) * 12;

    let x = Math.pow(1 + calculatedInterest, calculatedPayments),
      monthly = (pr_amount * x * calculatedInterest) / (x - 1);

    let total_emi_with_intst = calculatedPayments * monthly;

    let exp_calculatedInterest = parseFloat(expRateofInterset) / 100 / 12,
      exp_calculatedPayments = parseFloat(loanTenure) * 12;

    let y = Math.pow(1 + exp_calculatedInterest, exp_calculatedPayments),
      exp_monthly = (pr_amount * y * exp_calculatedInterest) / (y - 1);
    // finec = exp_monthly - parseInt(monthly);

    let total_expec_emi_with_intst = exp_calculatedPayments * exp_monthly;

    setEmi(Math.round(monthly));
    // setPayableInterest(parseInt(exp_monthly - 3 * finec));
    setPayableInterest(Math.round(exp_monthly));
    setTotalSavings(
      (
        parseFloat(total_emi_with_intst) -
        parseFloat(total_expec_emi_with_intst)
      ).toFixed(2)
    );

    console.log(total_emi_with_intst.toFixed(2));
    console.log(total_expec_emi_with_intst.toFixed(2));
    // console.log(exp_calculatedPayments)
    // console.log(exp_monthly)
  }

  const [formData, updateFormData] = useState({
    loan_type: "Home Loan Balance Transfer",
  });

  // const [checked, setChecked] = useState(false);
  const [phone, setPhone] = useState();
  const [otp, setOtp] = useState();
  const [showOtp, setShowOtp] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };
  const SubmitButton = document.querySelector(".submitBtn");
  const handleSubmit = async (e) => {
    var data = {
      ...formData,
      phone: phone,
      loanTenure: loanTenure,
      numberOfEMi: numberOfEMi,
      expRateofInterset: expRateofInterset,
      // loanTenure: loanTenure,
      PrincipalAmount: PrincipalAmount,
    };
    e.preventDefault();
    if (isVerified) {
      try {
        SubmitButton.innerText = "Please Wait.....";
        const response = await api({
          method: "post",
          url: "send_inquiry.php",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await response.data;
        if (jsonData.status === 200) {
          toast.success("Form Submitted Succesfull !");
          var close = setInterval(() => {
            setShowLogin(false);
            updateFormData();
            clearInterval(close);
            window.location.reload();
          }, 1000);
        }
      } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        if (errorObject.status === 400) {
          toast.error("Please try again later!");
        }
      }
    } else {
      toast.error("Please Verify Phone Number");
    }
  };

  const SendOTP = async (e, msg = "Sent") => {
    e.preventDefault();
    const response = await api({
      method: "post",
      url: "verify_mobile.php",
      data: { phone: phone, send_otp: true },
      headers: { "Content-Type": "multipart/form-data" },
    });
    const jsonData = await response;
    console.log(jsonData);
    if (response.data.Status === "Success") {
      setShowOtp(true);
      setOtp("");
      toast.success(`OTP ${msg}`);
    } else {
      toast.error("Invalid Phone Number");
    }
  };
  const VerifyOTP = async (e) => {
    e.preventDefault();
    const response = await api({
      method: "post",
      url: "verify_mobile.php",
      data: { phone: phone, otp: otp, verify_otp: true },
      headers: { "Content-Type": "multipart/form-data" },
    });
    const jsonData = await response;
    console.log(jsonData);
    if (response.data.Status === "Success") {
      setIsVerified(true);
      toast.success("OTP Verified");
    } else {
      toast.error("OTP Mismatch");
    }
  };

  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    let timeout;
    timer > 0 && (timeout = setTimeout(timeOutCallback, 1000));

    return () => clearTimeout(timeout);
  }, [timer, timeOutCallback]);

  const resetTimer = function (e) {
    if (!timer) {
      SendOTP(e, "Sent");
      setTimer(60);
    }
  };
  return (
    <>
      <PageLayout title={"Loan | Zero Broker"}>
        <CustomHeader
          backgroundImage={"./assets/images/banner/balance-transfer.jpg"}
          title={"ZERO BROK₹AGE HOME LOANS"}
          subtitle={
            "Lower interest rates, Greater loan eligibility & best loan offers from multiple banks"
          }
          // bankList={true}
          CustomeClass={"mbl-Fullscreen"}
        />

        <div className="container loan-details pb-5">
          <h3 className="text-center fw-bold w-100 m-3 home-loan-heads">
            Balance Transfer Loan
          </h3>
          <p className="type_loans_content ">
            A home loan balance transfer is the process of transferring an
            existing home loan to another lender at a lower rate of interest and
            other benefits. It is quite similar to availing a new home loan.
            When a borrower makes a balance transfer, their total outstanding
            amount of the loan will be transferred to the new account where the
            new lender will pay the remaining balance to the previous lender and
            the account will be closed. The new rate of interest will help the
            borrower to save on the monthly instalments or the EMIs as well as
            the interest outgo on a home loan.
          </p>

          {/* <EMICalculator setModalShow={setModalShow} />

					<ApplyLoanForm
						modalShow={modalShow}
						setModalShow={setModalShow}
					/> */}

          {/* <h3
						className="text-center fw-bold w-100 m-3 home-loan-heads"
						id="inquiryForm">
						CHECK YOUR ELIGIBILITY
					</h3> */}

          <div className="card calcForm mt-5">
            <h3
              className="fw-bold text-center text-white p-3"
              style={{ background: config.primaryColor }}
            >
              Home Loan Balance Transfer Calculator
            </h3>
            <div className="row">
              <div className="col-md-7 ">
                <div className="form-floating m-3">
                  <div className="form-floating m-3">
                    <div className="col1-calc">
                      <label className="calc-label">Loan Amount</label>
                      <span>
                        ₹
                        <input
                          type="text"
                          className="blank-inp inp-lg-wid"
                          value={PrincipalAmount}
                          //   maxLength={9}
                          onChange={(e) => {
                            if (e.target.value > 200000000) {
                              e.target.preventDefault();
                              return false;
                            }

                            setPrincipalAmount(e.target.value);
                          }}
                        />
                      </span>
                    </div>
                    <div className="col2-calc">
                      <RangeSlider
                        value={PrincipalAmount}
                        onChange={(e) => {
                          if (e.target.value > 200000000) {
                            e.target.preventDefault();
                            return false;
                          }

                          setPrincipalAmount(e.target.value);
                        }}
                        min={500000}
                        max={200000000}
                      />
                    </div>
                    <div className="col3-calc">
                      <label>5 Lac</label>
                      <label>20 Cr</label>
                    </div>
                  </div>
                </div>

                <div className="form-floating m-3">
                  <div className="form-floating m-3">
                    <div className="col1-calc">
                      <label className="calc-label">Loan Tenure</label>
                      <span>
                        <input
                          className="blank-inp inp-xsml-wid"
                          type={"text"}
                          value={loanTenure}
                          onChange={(e) => {
                            if (e.target.value > 30) {
                              e.target.preventDefault();
                              return false;
                            }

                            setLoanTenure(e.target.value);
                          }}
                        />
                        years
                      </span>
                    </div>
                    <div className="col2-calc">
                      <RangeSlider
                        value={loanTenure}
                        onChange={(e) => {
                          if (e.target.value > 30) {
                            e.target.preventDefault();
                            return false;
                          }

                          setLoanTenure(e.target.value);
                        }}
                        min={5}
                        max={30}
                      />
                    </div>
                    <div className="col3-calc">
                      <label>5 years</label>
                      <label>30 years</label>
                    </div>
                  </div>
                </div>

                <div className="form-floating m-3">
                  <div className="form-floating m-3">
                    <div className="col1-calc">
                      <label className="calc-label">
                        Current Rate of Interest
                      </label>
                      <span>
                        <input
                          className="blank-inp inp-xsml-wid"
                          type={"text"}
                          value={rateInterest}
                          step={0.1}
                          onChange={(e) => {
                            if (e.target.value > 20) {
                              e.target.preventDefault();
                              return false;
                            }

                            setRateInterest(e.target.value);
                          }}
                        />
                        %
                      </span>
                    </div>
                    <div className="col2-calc">
                      <RangeSlider
                        value={rateInterest}
                        onChange={(e) => {
                          if (e.target.value > 20) {
                            e.target.preventDefault();
                            return false;
                          }

                          setRateInterest(e.target.value);
                        }}
                        step={0.1}
                        min={6}
                        max={20}
                      />
                    </div>
                    <div className="col3-calc">
                      <label>6</label>
                      <label>20</label>
                    </div>
                  </div>
                </div>

                <div className="form-floating m-3">
                  <div className="form-floating m-3">
                    <div className="col1-calc">
                      <label className="calc-label">
                        Expected Rate of Interest
                      </label>
                      <span>
                        <input
                          className="blank-inp inp-xsml-wid"
                          type={"text"}
                          step={0.1}
                          value={expRateofInterset}
                          onChange={(e) => {
                            if (e.target.value > 9) {
                              e.target.preventDefault();
                              return false;
                            }

                            setExpRateofInterset(e.target.value);
                          }}
                        />
                        %
                      </span>
                    </div>
                    <div className="col2-calc">
                      <RangeSlider
                        value={expRateofInterset}
                        step={0.1}
                        onChange={(e) => {
                          if (e.target.value > 9) {
                            e.target.preventDefault();
                            return false;
                          }

                          setExpRateofInterset(e.target.value);
                        }}
                        min={6}
                        max={9}
                      />
                    </div>
                    <div className="col3-calc">
                      <label>6</label>
                      <label>9</label>
                    </div>
                  </div>
                </div>

                <div className="form-floating m-3">
                  <div className="form-floating m-3">
                    <div className="col1-calc">
                      <label className="calc-label">Number of EMI's paid</label>
                      <span>
                        <input
                          className="blank-inp inp-sml-wid"
                          type={"text"}
                          value={numberOfEMi}
                          onChange={(e) => {
                            if (e.target.value > 227) {
                              e.target.preventDefault();
                              return false;
                            }

                            setNumberOfEMi(e.target.value);
                          }}
                        />
                        EMI
                      </span>
                    </div>
                    <div className="col2-calc">
                      <RangeSlider
                        value={numberOfEMi}
                        onChange={(e) => {
                          if (e.target.value > 227) {
                            e.target.preventDefault();
                            return false;
                          }

                          setNumberOfEMi(e.target.value);
                        }}
                        min={0}
                        max={227}
                      />
                    </div>
                    <div className="col3-calc">
                      <label>0</label>
                      <label>227</label>
                    </div>
                  </div>
                </div>
                <button
                  className="m-3 btn text-white px-4"
                  style={{
                    background: config.primaryColor,
                    borderRadius: 100,
                    fontSize: 16,
                  }}
                  onClick={calculate}
                >
                  Calculate
                </button>
              </div>
              <div className="col-md-5">
                <h5 className="text-center fw-bold">Result</h5>
                <div className="card p-3 m-3">
                  <span className="text-center">Total Saving</span>
                  <span className="text-center fw-bold">
                    ₹{" "}
                    {parseFloat(totalSavings)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </span>
                </div>
                <div className="card p-3 m-3">
                  <span className="text-center">Current EMI</span>
                  <span className="text-center fw-bold">
                    ₹{" "}
                    {parseFloat(emi)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </span>
                </div>
                <div className="card p-3 m-3">
                  <span className="text-center">
                    Revised EMI <br />
                    {/* (Principal + Interest) */}
                  </span>
                  <span className="text-center fw-bold">
                    ₹{" "}
                    {parseFloat(payableInterest)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </span>
                </div>

                <div className="d-flex p-3 justify-content-center">
                  <button
                    className="btn text-white p-2"
                    style={{
                      width: "100%",
                      background: config.primaryColor,
                      alignSelf: "center",
                    }}
                    onClick={() => setShowLogin(true)}
                  >
                    Apply For Loan
                  </button>
                </div>
              </div>
            </div>
          </div>

          <CheckEligibilityForm />
        </div>

        <Banks setModalShow={false} href="#inquiryForm" />

        <Modal
          show={showLogin}
          onHide={() => {
            setShowLogin(false);
          }}
          centered
          fullscreen={window.screen.width >= 600 ? false : true}
          backdropClassName="backdropModel"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            <div className="">
              <div className="mobile-register-form">
                <div className="d-flex justify-content-between login-flex">
                  <h3 className="mb-4 fw-bold">Home Loan Balance Transfer</h3>
                  <CloseButton
                    onClick={() => {
                      setShowLogin(false);
                    }}
                    color="#000"
                  />
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="loanAmount"
                    name=""
                    value={PrincipalAmount}
                    disabled
                  />
                  <label htmlFor="loanAmount">Loan Amount</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="loanTenure"
                    name="loanTenure"
                    value={loanTenure}
                    disabled
                  />
                  <label htmlFor="loanTenure">Loan Tenuret</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="PrincipalAmount"
                    name="PrincipalAmount"
                    value={rateInterest}
                    disabled
                  />
                  <label htmlFor="PrincipalAmount">
                    Current Rate of Interest
                  </label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="expRateofInterset"
                    name="expRateofInterset"
                    value={expRateofInterset}
                    disabled
                  />
                  <label htmlFor="expRateofInterset">
                    Expected Rate of Interest
                  </label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="numberOfEMi"
                    name="numberOfEMi"
                    value={numberOfEMi}
                    disabled
                  />
                  <label htmlFor="numberOfEMi">Number of EMI's paid</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    placeholder="fullName"
                    name="fullName"
                    onChange={handleChange}
                  />
                  <label htmlFor="fullName">Full Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="name@example.com"
                    onChange={handleChange}
                  />
                  <label htmlFor="email">Email address</label>
                </div>

                <div className="mb-3">
                  {!showOtp ? (
                    <div className="input-group">
                      <div className="form-floating">
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Enter OTP"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <label htmlFor="phone">Phone</label>
                      </div>
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={(e) => SendOTP(e, "Sent")}
                      >
                        Send OTP
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="input-group">
                        <div className="form-floating">
                          <input
                            type="tel"
                            className="form-control"
                            id="otp"
                            name="otp"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                          <label htmlFor="otp">Enter OTP</label>
                        </div>
                        {!isVerified ? (
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={VerifyOTP}
                          >
                            Verify OTP
                          </button>
                        ) : (
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            disabled
                          >
                            Verified
                          </button>
                        )}
                      </div>
                      {timer <= 0 ? (
                        <p
                          className="resend-timer btn-link"
                          onClick={(e) => resetTimer(e)}
                        >
                          Resend OTP
                        </p>
                      ) : (
                        <p className="resend-timer">Resend OTP in {timer}</p>
                      )}
                    </>
                  )}
                </div>

                <button
                  className="btn text-white submitBtn"
                  onClick={handleSubmit}
                  style={{ background: config.primaryColor }}
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </PageLayout>
    </>
  );
}

export default HomeLoanBTC;
