import React from "react";
import ProjectCard, { LoaderPropertyCard } from "./parts/ProjectCard";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function TopProjects({ title, data = [], loadingStatus = true }) {
  return (
    <div className="container-fluid my-3 topcardcontainer">
      <h3 className="text-center my-3 text-uppercase fw-bold">{title}</h3>
      <div
        className="d-flex"
        style={{
          minHeight: "40vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loadingStatus ? (
          data.length > 0 ? (
            <>
              <div className="col-md-12 m-0 p-0 carausal ">
                <OwlCarousel
                  className="owl-stage-outer"
                  items={4}
                  margin={20}
                  navClass={["nav-prev", "nav-next"]}
                  autoplay
                  nav
                  responsive={{
                    0: {
                      items: 1,
                    },
                    700: {
                      items: 3,
                    },
                    1000: {
                      items: 4,
                    },
                  }}
                >
                  {data.map((item, index) => (
                    <ProjectCard key={index} item={item} />
                  ))}
                </OwlCarousel>
              </div>
              <div className="phoneCard d-md-none d-none">
                {data.map((item, index) => (
                  <ProjectCard key={index} item={item} />
                ))}
              </div>
            </>
          ) : (
            <OwlCarousel
              className="owl-stage-outer"
              items={4}
              margin={20}
              navClass={["nav-prev", "nav-next"]}
              autoplay
              nav
              responsive={{
                0: {
                  items: 1,
                },
                700: {
                  items: 3,
                },
                1000: {
                  items: 4,
                },
              }}
            >
              <LoaderPropertyCard />
              <LoaderPropertyCard />
              <LoaderPropertyCard />
              <LoaderPropertyCard />
              <LoaderPropertyCard />
            </OwlCarousel>
          )
        ) : (
          <div className="projectNoticeCard red w-100 text-center">
            Property Not Found
          </div>
        )}
      </div>
    </div>
  );
}

export default TopProjects;
