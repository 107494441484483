import React from 'react'
import config from "../../config.js"

function LoadMoreButton({onClick}) {
  return (
    <div style={{
        width:"100%",
        display:'flex',
        justifyContent:"center",
        alignItems:"center",
        marginTop:20,
    }}>
        <button onClick={onClick} className='btn px-4 text-white' style={{
            background: config.primaryColor,
            borderRadius:100,
        }}>Load More</button>
    </div>
  )
}

export default LoadMoreButton