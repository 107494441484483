import { useState, useEffect, useRef } from "react";
import { List, PersonCircle } from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation } from "react-router-dom";
import Logo from "./Logo";
import "./Header.css";
import Modal from "react-bootstrap/Modal";
import { CloseButton } from "react-bootstrap";
import { api } from "../../helpers/api";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { useCallback } from "react";

function Header({ headerTransparent = false }) {
	const [bgColor, setBgColor] = useState(headerTransparent);
	const location = useLocation();

	const [showWorkWithUs, setShowWorkWithUs] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	const [showSidebar, setShowSidebar] = useState(null);
	const [loading, setLoading] = useState(false);
	const [formData, updateFormData] = useState({});

	const [authId, setAuthId] = useState();
	const [otp, setOtp] = useState();

	const [showOtp, setShowOtp] = useState(false);
	const offCanvasRef = useRef();
	const closeOffCanvas = () => offCanvasRef.current.backdrop.click();

	// eslint-disable-next-line no-useless-escape
	const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

	const validateEmail = (val) => {
		if (val && val.match(isValidEmail)) {
			return true;
		} else {
			return false;
		}
	};

	const VerifyAuth = async (e, type = "Sent") => {
		e.preventDefault();

		var response = null;
		var jsonData = null;
		if (validateEmail(authId)) {
			response = await api({
				method: "post",
				url: "auth_login.php",
				data: { authId: authId, send_email: true },
				headers: { "Content-Type": "multipart/form-data" },
			});

			jsonData = await response.data;
			console.log(jsonData);
			if (jsonData.status === 200) {
				setShowOtp(true);
				toast.success(`OTP ${type} on mail.`);
			}
		} else {
			if (!isNaN(authId)) {
				response = await api({
					method: "post",
					url: "auth_login.php",
					data: { authId: authId, send_phone: true },
					headers: { "Content-Type": "multipart/form-data" },
				});

				jsonData = await response.data;
				console.log(jsonData);
				if (jsonData.Status === "Success") {
					setShowOtp(true);
					toast.success(`OTP ${type} on your phone.`);
				}
			} else {
				toast.error("Invaild Mobile Number");
			}
		}
	};

	const VerifyOTP = async (e) => {
		e.preventDefault();

		var response = null;
		var jsonData = null;

		if (validateEmail(authId)) {
			try {
				response = await api({
					method: "post",
					url: "auth_login.php",
					data: { authId: authId, verify_otp: otp },
					headers: { "Content-Type": "multipart/form-data" },
				});

				e.preventDefault();
				jsonData = await response.data;
				console.log(jsonData);
				if (jsonData.status === 200) {
					window.location.href = "/client/";
				}
			} catch (error) {
				const { response } = error;
				const { request, ...errorObject } = response; // take everything but 'request'
				console.log(errorObject);
				if (errorObject.status === 400) {
					toast.error("OTP Mismatch, Please try again");
				}
			}
		} else {
			try {
				response = await api({
					method: "post",
					url: "auth_login.php",
					data: { authId: authId, verify_phone_otp: otp },
					headers: { "Content-Type": "multipart/form-data" },
				});

				jsonData = await response.data;
				if (jsonData.Status === "Success") {
					// navigate('/client/');
					window.location.href = "/client/";
				}
			} catch (error) {
				const { response } = error;
				const { request, ...errorObject } = response; // take everything but 'request'
				console.log(errorObject);
				if (errorObject.status === 400) {
					toast.error("OTP Mismatch, Please try again");
				}
			}
		}
	};

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const workSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const response = await api({
				method: "post",
				url: "send_inquiry.php",
				data: { ...formData, work_with: true },
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response;
			if (jsonData.status === 200) {
				setLoading(false);
				toast.success("Form Submtted Successfully !");

				var close = setInterval(() => {
					setShowWorkWithUs(false);
					updateFormData();
					clearInterval(close);
				}, 2000);
			} else {
				toast.error("Please Enter All Details");
			}
		} catch (error) {
			setLoading(false);
			toast.error(error.response.data.status_message);
		}
	};

	const openWorkForm = () => {
		offCanvasRef.current.backdrop.click();
		setShowWorkWithUs(true);
	};
	const changeNavbarColor = () => {
		if (window.scrollY >= 20) {
			setBgColor(true);
		} else {
			setBgColor(false);
		}
	};
	window.addEventListener(
		"scroll",
		!headerTransparent ? changeNavbarColor : () => {}
	);

	const [deviceType, setDeviceType] = useState("");

	const [timer, setTimer] = useState(60);
	const timeOutCallback = useCallback(
		() => setTimer((currTimer) => currTimer - 1),
		[]
	);

	useEffect(() => {
		let timeout;
		timer > 0 && (timeout = setTimeout(timeOutCallback, 1000));

		return () => clearTimeout(timeout);
	}, [timer, timeOutCallback]);

	const resetTimer = function (e) {
		if (!timer) {
			VerifyAuth(e, "Resent");
			setTimer(60);
		}
	};

	useEffect(() => {
		let hasTouchScreen = false;
		if ("maxTouchPoints" in navigator) {
			hasTouchScreen = navigator.maxTouchPoints > 0;
		} else if ("msMaxTouchPoints" in navigator) {
			hasTouchScreen = navigator.msMaxTouchPoints > 0;
		} else {
			const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
			if (mQ && mQ.media === "(pointer:coarse)") {
				hasTouchScreen = !!mQ.matches;
			} else if ("orientation" in window) {
				hasTouchScreen = true; // deprecated, but good fallback
			} else {
				// Only as a last resort, fall back to user agent sniffing
				var UA = navigator.userAgent;
				hasTouchScreen =
					/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
					/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
			}
		}
		if (hasTouchScreen) {
			setDeviceType("Mobile");
		} else {
			setDeviceType("Desktop");
		}
	}, [location]);
	return (
		<>
			{/* <LoginRegister /> */}
			<Navbar
				bg={
					bgColor
						? "light"
						: !headerTransparent
						? "transparent"
						: "light"
				}
				fixed="top"
				expand={false}
				className={bgColor ? "shadow px-md-3 p-0" : "px-md-3 p-0"}
				style={{ transition: "0.1s linear all" }}>
				<div
					className="container-fluid"
					style={{
						height: 70,
						flexWrap: "unset",
					}}>
					<Navbar.Brand
						className={
							bgColor ? "fw-bold text-dark" : "fw-bold text-white"
						}>
						<Link to="/" className="zerobroker-logo">
							<Logo />
						</Link>
					</Navbar.Brand>
					<div className="d-flex">
						<ul className="navbar nav d-md-flex d-none">
							<li className="nav-item">
								<Link
									className={
										bgColor
											? location.pathname === "/"
												? "cool-link nav-link text-dark active"
												: "cool-link nav-link text-dark"
											: location.pathname === "/"
											? "cool-link-light nav-link text-white active-light"
											: "cool-link-light nav-link text-white"
									}
									aria-current="page"
									to="/">
									Home
								</Link>
							</li>
							<li className="nav-item">
								<Link
									className={
										bgColor
											? location.pathname ===
											  "/residential-projects"
												? "cool-link nav-link text-dark active"
												: "cool-link nav-link text-dark"
											: location.pathname ===
											  "/residential-projects"
											? "cool-link-light nav-link text-white active-light"
											: "cool-link-light nav-link text-white"
									}
									aria-current="page"
									to="/residential-projects">
									Residential Projects
								</Link>
							</li>
							<li className="nav-item">
								<Link
									className={
										bgColor
											? location.pathname ===
											  "/commercial-projects"
												? "cool-link nav-link text-dark active"
												: "cool-link nav-link text-dark"
											: location.pathname ===
											  "/commercial-projects"
											? "cool-link-light nav-link text-white active-light"
											: "cool-link-light nav-link text-white"
									}
									aria-current="page"
									to="/commercial-projects">
									Commercial Projects
								</Link>
							</li>
							<li className="nav-item">
								<Link
									className={
										bgColor
											? location.pathname ===
											  "/independent-bungalows-villas"
												? "cool-link nav-link text-dark active"
												: "cool-link nav-link text-dark"
											: location.pathname ===
											  "/independent-bungalows-villas"
											? "cool-link-light nav-link text-white active-light"
											: "cool-link-light nav-link text-white"
									}
									aria-current="page"
									to="/independent-bungalows-villas">
									Independent Bunglows/Villas
								</Link>
							</li>
							<li className="nav-item">
								<Link
									className={
										bgColor
											? location.pathname === "/home-loan"
												? "cool-link nav-link text-dark active"
												: "cool-link nav-link text-dark"
											: location.pathname === "/home-loan"
											? "cool-link-light nav-link text-white active-light"
											: "cool-link-light nav-link text-white"
									}
									aria-current="page"
									to="/home-loan">
									Home Loan
								</Link>
							</li>
						</ul>
						<Navbar.Toggle
							className="ms-2"
							style={{
								border: "none",
								color: "transparent",
							}}
							aria-controls={`offcanvasNavbar-expand-${false}`}>
							<List
								color={bgColor ? "#000" : "#fff"}
								size={30}
								style={{
									outline: "none",
								}}
							/>
						</Navbar.Toggle>
					</div>
					{showSidebar === null || showSidebar === true ? (
						<Navbar.Offcanvas
							id={`offcanvasNavbar-expand-${false}`}
							aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
							placement="end"
							ref={offCanvasRef}
							style={{
								zIndex: 100000,
							}}>
							<Offcanvas.Header className="offHeader" closeButton>
								<Offcanvas.Title
									id={`offcanvasNavbarLabel-expand-${false}`}
									onClick={() => {
										setShowLogin(true);
										setShowSidebar(false);
									}}>
									<PersonCircle className="me-3 mb-1" />
									Login/Register
								</Offcanvas.Title>
							</Offcanvas.Header>
							<Offcanvas.Body>
								<Nav className="justify-content-end flex-grow-1 pe-3">
									<Link
										className="sidebarlink nav-link"
										to="/">
										Home
									</Link>
									{deviceType === "Mobile" && (
										<>
											<Link
												className="sidebarlink nav-link phoneLink"
												to="/residential-projects"
												onClick={closeOffCanvas}>
												Residential Projects
											</Link>
											<Link
												className="sidebarlink nav-link phoneLink"
												to="/commercial-projects"
												onClick={closeOffCanvas}>
												Commercial Projects
											</Link>
											<Link
												className="sidebarlink nav-link phoneLink"
												onClick={closeOffCanvas}
												to="/independent-bungalows-villas">
												Independent Bungalow/Villas
											</Link>
											<Link
												className="sidebarlink nav-link phoneLink"
												to="/home-loan">
												Home Loan
											</Link>
										</>
									)}
									<Link
										className="sidebarlink nav-link"
										to="/about">
										About Us
									</Link>
									<Link
										className="sidebarlink nav-link d-md-block"
										onClick={openWorkForm}>
										Work With Us
									</Link>
									<Link
										className="sidebarlink nav-link"
										to="/blog">
										Blog
									</Link>
									<Link
										className="sidebarlink nav-link"
										to="/contact">
										Contact Us
									</Link>
									{/* <Link className="sidebarlink nav-link" to="/why-mumbai">
                    Why Mumbai
                  </Link> */}
									<Link
										className="sidebarlink nav-link"
										to="/privcy-policy">
										Privacy Policy
									</Link>
									<Link
										className="sidebarlink nav-link"
										to="/terms-and-conditions">
										Terms & Conditions
									</Link>
								</Nav>

								<div className="sidebarFooter">
									<strong className="my-2">Whatsapp :</strong>
									<div className="d-flex align-items-center">
										<div className="ms-3">
											<a
												href="tel:+919930704010"
												target="_blank"
												className="text-dark"
												rel="noreferrer">
												+91 9930704010
											</a>
										</div>
									</div>

									<strong className="my-2">Email :</strong>
									<div className="d-flex align-items-center">
										<div className="ms-3">
											<a
												href="mailto:sales@zerobroker.in"
												target="_blank"
												className="text-dark"
												rel="noreferrer">
												sales@zerobroker.in
											</a>
											<br />
											<a
												href="mailto:info@zerobroker.in"
												target="_blank"
												className="text-dark"
												rel="noreferrer">
												info@zerobroker.in
											</a>
										</div>
									</div>

									<footer
										style={{
											height: 100,
											width: "100%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											marginBottom: 50,
										}}>
										<a
											href="https://www.facebook.com/profile.php?id=100089858334241"
											className="iconFooterCanvas">
											<i className="fa-brands fa-facebook-f text-dark"></i>
										</a>
										<a
											href="https://www.instagram.com/zerobroker/"
											className="iconFooterCanvas">
											<i className="fa-brands fa-instagram text-dark"></i>
										</a>
										<a
											href="https://www.youtube.com/channel/UCoQYuBvCy8DIK_0HJUFx8Pg"
											className="iconFooterCanvas">
											<i className="fa-brands fa-youtube text-dark"></i>
										</a>
										<a
											href="https://www.linkedin.com/company/90894109/admin/"
											className="iconFooterCanvas">
											<i className="fa-brands fa-linkedin text-dark"></i>
										</a>
									</footer>
								</div>
							</Offcanvas.Body>
						</Navbar.Offcanvas>
					) : (
						<></>
					)}
				</div>
			</Navbar>

			<Modal
				show={showWorkWithUs}
				onHide={() => setShowWorkWithUs(false)}
				centered
				fullscreen={window.screen.width >= 600 ? false : true}
				backdropClassName="backdropModel"
				aria-labelledby="example-custom-modal-styling-title">
				<Modal.Body>
					<form className="">
						<div className="d-flex justify-content-between">
							<h3 className="mb-4 fw-bold">Work With Us</h3>
							<CloseButton
								onClick={() => setShowWorkWithUs(false)}
								color="#000"
							/>
						</div>
						<div className="form-floating mb-3">
							<input
								type="text"
								className="form-control"
								id="fullName"
								name="fullName"
								placeholder="Name"
								onChange={handleChange}
							/>
							<label htmlFor="fullName">Full Name</label>
						</div>

						<div className="form-floating mb-3">
							<input
								type="text"
								className="form-control"
								id="phone"
								name="phone"
								placeholder="Mobile Number"
								onChange={handleChange}
							/>
							<label htmlFor="phone">Mobile Number</label>
						</div>

						<div className="form-floating mb-3">
							<input
								type="email"
								className="form-control"
								id="email"
								name="email"
								placeholder="name@example.com"
								onChange={handleChange}
							/>
							<label htmlFor="email">Email address</label>
						</div>

						<div className="form-floating mb-3">
							<input
								type="text"
								className="form-control"
								id="location"
								name="location"
								placeholder="Location"
								onChange={handleChange}
							/>
							<label htmlFor="location">Location</label>
						</div>

						<div className="form-floating mb-3">
							<input
								type="text"
								className="form-control"
								id="rera"
								name="rera"
								placeholder="RERA Number"
								required
								onChange={handleChange}
							/>
							<label htmlFor="rera">RERA Number*</label>
							<span>
								(Note: RERA No. is Compulsory to Register as
								Partner)
							</span>
						</div>

						<div className="form-check">
							<input
								className="form-check-input"
								type="radio"
								name="broker"
								id="broker"
								required
								onChange={handleChange}
							/>
							<label
								className="form-check-label"
								htmlFor="broker">
								Broker
							</label>
						</div>
						<div className="form-check mb-3">
							<input
								className="form-check-input"
								type="radio"
								name="developer"
								id="developer"
								required
								onChange={handleChange}
							/>
							<label
								className="form-check-label"
								htmlFor="developer">
								Developer
							</label>
						</div>
						<Button
							type="button"
							loading={loading}
							className="btn btn-primary workBtn"
							onClick={workSubmit}>
							Submit
						</Button>
					</form>
				</Modal.Body>
			</Modal>
			{
				<Modal
					show={showLogin}
					onHide={() => {
						setShowLogin(false);
						setShowSidebar(true);
					}}
					centered
					fullscreen={window.screen.width >= 600 ? false : true}
					backdropClassName="backdropModel"
					aria-labelledby="example-custom-modal-styling-title">
					<Modal.Body>
						<div className="">
							<form action="" className="mobile-register-form">
								<div className="d-flex justify-content-between login-flex">
									<h3 className="mb-4 fw-bold">Login</h3>
									<CloseButton
										onClick={() => {
											setShowLogin(false);
											setShowSidebar(true);
										}}
										color="#000"
									/>
								</div>
								{!showOtp ? (
									<div className="form-floating mb-3">
										<input
											type="tel"
											className="form-control"
											id="phone"
											name="phone"
											placeholder="Enter OTP"
											onChange={(e) =>
												setAuthId(e.target.value)
											}
										/>
										<label htmlFor="email">
											Enter Mobile No./ Email
										</label>
									</div>
								) : (
									<div className="input-group mb-1">
										<div className="form-floating">
											<input
												type="tel"
												className="form-control"
												id="otp"
												name="otp"
												placeholder="Enter OTP"
												value={otp}
												onChange={(e) =>
													setOtp(e.target.value)
												}
											/>
											<label htmlFor="otp">
												Enter OTP
											</label>
										</div>
									</div>
								)}

								{showOtp ? (
									<>
										{timer <= 0 ? (
											<p
												className="resend-timer btn-link"
												onClick={(e) => resetTimer(e)}>
												Resend OTP
											</p>
										) : (
											<p className="resend-timer">
												Resend OTP in {timer}
											</p>
										)}

										<button
											className="btn btn-primary"
											type="button"
											onClick={VerifyOTP}>
											Verify OTP
										</button>
									</>
								) : (
									<button
										className="btn btn-primary"
										type="button"
										onClick={(e) => VerifyAuth(e, "Sent")}>
										{" "}
										Send OTP
									</button>
								)}
							</form>
						</div>
					</Modal.Body>
				</Modal>
			}
		</>
	);
}

export default Header;
