// import { useEffect, useState } from "react";
import { usePWAInstall } from "react-use-pwa-install";

export const useDownloadApp = () => {
	const install = usePWAInstall();
	// const [supportsPWA, setSupportsPWA] = useState(false);
	// const [promptInstall, setPromptInstall] = useState(null);

	// useEffect(() => {
	// 	const handler = (e) => {
	// 		e.preventDefault();
	// 		setSupportsPWA(true);
	// 		setPromptInstall(e);
	// 	};
	// 	window.addEventListener("beforeinstallprompt", handler);

	// 	return () => window.removeEventListener("transitionend", handler);
	// }, []);

	// if (!supportsPWA) {
	// 	// console.log("does not support");
	// 	return null;
	// }

	// const onClick = (evt) => {
	// 	evt.preventDefault();
	// 	console.log("Installing");
	// 	if (!promptInstall) {
	// 		return;
	// 	}
	// 	promptInstall.prompt();
	// };

	return install;
};
