import React from 'react'
import { GeoAltFill } from 'react-bootstrap-icons'
import config from '../../../config'

function LocationTitle({title}) {
  return (
    <div className='d-flex align-items-center mb-2 mt-3'>
        <GeoAltFill color={config.primaryColor} />
        <p className='ms-2' style={{
            fontWeight: 500,
            color:config.primaryColor,
            fontWeight:"bold"
        }}>{title}</p>
    </div>
  )
}

export default LocationTitle