import React, { useEffect, useState } from "react";
import { api } from "../../helpers/api";
import LazyLoad from "../LazyLoad";
import LoadMoreButton from "../useful/LoadMoreButton";

const Banks = ({ setModalShow, href }) => {
	const [showMore, setShowMore] = useState(6);
	const [bankList, setBankList] = useState([]);

	useEffect(() => {
		const loadBank = async () => {
			const response = await api({
				method: "post",
				url: "fetch_bank.php",
				data: { fetch_bank: true },
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			if (response.status === 200) {
				setBankList(jsonData.data);
			}
		};
		loadBank();
	}, []);

	return (
		<>
			<div className="container" id="bankLoansSection">
				<div className="py-md-4 py-4 my-5">
					<h3 className="text-center fw-bold w-100 m-md-3 mb-4 text-uppercase">
						Lower Interest Rates, Greater loan eligibility & best
						loan offer from multiple banks
					</h3>

					<div className="bank-lists card shadow border-0 p-md-4 p-2 d-flex justify-content-center flex-column ">
						<div className="bank bank-head row align-items-center border vertical-divider rounded mx-2 mb-2">
							<div className="bankimg col-md-15 col-12">
								Home Loan Banks
							</div>
							<div className="col-md-15 col-12">
								Interest Rate
							</div>
							<div className="col-md-15 col-12">
								Processing Fee
							</div>
							<div className="col-md-15 col-12">
								EMI Amount
								<span>(per lakh for 20 years)</span>
							</div>
							<div className="col-md-15 col-12">{""}</div>
						</div>
						{bankList.map(
							(bank, id) =>
								id <= showMore && (
									<div
										key={id}
										className="bank row align-items-center border vertical-divider rounded mx-2 mb-3"
									>
										<div className="bankimg col-md-15 col-12 d-md-block">
											<LazyLoad
												src={bank.image}
												className="img-fluid"
												lazyClass={"img-fluid"}
											/>
											<a
												href={href ?? "#!"}
												className="btn btn-primary d-md-none d-block"
												onClick={() => {
													if (setModalShow) {
														setModalShow(true);
													}
												}}
											>
												Enquire Now
											</a>
										</div>
										<div className="col-md-15 col-12">
											<span className="d-md-none d-inline-block label">
												Rate of Interest:
											</span>
											<span>{bank.roi}</span>
										</div>
										<div className="col-md-15 col-12">
											<span className="d-md-none d-inline-block label">
												Processing Fee:
											</span>
											<span>{bank.fee}</span>
										</div>
										<div className="col-md-15 col-12">
											<span className="d-md-none d-inline-block label">
												EMI Amount:
											</span>
											<span>{bank.emi_amount}</span>
										</div>
										<div className="col-md-15 col-12 d-md-block d-none">
											<a
												href={href ?? "#!"}
												className="btn btn-primary"
												onClick={() => {
													if (setModalShow) {
														setModalShow(true);
													}
												}}
											>
												Enquire Now
											</a>
										</div>
									</div>
								)
						)}

						{showMore <= bankList.length && (
							<LoadMoreButton
								onClick={() => setShowMore((prev) => prev + 8)}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Banks;
